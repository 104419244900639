.remainderemail-section{
    .reminder-content{
        display: flex;
        margin-left: auto;
    }
    .selectreminder-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #262626;
    }
    .turnreminder-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        display: flex;
        padding-top: 6px;
        margin-right: 16px;
        align-items: center;
        color: #262626;
    }
    .switch{
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    } 
    .switch input{ 
        opacity: 0;
        width: 0;
        height: 0;
    } 
    .slider{
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }
    .slider:before{
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }
    input:checked + .slider{
        background-color: #f60;
    }
    input:focus + .slider{
        box-shadow: 0 0 1px #f60;
    }
    input:checked + .slider:before{
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    .slider.round{
        border-radius: 34px;
    }
    .slider.round:before{
        border-radius: 50%;
    }
    .focusbucket-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 20px;
        padding-top: unset;
        color: #707070;
    }
    .selectoption-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        text-align: left;
        color: #000;
    }
    .selectedbucket-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-top: 16px;
        padding-bottom: 20px;
        color: #707070;
    }
    .remainderselected-content{
        height: unset;
        overflow: unset;
        display: flex;
        flex-wrap: wrap;
        grid-gap: 15px;
        grid-row-gap: 15px;
    }
    .focusbucketwhole-section{
        padding: 4px 10px;
        border-radius: 20px;
        border: solid 1px #dedede;
        background-color: #fff;
        align-items: center;
    }
    .focusbucketwholeselected-section{
        padding: 4px 10px;
        border-radius: 20px;
        border: solid 1px #f60;
        background-color: rgba(255, 102, 0, 0.05);
        align-items: center;
    }
    .focusbucketname-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        display: inline-block;
        align-items: center;
        padding-left: 6px;
        padding-right: 6px;
        color: #262626;
        text-overflow: ellipsis;
        max-width: 20ch;
        white-space: nowrap;
        overflow: hidden;
        cursor:pointer;
    }
    .focuscount-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        display: flex;
        align-items: center;
        padding: 2px 13px;
        border-radius: 14px;
        background-color: rgba(112, 112, 112, 0.25);
        color: #000; 
    }
    .focuscountselected-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        display: flex;
        align-items: center;
        padding: 2px 13px;
        border-radius: 14px;
        background-color: #f60;
        color: #fff;
    }
    .inputfocus-content{
        width: 16px;
        border-radius: 5px;
        border: solid 2px #707070;
    
    }
    input[name="buckets"]{
        accent-color: orangered;
    }
    input[name="book"]{
        accent-color: orangered;
    }
    .myselectedfocus-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal; 
        line-height: normal;
        text-align: left;
        padding-top: 40px;
        padding-bottom: 40px;
        color: #262626;
    }
    .focusselectedcount-content{
        width: 100px;
        padding: 4px 10px 4px 22px;
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 18px;
        text-align: center;
        border: solid 1px #dedede;
    }
    .kindlefocus-content{
        padding-top: 40px;
    }
    .selectedkindlebooks-content{
        padding-top: 16px;
    } 
    .time-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-top: 30px;
        padding-bottom: 20px;
        color: #262626;
    }
    .bucketswhole-spacecontent{
        margin-right: 10px;
        padding: 1px 10px;
        border-radius: 18px;
        border: solid 1px #d8dee5;
        background-color: #fbfbfb;
    }
    .createdbuckettext-content{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        line-height: 26px;
        text-align: left;
        padding-right: 12px;
        color: #262626;
    }
    .dropdownicon-bucketcontent{
        color: #707070;
    }
    .buttonpadding-content {
        padding-top: 6px;
        padding-bottom: 6px;
        display: flex;
    }
    .repeatheading-content{
        padding-top: 6px;
        padding-bottom: 6px;
        display: flex;
        margin-right: 20px;
        border-radius: 22.5px;
        // background-color: #f5f5f5;
        color: #000 !important;
        background-color: rgba(193, 193, 193, 0.18);
    }
    .repeatselectheading-content{
        padding-top: 6px;
        padding-bottom: 6px;
        display: flex;
        margin-right: 20px;
        border-radius: 22.5px;
        // color: #000 !important;
        border: solid 1px #ff6500;
        background-color: #fff7f2;
    } 
    .repeatselectheading-content span{
        color: #000 !important;
    }
    .daysSelectheading-content{
        width: 40px;
        height: 40px;
        padding-top: 4px;
        padding-bottom: 4px;
        display: flex;
        margin-right: 20px;
        border-radius: 50px;
        // color: #262626;
        border: solid 1px #ff6500;
        background-color: #fff7f2;
    }
    .daysSelectheading-content span{
        color: #000 !important;
    }
    .repeat-section{
        margin-bottom: 20px;
    }
    .daysheading-content{
        width: 40px;
        height: 40px;
        padding-top: 4px;
        padding-bottom: 4px;
        display: flex;
        margin-right: 20px;
        border-radius: 50px;
        color: #000;
        // background-color: #f5f5f5;
        background-color: rgba(193, 193, 193, 0.18);
    }
    .days-context{
        font-family: "HelveticaNeue";
        font-size: 17px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.65;
        text-align: center;
        padding: 1px 14px;
        color: #707070;
    }
    .remainderlast-section{
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .emailbuckets-context{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.65;
        text-align: center;
        padding: 1px 40px;
        color: #fdfdfd;
    }
    .daily-context{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.65;
        text-align: center;
        padding: 1px 30px;
        color: #707070;
    }
    .cancel-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        display: flex;
        padding-left: 40px;
        align-items: center;
        text-align: left;
        color: #8276b4;
    }
    .lastsection-content{
        margin-bottom: 30px;
    }
    .focusbucketmail-imgcontent{
        width: 24px;
        height: 25px;
        margin-right: 7px;
        filter: invert(77%) sepia(2%) saturate(815%) hue-rotate(176deg) brightness(95%) contrast(98%);
    }
    .focusbucketwholeselected-section .focusbucketmail-imgcontent{
        filter: unset;
    }
    .repeat-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal; 
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        padding-bottom: 15px;
        color: #000;
    }
    .myselectedfocus-content ::placeholder{
        color: #000000;
    }
}
.dark .bucketswhole-spacecontent{
    border: solid 1px #B5BAC1;
    background-color: rgba(251, 251, 251, 0.23);
}
.dark .createdbuckettext-content{
    color: #fff;
}
.dark .dropdownicon-bucketcontent{
    color: #fff;
}
.dark .remainderemail-section .selectreminder-content{
    color: #fff;
}
.dark .remainderemail-section .turnreminder-content{
    color: #fff;
}
.dark .remainderemail-section input[name=buckets] {
    filter: invert(100%) sepia(5%) saturate(100%) hue-rotate(190deg) brightness(100%) contrast(100%);
}
.dark input[name="buckets"]{
    accent-color: orangered;
}
.dark .remainderemail-section .focusbucketname-content{
    color: #fff;
}
.dark .remainderemail-section .focusbucketwhole-section{
    border: solid 1px #4C618F;
    background-color: #051534;
}
.dark .remainderemail-section .focuscount-content{
    background-color: #c7d8f9;
}
.dark .remainderemail-section .myselectedfocus-content{
    color: #fff;
}
.dark .remainderemail-section .selectoption-content{
    color: #fff;
}
.dark .remainderemail-section .time-content{
    color: #fff;
}
.dark .remainderemail-section .focusbucket-content{
    color: #bbbbbb;
}
.dark .remainderemail-section .selectedbucket-content{
    color: #bbbbbb;
} 
.dark .remainderemail-section .focusselectedcount-content{
    color: #fff;
    background-color: unset;
}
.dark .remainderemail-section .repeat-content{
    color: #fff;
}
.dark .remainderemail-section .daily-context{
    // color: #fff;
}
.dark .myselectedfocus-content ::placeholder{
    color: #fff;
}
.dark .remainderemail-section .daily-context{
    color: #c7c7c7;
}
.dark .remainderemail-section .days-context{
    color: #c7c7c7;
}
.dark .remainderemail-section .days-context{
    color: #c7c7c7;
}
.dark .remainderemail-section .daysSelectheading-content{
    color: #262626 !important;
    background-color: rgba(251, 251, 251, 0.23);
    border: solid 2px #f60;
}
.dark .remainderemail-section .repeatselectheading-content{
    background-color: rgba(251, 251, 251, 0.23);
    border: solid 2px #f60;
}
.dark .daysSelectheading-content .repeatselectheading-content{
    background-color: rgba(251, 251, 251, 0.23);
    border: solid 2px #f60;
}
.dark .daysSelectheading-content span{
    color: #fff !important;
}
.dark .repeatselectheading-content span{
    color: #fff !important;
}
.remainderemail-section{
    .turnreminder-content{
        display: block;
    }
}
@media (min-width: 320px) and (max-width: 771px){
    .remainderemail-section{
        .turnreminder-content{
            display: none;
        }
        .focusbucket-content{
            padding-top: 12px;
        }
        .focusbucketname-content{
            display: -webkit-inline-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .myselectedfocus-content{
            line-height: 28px;
        }
        .remainderselected-content{
            height: 300px;
            overflow: scroll;
        }
    }
}