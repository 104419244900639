$base-color: #262626;
$color-white: #ffffff;
$bf-color: #f8f8f8;
$color-grey: #707070;
$border-orange: #FF6600;
$color-scroll: #c6c6c6;

.editProject-wholesection{
  .editproject-content{
    font-family: 'Objectivity-Medium';
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
  }
  .summertitle-textcontent{
    font-size: 16px;
    width: 50%;
    padding: 5px;
    margin-left: 10px;
    display: flex;
    border-radius: 5px;
    border: solid 1px #707070;
  }
  .editIcon-content{
    padding-left: 6px;
    padding-right: 12px;
  }
  .bucketHeader {
    margin-bottom: 10px;
  }
  hr{
    border: 1px solid #dedede; 
  }
  .menuitems-content{
    justify-content: space-between;
    padding: 4px 14px;
    margin: 10px 10px 0px 10px;
    border-radius: 5px;
    border: solid 1px #dedede;
    background-color: #fff;
  }
  .createdtext-content{
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 26px;
    letter-spacing: normal;
    text-align: left;
    padding-right: 20px;
    color: #707070;
  }
  .savebutton-context{
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: normal;
    text-align: center;
    padding: 6px 30px;
    color: #fdfdfd;
  }
  .topsection-content{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
  }
  .selectcards-content{
    font-family: 'Objectivity-Medium';
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: -1.4px;
    text-align: center;
    width: 27%;
    color: #262626;
  }
  .clickspace-content{
    width: 15%;
  }
  .projectWorkspace-content{
    font-family: 'Objectivity-Medium';
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -1.4px;
    text-align: center;
    width: 58%;
    color: #262626;
  }
  .editproject-spacecontent{
    padding-bottom: 34px;
  } 
  .showcase-content{
    padding-top: 10px;
  }
  .projectswipe-content{
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .summerImage-content{
    padding-top: 10px;
  }
  .workspace-content{
    font-family: 'Objectivity-Medium';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -1.2px;
    text-align: center;
    margin-top: 20px;
    color: #262626;
  }
  .saved-section{
    text-align: center;
  }
  .card-detailimg{
    width: -webkit-fill-available;
    padding-bottom: 15px;
  }
  .bgeditproject-content{
    margin-right: 15px;
    padding: 15px 0px 15px 15px;
    background-color: #f7f7f7;
  }
  .canceltext-content{
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 35px;
    letter-spacing: normal;
    text-align: center;
    padding-left: 30px;
    color: #8276b4;
  }
  .react-tabs__tab:focus{
    outline: none;
  }
  .profile-imgcontent img{
    width: 30px;
    height: 30px;
  }
  .share-content{
    font-size: 12px;
  }
  .edit-menu{
    flex-wrap: nowrap;
    margin: auto;
  }
  .inline-align{
  display: inline-flex;
  }
  .search-tab{
    input{
      background-color: #fff;
      padding-left: 12px;
      margin: 23px 8px 19px;
      width: 94%;
    }
    .search-magnifier{
      right: 18px;
    }  
    input::placeholder{
      color:$color-grey;
    }
  }
  .saved-content{
    width: 15%;
    margin: auto 0;
    padding-left: 15px;
  }
  .react-tabs {
    display: flex;
    color: $base-color;
    background: $color-white;
  }
  .tab-menu{
    margin-left: auto;
  }
  .react-tabs__tab-list {
    display: flex;
    flex-direction: column; 
    width: 27%;
    margin: 0;
    padding: 0;
    color: white;
    background: $bf-color;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #f8f8f8;
  }
  .tab-scroll{
    height: 60%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .tab-scroll::-webkit-scrollbar {
    width: 10px;
    height: 100px;
  }
  .tab-scroll::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .tab-scroll::-webkit-scrollbar-thumb {
    background-color: $color-scroll;
    border-radius: 8px;
  }
  .react-tabs__tab {
    list-style: none;
    padding: 12px 6px;
    cursor: pointer;
    color: $base-color;
  }
  .react-tabs__tab{
    p{
      font-family: 'HelveticaNeue';
      font-size: 16px;
      font-weight: normal;
      line-height: 20px;
      color: $base-color;
    }
  }
  .react-tabs__tab:focus{
    border: none;
  }
  .edit-cardname{
    margin-left: 30px;
  }
  .public-share{
    font-size: 14px;
  }
  .card-content{
    font-family: HelveticaNeue;
    font-size: 16px;
    line-height: 1.27;
    text-align: left;
    color: #141414;
    text-align: left;
    margin-bottom: 16px;
  }
  .menu-cardshare{
    align-items: center;
  }
  .cardmenu-icons{
    margin-left: auto;
  }
  .mb--16{
    margin-bottom: 16px;
  }
  .last-updated p{
    font-family: 'HelveticaNeue';
    font-weight: normal;
    font-size: 14px;
    line-height: 1.25;
    text-align: left;
    color: #484848;
  }
  .react-tabs__tab-panel {
    display: none;
    width: 58%;
    margin-left: 15px;
  }
  .tag-menu{
    font-family: 'HelveticaNeue';
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
    border-radius: 17px;
    padding: 6px 14px;
    margin-right: 6px;
  }
  .react-tabs__tab-panel--selected {
    display: block;
    border: solid 1px #dedede;
  }
  .react-tabs__tab {
    padding-left: 8px;
    display: flex;
    border-bottom: solid 1px #dedede;
  }
  .react-tabs__tab--selected {
    padding-left: 8px;
    border-bottom: solid 1px #dedede;
  }
  .projectedit-menu{
    font-family: 'HelveticaNeue';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
  }
  .panel-content {
    text-align: center;
    padding: 15px;
    h5{
      font-family: 'HelveticaNeue';
      font-size: 22px;
      font-weight: bold;
      font-style: normal;
      line-height: 30px;
      text-align: left;
      color: $base-color;
    }
    hr {
      border: 1px solid #c1c1c1; 
    }
    .edit-menu span{
      font-family: 'HelveticaNeue';
      font-size: 14px;
      padding-left: 5px;
      letter-spacing: normal;
      text-align: left;
      color: #191919;
    }
    .project-nav{
      color: #8276b4;
    }
  }
  .checkbox-wrapper-24 *,
  .checkbox-wrapper-24 *:after,
  .checkbox-wrapper-24 *:before {
    box-sizing: border-box;
  }
  .checkbox-wrapper-24{
    display: flex;
    margin-right: 12px;
    margin-top: 4px;
  }
  .checkbox-wrapper-24 span{
    font-family: 'HelveticaNeue';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 23px;
    text-align: left;
    margin-left: 11px;
    color: #000;
  }
  .checkbox-wrapper-24 input {
    position: absolute;
    opacity: 0;
  }
  .checkbox-wrapper-24 input:checked + label svg path {
    stroke-dashoffset: 0;
  }
  .checkbox-wrapper-24 input:focus + label {
    transform: scale(1.03);
  }
  .checkbox-wrapper-24 input + label {
    display: block;
    border: 2px solid $color-grey;
    width: 18px;
    height: 18px;
    border-radius: 6px;
    cursor: pointer;
    transition: all .2s ease;
    padding: 2px;
  }
  .checkbox-wrapper-24 input + label:active {
    transform: scale(1.05);
    border-radius: 12px;
  }
  .checkbox-wrapper-24 input + label svg {
    pointer-events: none;
    padding: 5%;
  }
  #search:placeholder{
    font-size: 16px;
  }
  .checkbox-wrapper-24 input + label svg path {      
    fill: none;     
    stroke: #333;
    stroke-width: 4px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 100;
    stroke-dashoffset: 101;
    transition: all 250ms cubic-bezier(1,0,.37,.91);
  }
  .react-tabs__tab-list {
    .react-tabs__tab--selected {
      background: $color-white;
      color: $base-color;
    }
  }
} 
.dark {
  .editProject-wholesection{
  .search-tab{
  input::placeholder{
    color:#acacac;
}
  }
.react-tabs__tab-list
  {
    color: none;
    box-shadow: none; 
    background-color: #112750;
  }
  .tab-scroll::-webkit-scrollbar-thumb {
    background-color:#c6c6c6 ;
  }

  .react-tabs{
    background-color: #051534;
  }
 .checkbox-wrapper-24 input + label {
    border: 2px solid #9aa1ab;
}
.checkbox-wrapper-24 input + label svg path {      
  fill: none;     
  stroke: #acacac;
}
.darkicon-bc
{
 filter: invert(85%) sepia(5%) saturate(328%) hue-rotate(176deg) brightness(86%) contrast(95%);

} 
  .react-tabs__tab {
    border-left: 6px solid #112750;
    border-bottom: 1px solid #3b4964;

  }
  .card-content{
    color: #c7c7c7;

  }
  .panel-content h5{
    color: $color-white;
    
  }
  .last-updated{
    color: #bbbbbb;
  }
  .search-tab{
    input{
        background-color: #2F436A;
        
    }
  }
 
  .react-tabs__tab{
    p{
     color: $color-white;
   }
 
 }
}
}
.dark{
  .editProject-wholesection{
    h5{
      color: #fff;
    }
    .totalbucket-content{
      color: #bbbbbb;
    }
    h6{
      color: #fff;
    }
    .dark-icons{
      filter: invert(71%) sepia(12%) saturate(18%) hue-rotate(316deg) brightness(96%) contrast(88%);
    }
    
    .chevron-dark{
      filter:  invert(100%) sepia(0%) saturate(4165%) hue-rotate(103deg) brightness(107%) contrast(99%);;
    }
  }
}

.dark .editProject-wholesection .react-tabs__tab {
  border-bottom: 1px solid #3b4964;
}
.dark .editProject-wholesection .react-tabs__tab-list .react-tabs__tab--selected{
  background: #2F436A;
  border-left: 6px solid #FF6600;
  color: #ffffff;
}
.dark .editProject-wholesection{ 
  .search-tab{
    input::placeholder{
      color:#acacac;
    }
  }
}
.dark .editProject-wholesection .react-tabs {
  background-color: #051534;
}
.dark .editProject-wholesection .search-tab{
  input{
    background-color: #2F436A;     
  }
}
.dark .editProject-wholesection .react-tabs__tab-list{
  border-bottom: none !important;
}
.dark .editProject-wholesection .selectcards-content{
  color: #ffffff;
}
.dark .editProject-wholesection .projectWorkspace-content{
  color: #ffffff;
}
.dark .editProject-wholesection .editproject-content{
  color: #ffffff;
}
.dark .editProject-wholesection .summertitle-textcontent{
  color: #ffffff;
  border: 1px solid #202F4F;
}
.dark .editProject-wholesection hr{
  border: solid 1px #202F4F;
}
.dark .editProject-wholesection .react-tabs__tab-panel--selected{
  border: solid 1px #202F4F;
}
.dark .editProject-wholesection .bgeditproject-content{
  background-color: unset;
}
.dark .editProject-wholesection .editIcon-content{
  filter: invert(77%) sepia(2%) saturate(815%) hue-rotate(176deg) brightness(95%) contrast(98%);
}
.dark .editProject-wholesection .workspace-content{
  color: #ffffff;
}
.dark .editProject-wholesection .projectswipe-content{
  filter: invert(77%) sepia(2%) saturate(815%) hue-rotate(176deg) brightness(95%) contrast(98%);
}
.dark .editProject-wholesection .menuitems-content{
  border: solid 1px #16294C;
  background-color: #2F436A;
}