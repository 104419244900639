$base-color: #262626;
$color-white: #ffffff;
$bf-color: #f8f8f8;
$color-grey: #707070;
$border-orange: #ff6600;
$color-scroll: #c6c6c6;

.tags-pagesection {
  //   .tags-page .react-tabs__tab-list .react-tabs__tab--selected {
  //     background: #ffffff;
  //     border-left: 6px solid #FF6600;
  //     color: #262626;
  // }
  .filescard-section .cardContent img {
    height: 166px;
    margin: 0 auto;
    border-radius: 10px;
  }
  .buttonpadding-content {
    margin-right: 0;
  }
  .buckettagnav-content {
    width: 75px;
    right: -26px;
    transform: translate(-13%, -5%);
    top: 29px;
  }
  .tagsbookmarks-wholesection {
    margin-top: 40px;
  }
  .buckettagnav-content svg {
    display: none;
  }
  h5 {
    font-family: "HelveticaNeue";
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    line-height: 30px;
    text-align: left;
    color: $base-color;
  }
  .custom-height {
    height: 16px;
  }
  .inputCheck {
    input {
      width: 20px;
      height: 20px;
    }
  }
  .react-tabs__tab-list {
    // height: 800px;
  }
  .react-tabs__tab {
    border: none;
  }
  .totalbucket-content {
    margin-bottom: 14px;
  }
  .social-textcontent {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  h6.tags_selected{
    font-family: "HelveticaNeue";
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    line-height: 24px;
    text-align: left;
    color: $base-color;
  }
  .cardContent img {
    height: -webkit-fill-available;
    width: 100%;
  }
  .iconsRight {
    margin-left: auto;
  }
  .cardContent {
    // height: 300px;
    overflow: hidden;
  }
  .lastsection-subicon {
    .count-tag {
      color: #fff;
      background-color: #000000;
      font-size: 10px;
      padding: 1px 5px;
      border-radius: 50px;
      position: absolute;
      bottom: 10px;
      left: 7px;
    }
  }
  .display-content {
    align-items: center;
  }
  .tag-menu {
    font-family: HelveticaNeue;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
    border-radius: 17px;
    padding: 6px 14px;
    margin-right: 6px;
  }
  .lg\:masonry-3-col {
    column-count: 3;
    column-gap: 1em;
  }
  .total-bucktes {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    padding: 12px;
    color: #ffffff;
    background-color: #ff6600;
  }
  .dropdown-icon {
    // transform: rotate(270deg);
  }
  .react-tabs__tab--selected {
    .dropdown-icon {
      transform: rotate(0);
    }
  }
  .card-detailimg {
    width: -webkit-fill-available;
    // max-height: 500px ;
  }
  .react-tabs__tab:focus {
    outline: none;
  }
  .profile-imgcontent img {
    width: 25px;
    height: 25px;
  }
  .share-content {
    font-size: 12px;
  }

  .search-tab {
    input {
      background-color: #fff;
      padding-left: 12px;
      margin: 23px 12px 19px;
      width: 92%;
    }

    .search-magnifier {
      right: 18px;
      width: 30px;
    }
    input::placeholder {
      color: $color-grey;
    }
  }
  .react-tabs {
    display: flex;
    color: $base-color;
    // background: $co;
  }
  .tab-menu {
    margin-left: auto;
    opacity: 0;
  }
  .react-tabs__tab--selected .tab-menu {
    // opacity: 1;
  }

  .react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    width: 295px;
    // height: 550px;
    margin: 0;
    padding: 0;
    color: white;
    background: $bf-color;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    // background-color: #f8f8f8;
  }
  .tab-scroll {
    // height: 70%;
    // overflow-y: scroll;
    // height: 530px;
    // overflow-x: hidden;
  }
  .tab-scroll::-webkit-scrollbar {
    width: 10px;
    height: 100px;
  }
  .tab-scroll::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .tab-scroll::-webkit-scrollbar-thumb {
    background-color: $color-scroll;
    border-radius: 8px;
  }
  .react-tabs__tab {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    list-style: none;
    padding: 3px 5px 3px 12px;
    margin-right: 6px;
    cursor: pointer;
    color: $base-color;
    border-radius: 16px;
    background-color: #fff;
  }
  .react-tabs__tab {
    p {
      font-family: "HelveticaNeue";
      font-size: 16px;
      font-weight: normal;
      line-height: 20px;
      color: $base-color;
    }
  }
  .react-tabs__tab:focus {
    border: unset !important;
  }
  .react-tabs__tab-list {
    border-bottom: unset !important;
  }
  .react-tabs__tab-list .react-tabs__tab--selected {
    border: unset !important;
    background-color: #ff6600 !important;
    color: #fff !important;
  }
  .edit-cardname {
    margin-left: 30px;
  }
  .public-share {
    font-size: 14px;
  }
  .card-content {
    font-family: HelveticaNeue;
    font-size: 16px;
    line-height: 1.27;
    text-align: left;
    color: #141414;
    text-align: left;
    margin-bottom: 16px;
  }
  .menu-cardshare {
    align-items: center;
  }
  .cardmenu-icons {
    margin-left: auto;
  }
  .mb--16 {
    margin-bottom: 16px;
  }
  .last-updated {
    text-align: left;
    font-family: HelveticaNeue;
    font-size: 14px;
    line-height: 1.25;
    color: #484848;
    margin-bottom: 25px;
    font-weight: normal;
  }
  .react-tabs__tab-panel {
    display: none;
    width: 100%;
    margin-left: 15px;
    margin-top: 20px;
  }
  .tag-menu {
    font-family: HelveticaNeue;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
    border-radius: 17px;
    padding: 6px 14px;
    margin-right: 6px;
  }
  .react-tabs__tab-panel--selected {
    display: block;
  }

  .react-tabs__tab {
    // padding-left: 20px;
    display: flex;
    align-items: center;
    // border-left: 6px solid $bf-color;
    // border-bottom: solid 1px #dedede;
  }

  .react-tabs__tab--selected {
    // padding-left: 21px;
  }

  .panel-content {
    text-align: center;
  }
  .panel-content h5 {
    font-family: HelveticaNeue;
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    line-height: 30px;
    text-align: left;
    color: $base-color;
  }
  .checkbox-wrapper-24 *,
  .checkbox-wrapper-24 *:after,
  .checkbox-wrapper-24 *:before {
    box-sizing: border-box;
  }

  .checkbox-wrapper-24 {
    display: flex;
    margin-right: 15px;
    margin-top: 4px;
  }
  .checkbox-wrapper-24 span {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 23px;
    text-align: left;
    margin-left: 11px;
    color: #000;
  }
  .checkbox-wrapper-24 input {
    position: absolute;
    opacity: 0;
  }

  .checkbox-wrapper-24 input:checked + label svg path {
    stroke-dashoffset: 0;
  }

  .checkbox-wrapper-24 input:focus + label {
    transform: scale(1.03);
  }

  .checkbox-wrapper-24 input + label {
    display: block;
    // border: 2px solid $color-grey;
    width: 18px;
    height: 18px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 2px;
  }

  .checkbox-wrapper-24 input + label:active {
    transform: scale(1.05);
    border-radius: 12px;
  }

  .checkbox-wrapper-24 input + label svg {
    pointer-events: none;
    padding: 5%;
  }
  #search:placeholder {
    font-size: 16px;
  }

  .checkbox-wrapper-24 input + label svg path {
    fill: none;
    stroke: #333;
    stroke-width: 4px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 100;
    stroke-dashoffset: 101;
    transition: all 250ms cubic-bezier(1, 0, 0.37, 0.91);
  }
  .react-tabs__tab-list {
    .react-tabs__tab--selected {
      // background: $color-white;
      // border-left: 6px solid $border-orange;
      // color: $base-color;
    }
  }
}
.App_treeRoot__zBxcR {
  padding-top: unset !important;
}
.tags-pagesection .list-item-cl {
  border-left: 6px solid transparent;
}
.tags-pagesection .list-item-cl:hover,
.tags-pagesection .list-item-cl.active {
  // padding: unset !important;
  background: $color-white;
  border-left: 6px solid $border-orange;
  color: $base-color;
}
.tags-pagesection .notactive li {
  // padding-left: 14%;
}
.nodesub-content {
  padding-left: 6%;
}
.tagsSub-imgcontent {
  width: 18px;
  height: 21px;
}
.tab-scroll ul li ul {
  // padding-left: 18%;
  padding-left: 50px;
}
.createpopup-content {
  font-family: "Objectivity-Medium";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 23px;
  color: #000;
}
.tagsdesc-content {
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  vertical-align: top;
  color: #262626;
  margin-top: 15px;
}
.deletepopup-content {
  font-family: "Objectivity-Medium";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 12px;
  color: #000;
}
.tagsdescdelete-content {
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  padding-top: 5px;
  color: #262626;
}
.createdtagtext-content {
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 26px;
  letter-spacing: normal;
  text-align: left;
  padding: 9px 9px 9px 20px;
  color: #707070;
}
.createdtagstext-content {
  display: none;
}
.subtagsdelete-content {
  padding-top: 20px;
}
.subtagdelete-content {
  padding-top: 5px;
  padding-bottom: 20px;
}
.spacecancel-content {
  margin-right: 5%;
}
.canceltag-content {
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-top: 2%;
  padding-right: 10%;
  color: #8276b4;
}
.subtagsspace-imgcontent {
  padding-left: 8%;
}
.spacetag-content {
  display: flex;
  padding-left: 8%;
  background-color: red;
}
.tagsname-content {
  display: contents;
}
.spacetag-content .tab-menu {
  margin-left: auto;
}
.tags-pagesection .spacetag-content .tab-menu {
  // margin-left: 165% !important;
}
// .tab-scroll ul:nth-child()

// p:nth-child(2) {
// .tags-pagesection .react-tabs__tab li:nth-child(1){
//   display: none;
// }
.spacesubtag-content {
  display: flex;
  padding-left: 16%;
}
.tags-pagesection .spacesubtag-content .tab-menu {
  margin-left: 156% !important;
}
.cardHolders {
  border: 1px solid #655e5e;
}
.dark .cardHolders {
  border: solid 1px #4c618f;
}

.dark {
  .tags-pagesection {
    .tab-menu {
      filter: invert(72%) sepia(5%) saturate(531%) hue-rotate(176deg)
        brightness(88%) contrast(93%);
    }
    .search-tab {
      input::placeholder {
        color: #acacac;
      }
    }
    .react-tabs__tab-list {
      background-color: #112750;
    }
    .tab-scroll::-webkit-scrollbar-thumb {
      background-color: #c6c6c6;
    }

    .react-tabs {
      background-color: #051534;
    }
    .checkbox-wrapper-24 input + label {
      // border: 2px solid #9aa1ab;
    }
    .checkbox-wrapper-24 input + label svg path {
      fill: none;
      stroke: #acacac;
    }
    .darkicon-bc {
      filter: invert(85%) sepia(5%) saturate(328%) hue-rotate(176deg)
        brightness(86%) contrast(95%);
    }
    .react-tabs__tab {
      // border-left: 6px solid #112750;
      // border-bottom: 1px solid #3b4964;
    }
    .card-content {
      color: #c7c7c7;
    }
    .panel-content h5 {
      // color: $color-white;
    }
    .last-updated {
      color: #bbbbbb;
    }
    .search-tab {
      input {
        background-color: #2f436a;
      }
    }
    .tags-pagesection {
      .react-tabs__tab-list {
        .react-tabs__tab--selected {
          // background: #2F436A;
          // border-left: 6px solid $border-orange;
          // color: #ffffff;
        }
      }
    }
    .react-tabs__tab {
      p {
        //  color: $color-white;
      }
    }
  }
}
.dark .tags-pagesection .react-tabs__tab p {
  color: #ffffff;
}
.dark .tags-pagesection .active {
  background-color: #f60;
  color: #fff;
}
.bucketCreationPopuptags {
  position: fixed;
  /* overflow: scroll; */
  top: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pnc {
  height: 225px !important;
}
.pwc {
  height: 270px !important;
}
.parentwithnochild {
  padding-left: 8%;
}
.tagspoupsub-content {
  background-color: #ffffff;
}
.dark .tagspoupsub-content {
  background-color: #ffffff;
}
.bucketCreationPopuptags form {
  // height: 36%;

  position: fixed;
  top: 30%;
  border-radius: 10px !important;
  padding: 18px;
  margin-top: 18px;
  margin-bottom: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  background: #fff;
  display: flex;
  flex-direction: column;
}
.bucketCreationPopuptags form button {
  // background: #FF6600;
  // border: 2px solid transparent;
  // color: #fff;
  // width: 180px;
  // padding: 10px;
  // margin-left: auto;
  // margin-right: auto;
  // border-radius: 50px;
  // margin-top: 20px;
  // transition: .7s;
}
.dark {
  .tags-pagesection {
    h5 {
      color: #fff;
    }
    .totalbucket-content {
      color: #bbbbbb;
    }
    h6 {
      color: #fff;
    }
    .dark-icons {
      filter: invert(71%) sepia(12%) saturate(18%) hue-rotate(316deg)
        brightness(96%) contrast(88%);
    }

    .chevron-dark {
      filter: invert(100%) sepia(0%) saturate(4165%) hue-rotate(103deg)
        brightness(107%) contrast(99%);
    }
    .lastsection-subicon {
      .count-tag {
        background-color: #c7d8f9;
        color: #000;
      }
    }
  }
}
// .dark{
//   .tags-pagesection{
//   .react-tabs__tab-list {
//     .react-tabs__tab--selected {
//         background: #f60;
//         // border-left: 6px solid $border-orange;
//         color: #fff;
//         border-radius: 16px;
//       }
//   }
// }

// }
.tooltag-content {
  position: absolute;
  right: 0;
  margin-top: 0.5rem;
  width: 12rem;
  transform-origin: top right;
  border-radius: 0.375rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  z-index: 10;
  background-color: #ffffff;
}
.dark .tooltag-content {
  z-index: 10 !important;
  background-color: #ffffff !important;
}
.dark .tags-pagesection .list-item-cl:hover {
  background-color: #2f436a;
}
.tagsdesktop-section {
  display: block;
}
.tagsmbl-section {
  display: none;
}
.dark .react-tabs__tab .react-tabs__tab--selected:focus {
  border: unset !important;
}
.tagline-section {
  margin-right: 12px;
}
.dark .react-tabs__tab:focus {
  border: unset !important;
}
.dark .react-tabs__tab:focus {
  outline: none;
}
.dark .tags-pagesection .react-tabs__tab {
  color: #fff;
  background-color: #051534;
}
.dark .tagline-section .tagsdesc-content {
  color: #fff;
}
.dark .tagradio-content .tagsdesc-content {
  color: #fff;
}

.dark .radiobutton-content {
  width: 20px;
  height: 20px;
}
input[name="selectedtag"] {
  accent-color: orangered;
}
.dark input[type="radio"] {
  filter: invert(100%) sepia(5%) saturate(100%) hue-rotate(190deg)
    brightness(100%) contrast(100%);
  accent-color: orangered;
}
// .dark input[type="radio"]:checked{
//   accent-color: orangered;
// }
// input[type="radio"] {
//   appearance: none;
//   background-color: transparent;
//   border-radius:50%;
//   border: 1px solid gray;
// }
// input[type="radio"]:checked {
//   background-color: #f60;
//   border: 1px solid #f60;
// }
// .dark input[type="radio"] {
//   appearance: none;
//   background-color: #112750;
//   border-radius:50%;
//   border: 1px solid gray;
// }
// .dark input[type="radio"]:checked {
//   background-color: #f60;
//   border: 1px solid #f60;
// }
.tags-pagesection {
  .tagradio-content {
    margin-left: 16px;
    margin-right: 16px;
  }
  .radiobutton-content {
    width: 20px;
    height: 20px;
  }
  .tabssection-content {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 14px;
    grid-row-gap: 10px;
  }
  .react-tabs__tab--selected {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    border-radius: 16px;
    color: #fff;
    background-color: #f60;
  }
  .tagsalign-content{
    display: flex;
  }
}
@media (min-width: 320px) and (max-width: 771px) {
  .tags-pagesection {
    .tagsdesktop-section {
      display: none;
    }
    .tagsmbl-section {
      display: block;
      margin-left: auto;
      margin-right: 10px;
    }
    .tagsalign-content{
      display: inline;
    }
    .filterHolder {
      margin-top: unset;
      margin-left: auto;
    }
    .react-tabs__tab-panel {
      width: 100%;
      margin-left: 10px;
    }
    .last-updated {
      font-size: 13px;
    }
    .bookmarksdismiss-button {
      margin-left: auto;
      margin-bottom: 26px;
    }
    h5 {
      font-family: "HelveticaNeue";
      font-size: 22px;
      font-weight: bold;
      font-style: normal;
      line-height: 30px;
      text-align: left;
      padding-left: 10px;
      color: #262626;
    }
    .totalbucket-content {
      padding-left: 10px;
      margin-top: 8px;
    }
  }
  .dark .tags-pagesection .react-tabs__tab-panel--selected {
    // background-color: #f60;
  }
}
.dark
  .tags-pagesection
  .react-for-tabs
  .react-tabs__tab-list
  .react-tabs__tab--selected {
  font-weight: normal !important;
  background: #fff !important;
  border: solid 4px #fff !important;
  color: #111 !important;
}
.dark
  .tags-pagesection
  .react-for-active-tabs
  .react-tabs__tab-list
  .react-tabs__tab--selected {
  font-weight: normal !important;
  background: #ff6600 !important;
  border: solid 4px #ff6600 !important;
  color: #fff !important;
}
.dark .tags-pagesection .react-for-tabs .react-tabs__tab {
  background-color: #051534 !important;
  color: #ffffff !important;
}
// .tags-pagesection{
//   .react-tabs__tab-list {
//     .react-tabs__tab--selected {
//         background: #f60;
//         // border-left: 6px solid $border-orange;
//         color: #fff;
//         border-radius: 16px;
//     }
//   }
// }
.hi-content:hover {
}
.dark .tags-pagesection .react-for-active-tabs .dark-icons {
  filter: invert(71%) sepia(12%) saturate(18%) hue-rotate(316deg)
    brightness(96%) contrast(88%);
}
