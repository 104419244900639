.WhatIShared{
    h5{
      font-family: 'Objectivity-Medium';
      font-size: 22px;
      line-height: 1.25;
      letter-spacing: -1.4px;
      text-align: left;
      margin-left: 10px;
      color: #262626;
    }
    // .react-tabs__tab--selected{
    //   background: unset;
    //   border-bottom: solid 4px #ff6600 !important;
    //   color: #262626;
   
    //    }
    .bucket-title{ 
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      // max-width: 180px;
      // padding-top: 13px;
      // max-width: 180px;
      padding-top: 21px;
      display: -webkit-inline-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      cursor: pointer;
    }
    .youtubeshared-content{
      width: 30px;
      height: 30px;
      margin-top: 13px;
      border-radius: 50px;
    }
    .checkshared-content{
      padding-top: 20px;
    }
    .share-icon{
      width: 20px;
      padding-top: 7px;
    }
    // .react-tabs__tab{
    //   position: unset;
    // }
    .md-40{
      margin-right: 60px;
    }
    .shareditem-content{
      padding-top: 5px;
    }
    .class-active{
      padding: 6px 38px 6px 39px;
      border-radius: 14px;
      border: solid 1px #1b8a00;
      background-color: rgba(27, 138, 0, 0.1);
      font-family: HelveticaNeue;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      margin-top: unset;
      color: #1b8a00;
    }
    .class-inactive{
      padding: 6px 33px 6px 33px;
      border-radius: 14px;
      border: solid 1px #e20404;
      background-color: rgba(226, 4, 4, 0.1);
      font-family: HelveticaNeue;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      margin-top: unset;
      color: #e20404;
    }
    .checkbox-shared{
      width: 3%;
    }
    .facebook-shared{
      width: 4%;
    }
    .name-whatishared{
      width: 31%;
    }
    .shared-whatishared{
      width: 19%;
    }
    .views-whatishared{
      width: 7%;
    }
    .clicks-whatishared{
      width: 8%;
    }
    .goto-whatishared{
      width: 7%;
      justify-content: end;
    }
    .copyto-whatishared{
      width: 5%;
      justify-content: end;
    }
    .active-whatishared{
      width: 13%;
      display: flex;
      justify-content: end;
    }
    .threedot-whatishared{
      width: 5%;
      display: flex;
      justify-content: end;
    }
    table{
      border-collapse: separate;
      border-spacing: 25px 30px ;
      width: 100%;
    }
    .delete-share{
      display: flex;
      align-items: center;
      color: #bcbcbc;
    } 
    .tab-count{
      font-size: 1rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      padding: 6px 12px;
      margin-left: 10px;
      color: #FFFFFF;
      background-color: #FF6600;
      border-radius: 50px;
    }
    .sharedtitle-content{
      // width: 180px;
    }
    .td-check
    {
      text-align: left;
      input{
        width: 20px;
        height: 20px;
      }
   
    }
    .space{
      margin-bottom: 30px;
    }

    td{
      margin-top: 30px;
      img{
        max-width: unset;
      }
    }
    .td-head{
      opacity: 0.5;
      font-family: 'HelveticaNeue';
      font-size: 16px;
      font-weight: medium;
      line-height: 1;
      letter-spacing: normal;
      text-align: right;
      color: #262626;
    }
    .td-count{
      font-family: 'HelveticaNeue';
      font-size: 18px;
      font-weight: normal;
      // text-align: right;
      justify-content: end;
      color: #262626;
    }
   
    // .react-tabs__tab-list{
    //   border-bottom: solid 1px #e5e7eb;
    //   margin-bottom: 24px;
    // }

    // .react-tabs__tab{
    //   border: none;
    //   bottom: unset;
    //   font-family: 'HelveticaNeue';
    //   font-size: 18px;
    //   font-weight: 500;
    //   text-align: left;
    //   color: #515151;
    //   margin-right: 30px;
    // }
   

  
      
    //   th {
    //     border-bottom: 1px solid black;
    //     border: 2px solid black;
    //   }
      
        // border: 2px solid black;
        h6{
          font-family: 'HelveticaNeue';
          font-size: 18px;
          font-weight: normal;
          text-align: left;
          // color: #262626;
          margin-bottom: 5px;
        }
        .bucket-name{
          align-items: center;
        }
        p{
          font-family: 'HelveticaNeue';
          font-size: 18px;
          text-align: left;
          display: flex;
          align-items: center;
          // color: #262626;
        
        }
        .link-td{
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 300px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          // cursor: pointer;
        }
      }


.dark{
    .WhatIShared{
        h5{
      color: #fff;
        }
        hr{
            border: 1px solid #202F4F;
        }
        .delete-share{
          display: flex;
          align-items: center;
          color: #bbbbbb;
        }
        // .react-tabs__tab-list {
        //   border-bottom: solid 1px #202F4F;
        // }
        h5{

    color: #fff;
      }
      .td-head{
        opacity: 0.5;
        color: #fff;
      }
      .td-count{
        color: #fff;
      }
      h6{
        color: #fff;
      }
      p{
        color: #fff;
      }
      .white-icon{
        filter: invert(100%) sepia(32%) saturate(307%) hue-rotate(122deg) brightness(110%) contrast(100%);;
      }
      .trash-disabled{
        width: 20px;
        height: 24px;
        filter:  invert(84%) sepia(1%) saturate(175%) hue-rotate(314deg) brightness(93%) contrast(84%);;
      }
      // .react-tabs__tab{
      //   color: #fff;
      // }
      .td-check
      {
        text-align: left;
        input{
          filter: invert(100%) sepia(5%) saturate(72%) hue-rotate(59deg) brightness(116%) contrast(78%);

        }
      }
    }
}
.WhatIShared {
  table{
    tr {
    td:nth-child(1){
      border-spacing: 0px 30px ;

    }
  }
}
}
.dark{
  .WhatIShared{
//   .react-tabs__tab-list {

//   .react-tabs__tab--selected{
//     background: unset;
//     border-bottom: solid 4px #ff6600;
//     color: #fff;
//     border-left: 0;
//      }
// }
.share-icon
{
 filter: invert(100%) sepia(0%) saturate(7487%) hue-rotate(34deg) brightness(109%) contrast(103%);
}
}
}
.shareddesktop-section{
  display: block;
}
.sharedmbl-section{
  display: none;
}
.desktop-section{
  display: block;
}
.delete-shares{
  display: flex;
  align-items: center;
}
.dark .delete-shares{
  display: flex;
  align-items: center;
  color: #fff;
}
@media (min-width: 320px) and (max-width: 771px) {
  .shareddesktop-section{
    display: none;
  }
  .sharedmbl-section{
    display: block;
  }
  .desktop-section{
    display: none;
  }
  .WhatIShared{
    h5{
        font-family: 'Objectivity-Medium';
        font-size: 22px;
  line-height: 1.25;
  letter-spacing: -1.4px;
  text-align: left;
  color: #262626;
    }
    #default-checkboxs {
      width: 15px;
      height: 15px;
      margin-left: 5px;
    }
    // .react-tabs__tab--selected{
    //   background: unset;
    //   border-bottom: solid 4px #ff6600 !important;
    //   color: #262626;
   
    //    }
    .bucket-title{ 
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      max-width: 115px;
      padding-top: 13px;
    }
    .youtubeshared-content{
      width: 30px;
      padding-top: unset;
      margin-top: unset;
    }
    .checkshared-content{
      padding-top: 13px;
    }
    .share-icon{
      width: 20px;
    }
    .md-40{
      margin-right: 60px;
    }
    .class-active{
      padding: 5px 5px 6px 8px;
      border-radius: 14px;
      border: solid 1px #1b8a00;
      background-color: rgba(27, 138, 0, 0.1);
      font-family: HelveticaNeue;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      margin-top: 10px;
      color: #1b8a00;
    }
    .class-inactive{
      padding: 5px 5px 6px 8px;
      border-radius: 14px;
      border: solid 1px #e20404;
      background-color: rgba(226, 4, 4, 0.1);
      font-family: HelveticaNeue;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      margin-top: 10px;
      color: #e20404;
    }
    table{
      border-collapse: separate;
      border-spacing: 25px 30px ;
      width: 100%;
    }
    .delete-share{
      color: #bcbcbc;
    }
    .tab-count{
      font-size: 1rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      padding: 6px 12px;
      color: #FFFFFF;
      background-color: #FF6600;
      border-radius: 50px;
    }
    .td-check
    {
      text-align: left;
      input{
        width: 20px;
        height: 20px;
      }
   
    }
    .space{
      margin-bottom: 30px;
    }

    td{
      margin-top: 30px;
      img{
        max-width: unset;
      }
    }
    .td-head{
      opacity: 0.5;
      font-family: 'HelveticaNeue';
      font-size: 14px;
      font-weight: medium;
      line-height: 1;
      letter-spacing: normal;
      text-align: right;
      color: #262626;
    }
    .td-count{
      font-family: 'HelveticaNeue';
      font-size: 14px;
      font-weight: normal;
      text-align: right;
      color: #262626;
    }
   
    // .react-tabs__tab-list{
    //   border-bottom: solid 1px #e5e7eb;
    //   margin-bottom: 24px;
    // }

    // .react-tabs__tab{
    //   border: none;
    //   bottom: unset;
    //   font-family: 'HelveticaNeue';
    //   font-size: 14px;
    //   font-weight: 500;
    //   text-align: left;
    //   color: #515151;
    //   margin-right: 8px;
    // }
   

  
      
    //   th {
    //     border-bottom: 1px solid black;
    //     border: 2px solid black;
    //   }
      
        // border: 2px solid black;
        h6{
          font-family: 'HelveticaNeue';
          font-size: 14px;
          font-weight: normal;
          text-align: left;
          color: #262626;
          margin-bottom: 5px;
        }
        .bucket-name{
          align-items: center;
        }
        p{
          font-family: 'HelveticaNeue';
          font-size: 12px;
          text-align: left;
          color: #262626;
        
        }
        .link-td{
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 120px;
        }
      }


.dark{
    .WhatIShared{
        h5{
      color: #fff;
        }
        hr{
            border: 1px solid #202F4F;
        }
        .delete-share{
          color: #bbbbbb;
        }
        // .react-tabs__tab-list {
        //   border-bottom: solid 1px #202F4F;
        // }
        h5{

    color: #fff;
      }
      .td-head{
        opacity: 0.5;
        color: #fff;
      }
      .td-count{
        color: #fff;
      }
      h6{
        color: #fff;
      }
      p{
        color: #fff;
      }
      .white-icon{
        filter: invert(100%) sepia(32%) saturate(307%) hue-rotate(122deg) brightness(110%) contrast(100%);;
      }
      .trash-disabled{
        filter:  invert(84%) sepia(1%) saturate(175%) hue-rotate(314deg) brightness(93%) contrast(84%);;
      }
      // .react-tabs__tab{
      //   color: #fff;
      // }
      .td-check
      {
        text-align: left;
        input{
          filter: invert(100%) sepia(5%) saturate(72%) hue-rotate(59deg) brightness(116%) contrast(78%);

        }
      }
    }
}
.WhatIShared {
  table{
    tr {
    td:nth-child(1){
      border-spacing: 0px 30px ;

    }
  }
}
}
}

