.root {
    background-color: #1967d2;
    border-radius: 4px;
    box-shadow: 0 12px 24px -6px rgba(0, 0, 0, 0.25),
      0 0 0 1px rgba(0, 0, 0, 0.08);
    color: #fff;
    display: inline-block;
    font-size: 14px;
    padding: 4px 8px;
    pointer-events: none;
  }
  