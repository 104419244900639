.manage-section{
    .manageteam-content{
        font-family: "HelveticaNeue";
        font-size: 28px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: unset;
        margin-right: 44px;
        color: #262626;
    }
    .manageseats-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 14%;
        padding-bottom: 18px;
        color: #262626;
    }
    .seatssec-content{
        padding-top: 40px;
    }
    .countdetails-content{
        padding-bottom: 50px;
    }
    .managecount-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 14%;
        padding-bottom: unset;
        color: #262626;
    }
    .manageadd-content{
        color: #8276b4;
    }
    .manageinvite-imgcontent{
        margin: 0 auto;
    }
    .manageassign-content{
        font-family: "HelveticaNeue";
        font-size: 28px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: center;
        padding-top: 30px;
        padding-bottom: 18px;
        color: #262626;
    }
    .manageactive-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: center;
        padding-bottom: 40px;
        color: #707070;
    }
    .invitemem-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 35px;
        color: #262626;
    }
    .headinginvite-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: 1.83;
        text-align: left;
        width: 18%;
        color: #262626;
    }
    .headingfirstinvite-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: 1.83;
        text-align: left;
        width: 25%;
        color: #262626;
    }
    .datedetail-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 17%;
        align-items: center;
        display: flex;
        color: #8276b4;
        cursor: pointer;
    }
    .managdetail-content{
        padding-top: 6px;
        padding-bottom: 6px;
    }
    .headingdateinvite-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: 1.83;
        text-align: left;
        width: 22%;
        color: #262626;
    }
    .headingdatefrstinvite-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: 1.83;
        text-align: left;
        width: 28%;
        color: #262626;
    }
    .headingdateactive-content{
        font-family: "HelveticaNeue";
        font-size: 18px; 
        font-weight: bold;
        font-style: normal;
        line-height: 1.83;
        text-align: left;
        width: 18%;
        color: #262626;
    }
    .headingactive-content{ 
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: 1.83;
        text-align: left;
        width: 16%;
        color: #262626;
    }
    .headingnameactive-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: 1.83;
        text-align: left;
        width: 26%;
        color: #262626;
    }
    .headingemailactive-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: 1.83;
        text-align: left;
        width: 25%;
        color: #262626;
    }
    .subinvitedetail-content{
        font-family: "HelveticaNeue";
        font-size: 18px; 
        font-weight: normal;
        font-style: normal;
        line-height: 1.2;
        text-align: left;
        width: 22%;
        align-items: center;
        display: flex;
        color: #262626; 
    }
    .subinvitefrstdetail-content {
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.2;
        text-align: left;
        width: 28%;
        align-items: center;
        display: flex;
        color: #262626;
    }
    .subcountdetail-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.2;
        width: 20%;
        align-items: center;
        display: flex;
        justify-content: center;
        color: #262626; 
    }
    .managedelete-imgcontent{
        width: 3%;
        align-items: center;
        display: flex;
    }
    .plancancel-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.56;
        text-align: left;
        padding-top: 26px;
        padding-bottom: 26px;
        color: #262626;
    }
    .assigncancel-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.56;
        text-align: left;
        padding-bottom: 26px;
        color: #262626;
    }
    .cancelbillinfo-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        align-items: center;
        display: flex;
        padding-right: 44px;
        color: #8276b4;
    }
    .savemanagebutton-context{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.65;
        text-align: center;
        padding: 6px 60px;
        color: #fdfdfd;
        cursor: pointer;
    }
    .submanagedetail-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.2;
        text-align: left;
        width: 25%;
        align-items: center;
        display: flex;
        color: #262626;
    }
    .submanagefrstdetail-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.2;
        text-align: left;
        width: 28%;
        align-items: center;
        display: flex;
        color: #262626;
    }
    .subactivedetail-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.2;
        text-align: left;
        width: 20%;
        align-items: center;
        display: flex;
        color: #262626;
    }
    .subactivenamedetail-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.2;
        text-align: left;
        width: 32%;
        align-items: center;
        display: flex;
        color: #262626;
    }
    .subactiveemaildetail-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.2;
        text-align: left;
        width: 32%;
        align-items: center;
        display: flex;
        color: #262626;
    }
    .cancellastfile-section{
        margin-bottom: 30px;
    }
    .manageactive-section{
        padding-top: 50px;
    }
    .profilecard-imgcontent{
        width: 48px;
        height: 48px;
        margin-right: 8px;
        border-radius: 100px;
        object-fit: cover;
    }
    .subcancelupload-section {
        position: fixed;
        // overflow: scroll;
        top: 28%; 
        left: 36%;
        border-radius: 10px;
        z-index: 1;
        padding: 22px 15px 0px 15px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
        background: #fff;
        width: 30%;
        margin: 0 auto;
        transition: left 0.3s ease-in-out;
    }
    .currentstatus-section{
        padding: 20px 20px;
        margin-top: 20px;
        border-radius: 12px;
        background-color: #f5f5f5;
    }
    .profilebill-content{ 
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        align-items: center;
        display: flex;
        padding-bottom: 30px;
        color: #262626;
    }
    .seatsbill-content{
        color: #024e84;
    }
    .addbillseat-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 13px;
        color: #707070;
    }
    .decrementbutton-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: center;
        width: 90px;
        height: 40px;
        margin-right: 14px;
        border-radius: 6px;
        border: unset;
        background-color: #fff;
        color: #262626;
    }
    .focusteamcount-content{
        width: 206px;
        height: 40px;
        padding: 4px 10px;
        text-align: center;
        margin-right: 14px;
        border-radius: 6px;
        border: unset; 
    }
    .incrementbutton-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: center;
        width: 90px;
        height: 40px;
        border-radius: 6px;
        border: unset;
        background-color: #fff;
        color: #262626;
    }
    .teammember-section ::placeholder{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        color: #262626;
    }
    .seatno-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        padding-top: 14px;
        color: #707070;
    }
    .totalcharges-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        padding-top: 30px;
        padding-bottom: 18px;
        color: #000;
    }
    .seatprice-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        width: 50%;
        color: #707070;
    }
    .seatsmon-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal; 
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #000;
    }
    .seatsec-content{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .pricebill-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 50%;
        color: #000;
    }
    .pricesection-content{
        padding-bottom: 50px;
    }
    .cancelbill-content{
        font-family: HelveticaNeue;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: center;
        align-items: center;
        display: flex;
        margin-left: 62px;
        color: #8276b4;
    }
    .planupdate-section{
        margin-top: 40px;
    }
    .saveplanbutton-context{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.65;
        text-align: center;
        padding: 6px 60px;
        color: #fdfdfd;
    }
    .cancelupdatecard-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        padding-top: 10px;
        margin-right: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        margin-left: 84px;
        color: #8276b4;
    }
    .payupdatebutton-context{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.65;
        text-align: center;
        padding: 6px 60px;
        color: #fdfdfd;
        cursor: pointer;
    }
}
.dark .manage-section .invitemem-content{
    color: #ffffff;
}
.dark .manage-section .headinginvite-content{
    color: #ffffff;
}
.dark .manage-section .headingdateinvite-content{
    color: #ffffff;
}
.dark .manage-section .submanagedetail-content{
    color: #ffffff;
}
.dark .manage-section .subinvitedetail-content{
    color: #ffffff;
}
.dark .manage-section .headingactive-content{
    color: #ffffff;
}
.dark .manage-section .headingdateactive-content{
    color: #ffffff;
}
.dark .manage-section .subactivedetail-content{
    color: #ffffff;
}
.dark .manage-section .subcountdetail-content{
    color: #ffffff;
}
.dark .manage-section .headingfirstinvite-content{
    color: #ffffff;
}
.dark .manage-section .headingdatefrstinvite-content{
    color: #ffffff;
}
.dark .manage-section .subinvitefrstdetail-content{
    color: #ffffff;
}
.dark .manage-section .headingnameactive-content{
    color: #ffffff;
}
.dark .manage-section .headingemailactive-content{
    color: #ffffff;
}
.dark .manage-section .subactivenamedetail-content{
    color: #ffffff;
}
.dark .manage-section .subactiveemaildetail-content{
    color: #ffffff;
}
.manageinvite-desktop{
    display: block;
}
.manageinvite-mbl{
    display: none;
}
@media (min-width: 320px) and (max-width: 771px){
    .manage-section{
        padding-left: 14px !important;
        padding-right: 14px !important;
    }
    .manageseats-content{
        width: 50% !important;
        margin-top: 20px !important;
    } 
    .manageteam-content{
        font-size: 25px !important;
        margin-right: 6px !important;
    }
    .seatssec-content {
        padding-top: 20px !important;
    }
    .managecount-content{
        width: 50% !important;
    }
    .manageinvite-imgcontent{
        padding-top: 50px !important;
    }
    .manageinvite-desktop{
        display: none;
    }
    .manageinvite-mbl{
        display: block;
    }
    .submanagedetail-content{
        width: unset !important;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }
    .subinvitefrstdetail-content{
        width: unset !important;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }
    .datedetail-content{
        width: unset !important;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }
    .subinvitedetail-content{
        width: unset !important;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }
    .subcountdetail-content{
        width: unset !important;
        align-items: left !important;
        text-align: left !important;
        justify-content: left !important;
    }
    .subactiveemaildetail-content{
        width: unset !important;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }
}