.cardList.Bookmarklist .react-tabs__tab-list {
  height: 872px;
}

.cardList.Bookmarklist {
  padding-bottom: 40px;
  .react-tabs__tab {
    border: none;
    padding: 0px 6px;
  }
  .react-tabs__tab--selected {
    background-color: #ffffff !important;
  }
  // .react-tabs__tab-panel {
  //   width: -webkit-fill-available;
  // }
  .tooltip-icon.bookmarkiconsingle-content.clipicon-content {
    margin-right: 28px;
  }
  .last-updated {
    margin-top: -10px;
  }
  .bookmarklist-menu {
    font-family: HelveticaNeue;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
  }
  .star-img {
    width: 23px;
  }
  .card-txt-content {
    margin-top: 9px;
  }
  .react-tabs__tab-list {
    .react-tabs__tab {
      border-left: 6px solid #f8f8f8;
    }
    .react-tabs__tab--selected {
      border-radius: 0;
      border-left: 6px solid #ff6600;
      border-bottom: 0 !important;
    }
  }
  .spacetag-content {
    display: flex;
    padding-left: 8%;
  }
  .spacetag-content .tab-menu {
    margin-left: auto;
  }

  .card-img {
    margin: auto 0;
    padding-right: 8px;
    .card-imge {
      height: 40px;
      width: 40px;
      border-radius: 50%;
    }
  }
  .react-tabs__tab {
    p {
      margin: auto 0;
    }
  }
  .link-imge {
    // height: 16px;
    margin-right: 5px;
  }
}
.spacebookmark-content {
  display: flex;
  padding-left: 14%;
}
.spacebookmark-content .menuthree-icon {
  margin-left: 64px !important;
}
.dark {
  .link-imge {
    filter: invert(100%) sepia(0%) saturate(7466%) hue-rotate(71deg)
      brightness(106%) contrast(96%);
  }
}
.cardList.Bookmarklist .react-tabs__tab--selected {
  border-radius: 0;
  border-left: 6px solid #ff6600 !important;
  border-bottom: 0 !important;
}
.cardList.Bookmarklist .react-tabs__tab--selected::after {
  content: none;
  border: 0;
  border-style: none;
  display: none;
}
.dark {
  .cardList.Bookmarklist {
    .react-tabs__tab-list {
      .react-tabs__tab {
        border-left: 6px solid #112750 !important;
      }
      .react-tabs__tab--selected {
        background-color: #2f436a !important;
        color: #262626;
        border-left: 6px solid #ff6600 !important;
      }
    }
  }
}

iframe > body::-webkit-scrollbar {
  width: 10px;
  height: 100px;
  background-color: #ff6600;
}
iframe > body::-webkit-scrollbar-track {
  background-color: transparent;
}
iframe > body::-webkit-scrollbar-thumb {
  background-color: red; 
  border-radius: 8px;
}
.Bookmarklist .buckettitle-textcontent{
  font-family: "HelveticaNeue";
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  line-height: 30px;
  text-align: left;
  color: #262626;
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
} 
