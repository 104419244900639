.teammembers-section {
  .cardssubBuckets-content {
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    text-align: left;
    color: #262626;
  }
  .subbucketsbg-content {
    padding: 0px 12px 1px 12px;
  }
  .cardsjustify-end {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  .btndesktop-invited {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    display: block;
    padding: 4px 10px;
  }
  .detailstop-section {
    padding-top: 32px;
  }
  .headingteam-section {
    font-family: "HelveticaNeue";
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    width: 35%;
    padding-bottom: 12px;
    text-align: left;
    color: #262626;
  }
  .invitedheadingteam-section {
    font-family: "HelveticaNeue";
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    width: 25%;
    padding-bottom: 12px;
    text-align: left;
    color: #262626;
  }
  .headingshare-section {
    font-family: "HelveticaNeue";
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    width: 25%;
    padding-bottom: 12px;
    text-align: center;
    color: #262626;
  }
  .headingaction-section {
    font-family: "HelveticaNeue";
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    width: 15%;
    padding-bottom: 12px;
    text-align: center;
    color: #262626;
  }
  .namemembers-content {
    width: 35%;
  }
  .teamtop-section {
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
  }
  .name-content {
    font-family: "HelveticaNeue";
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    text-align: left;
    // width: 25%;
    color: #262626;
  }
  .emailname-content {
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    text-align: left;
    // width: 25%;
    padding-top: 5px;
    color: #262626;
  }
  .unselectname-content {
    font-family: "HelveticaNeue";
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    text-align: left;
    width: 25%;
    color: #707070;
  }
  .profilecard-imgcontent {
    width: 41px;
    height: 41px;
    margin-top: 4px;
    margin-right: 15px;
    align-items: center;
    display: flex;
  }
  .invited-content {
    font-family: "HelveticaNeue";
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.2;
    text-align: left;
    width: 25%;
    align-items: center;
    display: flex;
    color: #262626;
  }
  .availableteam-content {
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    margin-left: 10px;
    align-items: center;
    display: flex;
    color: #262626;
  }
  .teamnumber-content {
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.33;
    text-align: left;
    margin-left: 5px;
    margin-right: 10px;
    color: #262626;
  }
  .teammembers-section .react-tabs__tab-list {
    border-bottom: 1px solid #c1c1c1;
    margin-bottom: 24px !important;
  }
  .teamslot-content {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    text-align: left;
    margin-left: 10px;
    align-items: center;
    display: flex;
    color: #8276b4;
  }
  .addmember-section {
    padding-top: 24px;
  }
  .unselectinvited-content {
    font-family: "HelveticaNeue";
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.2;
    text-align: left;
    width: 25%;
    color: #707070;
  }
  .shared-content {
    font-family: "HelveticaNeue";
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.2;
    text-align: center;
    width: 25%;
    padding-top: 14px;
    margin: 0 auto;
    color: #262626;
  }
  .cardssharedBuckets-content {
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    text-align: left;
    color: #262626;
  }
  .delete-content {
    width: 15%;
    align-items: center;
    display: flex;
  }
  .deleteteam-imgcontent {
    margin: 0 auto;
  }
  .filesuploadpopup {
    position: fixed;
    overflow: scroll;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    display: flex;
    transition: left 0.3s ease-in-out;
  }
  .filesuploadpopup::-webkit-scrollbar {
    width: 0;
  }
  .filesuploadpopup ::-webkit-scrollbar-track {
    background: transparent;
  }
  .filesuploadpopup::-webkit-scrollbar-thumb {
    background: transparent;
  }
  .subfilesupload-section {
    position: fixed;
    overflow: scroll;
    top: 0%;
    left: 70%;
    border-radius: 0px;
    z-index: 1;
    padding: 22px 22px 0px 22px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    background: #fff;
    width: 30%;
    height: 100%;
    margin: 0 auto;
    transition: left 0.3s ease-in-out;
  }
  .subfilesupload-section::-webkit-scrollbar {
    width: 0;
  }
  .subfilesupload-section ::-webkit-scrollbar-track {
    background: transparent;
  }
  .subfilesupload-section::-webkit-scrollbar-thumb {
    background: transparent;
  }
  .teammembers-content {
    font-family: "HelveticaNeue";
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    text-align: left;
    color: #262626;
  }
  .profileteam-imgcontent {
    width: 60px;
    height: 60px;
    margin-right: 15px;
  }
  .teamuploadsection {
    padding-bottom: 40px;
  }
  .teamname-content {
    font-family: "HelveticaNeue";
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 1px;
    color: #262626;
  }
  .invitename-content {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.06;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
  }
  .profileteam-section {
    padding-bottom: 36px;
  }
  .cardteam-count {
    padding-bottom: 32px;
  }
  .teambucket-imgcontent {
    margin-right: 15px;
  }
  .teambucket-content {
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
  }
  .unshare-content {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    text-decoration: underline;
    color: #8276b4;
  }
  .sharedtop-content {
    padding-bottom: 24px;
  }
  .subinviteupload-section {
    position: fixed;
    overflow: scroll;
    top: 24%;
    left: 36%;
    border-radius: 10px;
    z-index: 1;
    padding: 22px 15px 0px 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    background: #fff;
    width: 30%;
    margin: 0 auto;
    transition: left 0.3s ease-in-out;
  }
  .subinviteupload-section::-webkit-scrollbar {
    width: 0;
  }
  .subinviteupload-section ::-webkit-scrollbar-track {
    background: transparent;
  }
  .subinviteupload-section::-webkit-scrollbar-thumb {
    background: transparent;
  }
  .teaminvitesection {
    padding-bottom: 20px;
  }
  .teaminput-content {
    outline: unset !important;
    margin-bottom: 24px;
    padding: 10px;
  }
  .teamlink-imgcontent {
    width: 21px;
    margin-right: 15px;
  }
  .linkteam-content {
    font-family: "HelveticaNeue";
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
  }
  .joinlink-content {
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.22;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 80px;
    color: #262626;
  }
  .savebutton-context {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: normal;
    text-align: center;
    padding: 6px 40px;
    color: #fdfdfd;
  }
}
.dark .teammembers-section .cardssubBuckets-content {
  color: #ececec;
}
.dark .teammembers-section .headingteam-section {
  color: #fff;
}
.dark .teammembers-section .headingshare-section {
  color: #fff;
}
.dark .teammembers-section .headingaction-section {
  color: #fff;
}
.dark .teammembers-section .name-content {
  color: #fff;
}
.dark .teammembers-section .invited-content {
  color: #fff;
}
.dark .teammembers-section .shared-content {
  color: #fff;
}
.dark .teammembers-section .emailname-content {
  color: #fff;
}
.dark .teammembers-section .availableteam-content {
  color: #fff;
}
.dark .teammembers-section .teamnumber-content {
  color: #fff;
}
.dark .teammembers-section .invitedheadingteam-section {
  color: #fff;
}
.mblsort-section {
  display: none;
}
.sorttextteam-content {
  display: block;
}
.btnmbl-mblinvited {
  display: none;
}
.desktopteam-section {
  display: block;
}
.mblteam-section {
  display: none;
}
.desktopmembers-section {
  display: block;
}
.mblmembers-section {
  display: none;
}
@media (min-width: 320px) and (max-width: 771px) {
  .teammembers-section {
    .mblsort-section {
      display: block;
    }
    .sorttextteam-content {
      display: none;
    }
    .btndesktop-invited {
      display: none;
    }
    .btnmbl-mblinvited {
      font-family: "HelveticaNeue";
      font-size: 14px;
      font-weight: 500;
      margin-top: 16px;
      margin-bottom: 9px;
      font-style: normal;
      display: block;
      text-align: center;
      padding: 4px 10px;
    }
    .mblheadingteam-section {
      font-family: "HelveticaNeue";
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.65;
      width: unset;
      padding-bottom: 12px;
      text-align: left;
      color: #262626;
    }
    .desktopteam-section {
      display: none;
    }
    .mblteam-section {
      display: block;
    }
    .desktopmembers-section {
      display: none;
    }
    .mblmembers-section {
      display: block;
    }
    .mblunselectname-content {
      font-family: "HelveticaNeue";
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      text-align: left;
      width: 100%;
      color: #707070;
    }
    .mblunselectinvited-content {
      font-family: "HelveticaNeue";
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      line-height: 1.2;
      text-align: left;
      width: 100%;
      margin-left: 11%;
      color: #707070;
    }
  }
}
