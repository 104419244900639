.plans {
    width: 45%;
    margin: 30px auto;
    padding: 34px 25.5px 30px 30px;
    border-radius: 16px;
    border: solid 1px #c1c1c1;
    background-color: #fff;
    padding-top: 30px;
  
    hr {
      margin: 15px 0;
      border: 1px solid #c1c1c1;
    }
    .width-75 {
      width: 75%;
    }
    .width-25 {
      width: 25%;
    }
    .width-90 {
      width: 90%;
    }
    .width-10 {
      width: 10%;
    }
    span {
      font-size: 14px;
      color: #a5a5a5;
    }
    h4 {
      font-family: Objectivity-Medium;
      font-size: 32px;
      font-weight: 500;
      line-height: normal;
      text-align: left;
      color: #262626;
      margin-bottom: 12px;
    }
    .additional-content{
      font-family: "HelveticaNeue";
      font-size: 16px;
      font-weight: normal;
      line-height: normal;
      text-align: left;
      color: #262626;
      padding-top: 10px;
      margin-bottom: 12px;
    }
    h6 {
      font-family: Objectivity-Medium;
      font-size: 18px;
      line-height: normal;
      text-align: left;
      color: #262626;
    }
    .subscribe-btn {
      background-color: #ff6500;
      font-family: HelveticaNeue;
      font-size: 16px;
      font-weight: normal;
      line-height: normal;
      text-align: left;
      color: #fff;
      border-radius: 50px;
      padding: 10px 15px; 
    }
    .btn-column {
      text-align: end;
      margin: auto;
    }
    .plan-details {
      margin-top: 30px;
      h5 {
        font-family: Objectivity-Medium;
        font-size: 20px;
        font-weight: 500;
        line-height: normal;
        text-align: left;
        color: #262626;
      }
      .socialmedia-icons {
        img {
          margin-right: 12px;
          margin-top: 12px;
        }
      }
      p {
        font-family: HelveticaNeue;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        text-align: left;
        color: #707070;
      }
  
      .sub-heading {
        font-family: Objectivity-Medium;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        margin-top: 20px;
      }
      .icon-align img {
        margin-left: auto;
      }
      .count-align {
        p {
          text-align: right;
        }
      }
    }
}
.dark .additional-content{
  color: #ffffff;
}
.dark{
    .pricing-plans {
        h3 {
          color: #fff;
        }
        .sec-sectio {
          h6 {
            color: #ffffff;
          }
        }
        p {
          color: #c7c7c7;
        }
        .react-tabs__tab-list {
          background-color: #2f436a;
          .react-tabs__tab--selected {
            border-bottom: 0 !important;
            background-color: #ff6600 !important;
          }
        }
      }
    .plans {
        background-color: #051534;
        h4 {
          color: #ffffff;
        }
        h6 {
          color: #ffffff;
        }
        .plan-details {
          h5 {
            color: #ffffff;
          }
    
          .sub-heading {
            color: #ffffff;
          }
        }
      }
}