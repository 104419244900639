.slider-section {
    // margin-top: 30px;
    width: 100%;
}
.sliderjoin-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px; 
    padding: 20px 0px 10px 16px;
    color: #FFFFFF;
}
.slider-display {
    display: flex !important;
}
.topcircle-sectionscontent {
    min-width: 200px;
    padding: 6px 6px 10px 6px;
    margin: 0px 10px 20px 16px
}
.challenge-imgcontent {
    width: 100%;
}
.slick-initialized .slick-slide {
    padding-right: 10px;
}
.science-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.03em;
    padding-top: 8px;
    padding-bottom: 5px;
    color: #FFFFFF;
}
.rocketdisplay-content {
    display: flex;
}
.circlebutton-content {
    width: 53px;
    height: 26px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin: 0 auto;
    float: right;
    padding: 4px 12px;
    border-radius: 8px;
    border: none !important;  
    color: #F2F2F2;
    background: linear-gradient(111.76deg, #0A66C2 27.13%, #00B5D1 90.6%);
}
.slider-margin {
    margin-right: 10px;
}
.offers-blur #next-arrow{
    right: -2% !important;
    top: 48% !important;
    float: right !important;
    width: 30px !important;
    display: block !important;
    font-size: 0;
    line-height: 0;
    height: 30px;
    border: none;
    outline: 0;
}
.offers-blur #previous-arrow{
    left: -2% !important;
    top: 48% !important;
    /* float: right !important; */
    width: 30px !important;
    display: block !important;
    font-size: 0;
    line-height: 0;
    height: 30px;
    border: none;
    z-index: 1;
    outline: 0;
}
.slick-slider{
    width: 602px;
}

