.projectgrid-wholesection{
    .bucketHeader{
        margin-bottom: 20px;
    }
    // .actionsHolder{
    //     padding-top: 18px; 
    // } 
    .actionicon-content{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal; 
        text-align: left;
        color: #262626;
    }
    .trash-icon{
        height: 18px;
    }
    .social-textcontent{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: normal;
        text-align: left;
        color: #262626;
    }
    .iconsalign-content{
        margin-left: auto;
    }
    .resizeicon{
        width: 20px;
        height: 20px;
    }
    .cardtext-content{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.18;
        letter-spacing: normal;
        text-align: left;
        padding-top: 6px;
        padding-bottom: 14px;
        color: #141414;
    }
    .createddate-content{
        font-family: "HelveticaNeue";
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #484848;
    }
    .headingproject-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: left;
        padding-top: 15px;
        padding-bottom: 15px; 
        color: #000000;
    }
    .createalign-content{
        padding-top: 6px;
    }
}
.dark .projectgrid-wholesection hr{
    border: 1px solid #202F4F;
}
.dark .projectgrid-wholesection .actionicon-content{
    color: #bbb;
}
.dark .projectgrid-wholesection .social-textcontent{
    color: #fff;
}
.dark .projectgrid-wholesection .headingproject-content{
    color: #fff;
}
.dark .projectgrid-wholesection .cardtext-content{
    color: #c7c7c7;
}
.dark .projectgrid-wholesection .createddate-content{
    color: #bbb;
}