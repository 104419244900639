.settings-section{
    overflow-x: hidden;
    overflow-y: hidden;
    .settings-content{
        font-family: "HelveticaNeue";
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 26px;
        color: #262626;
    }
} 
.dark .settings-content{
    color: #fff;
}
.dark .settings-section .react-tabs__tab-list .react-tabs__tab--selected{
    font-weight: bold !important;
    background: unset !important;
    border-bottom: solid 4px #ff6600 !important;
    color: #fff !important;
    border-left: 0;
}
.dark .settings-section .react-tabs__tab{
    color: #fff;
}
@media (min-width: 320px) and (max-width: 771px){
    .settings-section{
        padding-left: 14px;
        padding-right: 14px;
    } 
}
  