.kindle-detail{
    h5{
        font-family: 'HelveticaNeue';
           font-size: 22px;
           font-weight: bold;
           font-style: normal;
           line-height: 30px;
           text-align: left;
           color: #262626;
    
         }
         .breadcrumbs-title{
          display: -webkit-inline-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          width: 250px;
         }
         .shared-public{
            background-color: #ffe6d6;
         }
         .project-nav{
            color: #8276b4;
         }
         .kindlebg-content{
          font-family: "HelveticaNeue";
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.13;
          letter-spacing: normal;
          text-align: center;
          padding: 6px 12px;
          color: #fff;
         }
      
         .last-updated{
            font-size: 14px;
            line-height: 1.22;
            text-align: left;
            color: #707070;
          }
         .kindle-card{
            padding: 20px 15px ;
            border-radius: 14px;
            border: solid 1px #655e5e;
            background-color: #fff; 
            width: 305px;

          h6{
            font-family: 'HelveticaNeue';
            font-size: 16px;
            font-weight: normal;
            line-height: 21px;
            text-align: left;
            color: #262626;
            // margin-bottom: 17px;
            margin-right: 40px;
          }
          .kindle-tick{
            border: 1px solid #707070;
            border-radius: 50px;
            padding: 5px;
            background: #fff;
        
          }
         
          .kindle-image{
            margin: 0 auto;
            margin-top: 10px;
            margin-bottom: 10px;
            // width: 350px;
            // height: 450px;
          }
          .kindle-box {
            padding: 7px 10px;
            border-radius: 5px;

          }
          .bg-green{
            background-color: #0fd161;
          }
          .bg-afc604{
            background-color: #afc604;
          }
          span{
            background: #fcffa8;
            border-radius: 14px;
            padding: 5px 12px;
            display: inline-block;
            font-size: 14px;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #262626;

          }
        }
        .kindlecard{
            border-radius: 14px;
            border: solid 1px #d6d6d6;
            background-color: #fff;
            padding: 15px;
            border-top: 14px solid #0fd161;
            margin-bottom: 30px;
       
          .menu-kindle{
            display: flex;
            justify-content: space-between;
            margin: auto 0;
            .actionicon-content {
                color: #8276b4;
             }

        }
        .publicytext-content{
         font-size: 14px;
       }
        .kindle-content{
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 21px;
            letter-spacing: normal;
            text-align: left;
            color: #262626;
            margin-top: 20px;
            padding-right: 50px;
        }
       
         }
}
.dark{
    .kindle-detail{
        .kindle-card{
            background: #051534;
            border: solid 1px #202F4F;
        }
        .menu-icon
        {
         filter:invert(77%) sepia(2%) saturate(815%) hue-rotate(176deg) brightness(95%) contrast(98%);
        }
        h5{
               color: #fff;
        
             }
             h6{
                color: #c7c7c7;
            }
             .project-nav{
                color: #8276b4;
             }
             hr{
                border: 1px solid #202F4F;
             }
             .kindle-content
             {
                color: #c7c7c7;
             }
             .last-updated{
                color: #c7c7c7;
              }
             .kindlecard{
                background: #051534;
                border: solid 1px #202F4F;
                border-top: 14px solid #0fd161;
               
             .menu-kindle{
                .actionicon-content {
                    color: #8276b4;
                 }
             }
             }       
        }
        .kindlecard:nth-child(2){
            border-top: 14px solid #afc604;
        }
    }
.kindlecard:nth-child(2){
    border-top: 14px solid #afc604;
}