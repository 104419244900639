.pricing-checkout.add-payment .pagination-checkout {
  margin-bottom: 30px;
}

.add-payment {
  .savebutton-context {
    padding: 10px 60px;
  }
  .pagination-checkout {
    margin-bottom: 30px;
  }
  h4 {
    font-family: Objectivity-Medium;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    color: #262626;
    margin-bottom: 30px;
  }
  .bank-options div {
    border: 1px solid#c1c1c1;
    text-align: center;
    padding: 10px 34px;
    border-radius: 12px; 
    vertical-align: middle;
    display: flex;
    align-items: center;
  }
  .acknowledgement {
    margin-bottom: 30px;
    p {
      font-family: HelveticaNeue;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 20px;
      letter-spacing: normal;
      text-align: left;
      color: #707070;
    }

    span {
      color: #707070;
    }
  }
}
.dark {
  .add-payment {
    h4 {
      color: #ffffff;
    }
    .inactive-icon {
      display: none;
    }
    .bank-options div {
      border: solid 1px #4c618f;
      color: #fff;
    }
    .react-tabs__tab--selected {
      .dark-inactive-icon {
        display: none;
      }
      .inactive-icon {
        display: none;
      }

      .acknowledgement {
        p {
          color: #c7c7c7;
        }
        span {
          color: #c7c7c7;
        }
      }
    }
  }
}
.dark .add-payment .react-tabs__tab {
  color: #ffffff;
}
