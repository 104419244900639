.payment-section{
    width: 80%;
    margin: 30px auto;
    // border-radius: 16px;
    // border: solid 1px #c1c1c1;
    // background-color: #fff;
    
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    .paymentsec-content{ 
        width: 50%;
        padding-top: 30px;
        padding: 34px 50px 30px 50px;
    } 
    .paymentsect-content{
        width: 50%;
        padding-top: 30px;
        box-shadow: -5px 3px 13px 0 rgba(0, 0, 0, 0.07);
        padding: 86px 50px 30px 50px;
        background-color: #fff;
    }
    .plandetail-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        line-height: normal; 
        text-align: left;
        padding-bottom: 30px;
        color: #8276b4;
    }
    .plan-content{
        color: #707070;
    }
    .radiobutton-content {
        width: 24px;
        height: 24px;
    }
    .priceline-section{
        margin-right: 64px;
    }
    input[name="selectedtag"] {
        accent-color: #000000;
    }
    .tagsdesc-content{ 
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: 24px;
        text-align: left;
        padding-bottom: 30px;
        color: #000;
    }
    .renewmonth-content{
        padding-top: 30px;
        padding-bottom: 6px;
    }
    .addmem-content{
        padding-bottom: 24px;
    }
    .yearlydet-content{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #707070;
    }
    .savetext-content{
        color: #ff6500;
    }
    .renewheading-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 100%;
        color: #262626;
    }
    .renewamount-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #262626;
    }
    .billing-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 40%;
        padding-bottom: 25px;
        color: #707070;
    }
    .paymentsub-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 24px;
        color: #707070;
    }
    .paycard-content{
        font-family: "HelveticaNeue";
        font-size: 18px; 
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 20px;
        color: #262626;
    }
    .teammember-section{
        margin-top: 12px;
        margin-bottom: 14px;
    }
    .teammember-section ::placeholder{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        color: #262626;
    }
    .decrementbutton-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: center;
        width: 30px;
        height: 31px;
        border-radius: 6px 0px 0px 6px;
        border: solid 1px #c1c1c1;
        background-color: #fff;
        color: #262626;
    }
    .focusteamcount-content{
        width: 50px;
        height: 31px;
        padding: 4px 10px;
        text-align: center;
        border: solid 1px #c1c1c1; 
    }
    .incrementbutton-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: center;
        width: 30px;
        height: 31px;
        border-radius: 0px 6px 6px 0px;
        border: solid 1px #c1c1c1;
        background-color: #fff;
        color: #262626;
    }
    .seat-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 24px;
        color: #707070;
    }
    .subtotal-section{
        padding-top: 24px;
        padding-bottom: 26px;
    }
    .promotion-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 30px;
        color: #8276b4;
    }
    .tax-section{
        padding-bottom: 24px;
    }
    .total-content{
        font-family: "HelveticaNeue";
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #262626;
    }
    .paymentcard-inputfield{
        width: 100%;
        height: 45px;
        outline: unset !important;
        margin-bottom: 20px;
        padding: 12px 20px 12px 20px;
        border-radius: 6px;
        border: solid 2px #e6e6e6;
        background-color: #fff;
    }
    .paymentsec-inputfield{
        width: 100%;
        height: 45px;
        outline: unset !important;
        margin-right: 10px; 
        padding: 12px 20px 12px 20px;
        border-radius: 6px;
        border: solid 2px #e6e6e6;
        background-color: #fff;
    }
    .paymentcvc-inputfield{
        width: 100%;
        height: 45px;
        outline: unset !important;
        padding: 12px 20px 12px 20px;
        border-radius: 6px;
        border: solid 2px #e6e6e6;
        background-color: #fff;
    }
    .paymentsecond-section{
        margin-top: 30px;
    }
    .default-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        margin-bottom: 30px;
        margin-top: 20px;
        color: #262626;
    }
    .submitpay-content{
        margin-top: 13px;
    }
    .saveupdatebutton-context{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.65;
        text-align: center;
        padding: 6px 40px;
        color: #fdfdfd;
    }
}
.dark .payment-section{
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.25);
}
.dark .paymentsect-content{
    background-color: unset;
    box-shadow: -5px 3px 13px 0 rgba(0, 0, 0, 0.15);
}
.dark .payment-section .total-content{
    color: #ffffff;
}
.dark .payment-section .default-content{
    color: #ffffff;
}
.dark .payment-section .paycard-content{
    color: #ffffff;
}
.dark .teammember-section ::placeholder{
    color: #fff;
}
.dark .payment-section .confirmrenew-content{
    color: #fff;
}
.dark .payment-section .totalplan-content{
    color: #fff;
}
.dark .payment-section .renewamount-content{
    color: #fff;
}
.dark .payment-section .renewheading-content{
    color: #fff;
}
.dark .payment-section .teamplan-content{
    color: #fff;
}
.dark .payment-section .teamslot-content{
    color: #c7c7c7;
}
.dark .payment-section .additional-content{
    color: #c7c7c7;
}
.dark .payment-section .billing-content{
    color: #c7c7c7;
}
.dark .payment-section .duemonth-content{
    color: #c7c7c7;
}
.dark .payment-section .visano-content{
    color: #bbb;
}
.dark .payment-section .termsagree-content{
    color: #c7c7c7;
}
.dark .payment-section .plan-content{
    color: #c7c7c7;
}
.dark .payment-section .decrementbutton-content{
    color: #fff;
    background-color: unset;
}
.dark .payment-section .focusteamcount-content{
    color: #ffffff;
    background-color: unset;
}
.dark .teammember-section ::placeholder{
    color: #fff;
}
.dark .payment-section .incrementbutton-content{
    color: #fff;
    background-color: unset;
}