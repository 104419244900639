.planteammembers-section{
    width: 100%;
    margin-left: 20px;
    padding: 20px;
    border-radius: 12px;
    border: solid 1px #c1c1c1; 
    .plandetail-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        line-height: normal; 
        text-align: left;
        padding-bottom: 30px;
        color: #8276b4;
    }
    .plan-content{
        color: #707070;
    }
    .confirmrenew-content{
        font-family: "HelveticaNeue";
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 30px;
        color: #262626;
    }
    .renewheading-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 38%;
        color: #262626;
    }
    .renewamount-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 62%;
        color: #262626;
    }
    .renewmonth-content{
        padding-bottom: 6px;
    }
    .billing-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 40%;
        padding-bottom: 25px;
        color: #707070;
    }
    .totalmembers-section{
        width: 32%;
    }
    .teamplan-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 6px;
        color: #262626;
    }
    .teamslot-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 6px;
        color: #707070;
    }
    .additional-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 20px;
        color: #707070;
    }
    hr{
        width: 40%;
    }
    .totalplan-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 38%;
        color: #000;
    }
    .subtotal-section{
        padding-top: 16px;
        padding-bottom: 25px;
    }
    .totalteam-section{
        padding-top: 16px;
        padding-bottom: 30px;
    }
    .tax-section{
        padding-bottom: 20px;
    }
    .today-section{
        padding-top: 20px;
        padding-bottom: 10px;
    }
    .duemonth-content{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 32%;
        color: #707070;
    }
    .monthdue-content{
        padding-bottom: 50px;
    }
    .visano-content{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: center;
        text-decoration: underline;
        color: #808080;
    }
    .termsagree-content{
        font-family: "HelveticaNeue";
        font-size: 17px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 40px;
        color: #707070;
    }
    .condition-content{
        text-decoration: underline;
    }
    .saveupdatebutton-context{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.65;
        text-align: center;
        padding: 6px 80px;
        color: #fdfdfd;
    }
    .buttonplan-content{
        margin-bottom: 60px;
    }
    .cancelplancard-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        padding-top: 10px;
        margin-left: 82px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #8276b4;
    }
    .decrementbutton-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: center;
        width: 30px;
        height: 31px;
        border-radius: 6px 0px 0px 6px;
        border: solid 1px #c1c1c1;
        background-color: #fff;
        color: #262626;
    }
    .focusteamcount-content{
        width: 50px;
        height: 31px;
        padding: 4px 10px;
        text-align: center;
        border: solid 1px #c1c1c1; 
    }
    .incrementbutton-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: center;
        width: 30px;
        height: 31px;
        border-radius: 0px 6px 6px 0px;
        border: solid 1px #c1c1c1;
        background-color: #fff;
        color: #262626;
    }
    .teammember-section ::placeholder{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        color: #262626;
    }
    .filesuploadpopup{
        position: fixed;
        overflow: scroll;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.6);
        width: 100%;
        display: flex;
        transition: left .3s ease-in-out;
    }
    .filesuploadpopup::-webkit-scrollbar{
        width: 0;
    }
    .filesuploadpopup ::-webkit-scrollbar-track{
        background: transparent; 
    }
    .filesuploadpopup::-webkit-scrollbar-thumb{
        background: transparent; 
    }
    .subinviteupload-section{
        position: fixed;
        overflow: scroll;
        top: 24%;
        left: 36%;
        border-radius: 10px;
        z-index: 1;
        padding: 22px 15px 0px 15px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
        background: #fff;
        width: 30%;
        margin: 0 auto;
        transition: left 0.3s ease-in-out;
    }
    .subinviteupload-section::-webkit-scrollbar{
        width: 0;
    }
    .subinviteupload-section ::-webkit-scrollbar-track{
        background: transparent; 
    }
    .subinviteupload-section::-webkit-scrollbar-thumb{
        background: transparent; 
    }
    .filesuploadpopup hr{
        width: 100%;
    }
    .teaminvitesection {
        padding-bottom: 15px;
    }
    .teammembers-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold; 
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #000;
    }
    .teammemberplan-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 26px;
        letter-spacing: normal;
        text-align: left;
        padding-top: 20px;
        padding-bottom: unset;
        color: #262626;
    }
    .buttonteam-content{
        padding-top: 6px;
        padding-bottom: 6px;
    }
    .cancelteam-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        display: flex;
        align-items: center;
        margin-right: 36px;
        color: #8276b4;
    }
}
.dark .planteammembers-section .confirmrenew-content{
    color: #fff;
}
.dark .planteammembers-section .totalplan-content{
    color: #fff;
}
.dark .planteammembers-section .renewamount-content{
    color: #fff;
}
.dark .planteammembers-section .renewheading-content{
    color: #fff;
}
.dark .planteammembers-section .teamplan-content{
    color: #fff;
}
.dark .planteammembers-section .teamslot-content{
    color: #c7c7c7;
}
.dark .planteammembers-section .additional-content{
    color: #c7c7c7;
}
.dark .planteammembers-section .billing-content{
    color: #c7c7c7;
}
.dark .planteammembers-section .duemonth-content{
    color: #c7c7c7;
}
.dark .planteammembers-section .visano-content{
    color: #bbb;
}
.dark .planteammembers-section .termsagree-content{
    color: #c7c7c7;
}
.dark .planteammembers-section .plan-content{ 
    color: #c7c7c7;
}
.dark .planteammembers-section .decrementbutton-content{
    color: #fff; 
    background-color: unset;
}
.dark .planteammembers-section .focusteamcount-content{
    background-color: unset;
}
.dark .planteammembers-sectionn ::placeholder{
    color: #fff;
}
.dark .planteammembers-section .incrementbutton-content{
    color: #fff;
    background-color: unset;
}