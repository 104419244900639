.billinginfo-section{
    width: 100%;
    margin-left: 20px;
    padding: 20px;
    border-radius: 12px;
    border: solid 1px #c1c1c1;
    .plandetails-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 33px;
        color: #262626;
    }
    .monthlyplan-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 14px;
        color: #262626;
    }
    .amountmonth-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #262626;
    }
    .currentplan-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding: 6px 12px;
        margin-left: 20px;
        margin-bottom: 20px;
        border-radius: 14px;
        background-color: rgba(193, 193, 193, 0.18);
        color: #262626;
    }
    .planrenew-content{
        font-family: "HelveticaNeue"; 
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 28px;
        color: #707070;
    }
    .profileplan-section{
        margin-bottom: 50px;
    }
    .purchasedplan-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-left: 8px;
        display: flex;
        align-items: center;
        color: #707070;
    }
    .countplan-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold !important;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-left: 8px;
        display: flex;
        align-items: center;
        color: #000000;
    }
    .planteam-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: 25px;
        text-align: center;
        display: flex;
        padding-left: 13px;
        align-items: center;
        text-decoration: underline;
        color: #808080;
    }
    .saveplanbutton-context{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.65;
        text-align: center;
        padding: 6px 60px;
        color: #fdfdfd;
    }
    .planupdate-section{
        padding-bottom: 30px;
    }
    .paymentdetails-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-top: 30px;
        padding-bottom: 30px;
        color: #262626;
    }
    .billing-content{
        padding-bottom: 30px;
    }
    .stripetest-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        text-align: left;
        color: #262626;
    }
    .visadetail-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.5;
        text-align: center;
        display: flex;
        align-items: center;
        color: #262626;
    }
    .visacarddot-content{
        width: 4px;
        height: 4px;
        margin-left: 52px;
        color: #5d5d5d;;
    }
    .defaultplan-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding: 6px 12px;
        margin-left: 20px;
        margin-top: -4px;
        border-radius: 14px;
        background-color: rgba(193, 193, 193, 0.18);
        color: #262626;
    }
    .plandismiss-content{
        margin-left: 193px;
    }
    .expire-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.5;
        text-align: center;
        margin-left: 143px;
        display: flex;
        align-items: center;
        color: #262626;
    }
    .plancard-content{
        padding-bottom: 40px;
    }
    .addpayment-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 40px;
        color: #8276b4;
    }
    .billingdetails-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 30px;
        color: #262626;
    }
    .billingaddress-content{
        padding-bottom: 30px;
    }
    .billinginfo-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        margin-right: 43px;
        color: #707070;
    }
    .billingaddr-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.63;
        text-align: left;
        color: #262626;
    }
    .updateplan-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 100px;
        color: #8276b4;
    }
}
.dark .plandetails-section .plandetailsfirst-section{
    background-color: unset;
}
.dark .react-tabs__tab-list .react-tabs__tab--selected {
    font-weight: bold !important;
    // background: #f3f4f6 !important;
    // border-bottom: unset !important;
    color: #fff !important;
    border-left: 0;
}
.dark .plandetailsfirst-section .react-tabs__tab-list .react-tabs__tab--selected {
    font-weight: bold !important;
    background: #f3f4f6 !important;
    border-bottom: unset !important;
    color: #000000 !important;
    border-left: 0;
}
.dark .plandetails-section .react-tabs__tab{
    color: #ffffff;
}
.dark .billinginfo-section .plandetails-content{
    color: #fff;
}
.dark .billinginfo-section .monthlyplan-content{
    color: #fff;
}
.dark .billinginfo-section .amountmonth-content{
    color: #fff;
}
.dark .billinginfo-section .currentplan-content{
    color: #fff;
}
.dark .billinginfo-section .planrenew-content{
    color: #c7c7c7;
}
.dark .billinginfo-section .purchasedplan-content{
    color: #bbb;
}
.dark .billinginfo-section .countplan-content{
    color: #fff;
}
.dark .billinginfo-section .planteam-content{
    color: #bbb;
}
.dark .billinginfo-section .paymentdetails-content{
    color: #fff;
}
.dark .billinginfo-section .visadetail-content{
    color: #fff;
}
.dark .billinginfo-section .defaultplan-content{
    color: #fff;
}
.dark .billinginfo-section .expire-content{
    color: #fff;
}
.dark .billinginfo-section .billingdetails-content{
    color: #fff;
}
.dark .billinginfo-section .billingaddr-content{
    color: #fff;
}
.dark .billinginfo-section .billinginfo-content{
    color: #C1C1C1;
}
.dark .billinginfo-section .stripetest-content{
    color: #fff;
}
.dark .billinginfo-section .plandismiss-content{
    filter: invert(77%) sepia(2%) saturate(815%) hue-rotate(176deg) brightness(95%) contrast(98%);
}
.dark .billinginfo-section .visacarddot-content{
    filter: invert(77%) sepia(2%) saturate(815%) hue-rotate(176deg) brightness(95%) contrast(98%);
}