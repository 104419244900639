.manage-section{
    .manageteam-content{
        font-family: "HelveticaNeue";
        font-size: 28px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 50px;
        color: #262626;
    }
    .manageseats-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 14%;
        padding-bottom: 18px;
        color: #262626;
    }
    .managecount-content{
        font-family: "HelveticaNeue";
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 14%;
        padding-bottom: 88px;
        color: #262626;
    }
    .manageadd-content{
        color: #8276b4;
    }
    .manageinvite-imgcontent{
        margin: 0 auto;
    }
    .manageassign-content{
        font-family: "HelveticaNeue";
        font-size: 28px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: center;
        padding-top: 30px;
        padding-bottom: 18px;
        color: #262626;
    }
    .manageactive-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: center;
        padding-bottom: 40px;
        color: #707070;
    }
    .managebutton-content{
        margin: 0 auto;
        display: flex;
    }
}
.dark .manage-section .manageteam-content{
    color: #ffffff;
}
.dark .manage-section .manageseats-content{
    color: #ffffff;
}
.dark .manage-section .managecount-content{
    color: #ffffff;
}
.dark .manage-section .manageassign-content{
    color: #ffffff;
}
.dark .manage-section .manageactive-content{
    color: #ffffff;
}