.page-border {
  border: 1px solid #c1c1c1;
  padding: 8px 19px;
  border-radius: 17px;
  height: 100%;
}
.Plan-checkout {
  .pagination-checkout {
    margin-bottom: 30px;
    span {
      color: #8276b4;
    }
  }
  .teaminvitesection {
    padding-bottom: 15px;
  }
  .buttonpadding-content {
    margin-right: 0;
  }
  .nothanks-btn {
    font-family: "HelveticaNeue";
    font-size: 18px;
    padding-top: 10px;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    color: #8276b4;
    margin-right: 40px;
  }
  label {
    font-family: HelveticaNeue;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
  }
  h4 {
    font-family: Objectivity-Medium;
    font-size: 22px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1.1px;
    text-align: left;
    color: #262626;
    margin-bottom: 30px;
  }
  textarea {
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 10px;
    margin-top: 14px;
    width: 100%;
  }
  textarea::placeholder {
    font-family: HelveticaNeue;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
  }
  .cancelreason-section {
    input {
      width: 24px;
      height: 24px;
    }
  }
  .Subscription-head {
    font-family: Objectivity-Medium;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    color: #000;
  }
  .dismissSubfiles-content {
    margin-bottom: 0;
  }
  h6 {
    font-family: Objectivity-Medium;
    font-size: 18px;
    font-weight: normal;
    text-align: left;
    color: #262626;
  }
  .currentplan-price {
    font-family: Objectivity-Medium;
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    color: #262626;
    margin-top: 5px;
  }
  .currentplan {
    padding: 6px 25px;
    border-radius: 50px;
    background-color: rgba(193, 193, 193, 0.18);
    margin-left: 8px;
    font-family: Objectivity;
    font-size: 16px;
    font-weight: normal;
    color: #262626;
  }
  .plan-summary {
    p {
      font-family: HelveticaNeue;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.25;
      text-align: left;
      color: #262626;
    }
    .cancel-summary {
      color: #707070;
    }
  }
  .buttonpadding-content {
    padding: 8px 15px;
  }
  .cancel-subscription {
    font-family: "HelveticaNeue";
    font-size: 18px;
    padding-top: 10px;
    margin-left: 82px;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    color: #8276b4;
  }
  .cancelsubscription-popup {
    position: fixed;
    // overflow: scroll;
    top: 15%;
    left: 36%;
    border-radius: 10px;
    z-index: 1;
    padding: 22px 15px 22px 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    background: #fff;
    width: 30%;
    margin: 0 auto;
    transition: left 0.3s ease-in-out;
  }
  .cancelsubscription-btn.submit-btn {
    padding: 8px 30px;
    margin-right: 0;
  }
  .cancel-summary p {
    font-family: HelveticaNeue;
    font-size: 16px;
    font-weight: 500;

    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
  }
}

.dark {
  .Plan-checkout {
    input[type="radio"] {
      filter: none;
    }
    .pagination-checkout {
      p {
        color: #ffffff;
      }
    }
    .currentplan-price {
      color: #ffffff;
    }
    h4 {
      color: #ffffff;
    }
    h6 {
      color: #ffffff;
    }
    .currentplan {
      color: #c7c7c7;
    }
  }
  .plan-summary {
    p {
      color: #c7c7c7;
    }
    .cancel-summary {
      color: #c1c1c1;
    }
  }
  .cancelsubscription-popup {
    hr {
      border: 1px solid #dedede !important;
    }
  }
}
