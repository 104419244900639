.react-tagsinput-tag {
  // line-height: 24px;
  // height: 38px;
  background-color: #ffffff;
  border-color: #c5c5c5;
  border-radius: 20px;
  font-size: 14px;
}
.react-tagsinput-tag:nth-child(even) {
  background-color: #ffffff;
  border-color: #c5c5c5;
  border-radius: 20px;
}
.react-tagsinput-input {
  width: 250% !important;
  padding: 0;
  font-family: "HelveticaNeue" !important;
  color: #707070 !important;
  font-size: 17px;
}
.react-tagsinput-input::placeholder {
  color: #707070 !important;
}
.menusubitems-content input::placeholder {
  color: #707070 !important;
}
// .sharetop-section {
//   margin-top: 10px;
// }
.dropdownshareteam-content {
  width: 127px;
  text-align: left;
}
.subinviteupload-section .tagscard .dropdown li {
  padding: 3px 20px;
}
.share-pos {
  position: relative;
}
