.purchasesummary-section {
  width: 100%;
  margin-left: 20px;
  padding: 20px;
  border-radius: 0;
  border-left: solid 1px #c1c1c1;
  .plandetail-content {
    font-family: "HelveticaNeue";
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 30px;
    color: #8276b4;
  }
  .plan-content {
    color: #707070;
  }
  .confirmrenew-content {
    font-family: "HelveticaNeue";
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1.1px;
    text-align: left;
    padding-bottom: 30px;
    color: #262626;
  }
  .renewheading-content {
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.9px;
    text-align: left;
    width: 67%;
    color: #262626;
  }
  .renewamount-content {
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.9px;
    text-align: right;
    width: 10%;
    color: #262626;
  }
  .renewmonth-content {
    padding-bottom: 6px;
  }
  .billing-content {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    width: 67%;
    padding-bottom: 25px;
    color: #707070;
  }
  .teamplan-content {
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.9px;
    text-align: left;
    padding-bottom: 6px;
    color: #262626;
  }
  .teamslot-content {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 6px;
    color: #707070;
  }
  .additional-content {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 20px;
    color: #707070;
  }
  hr {
    width: 77%;
  }
  .totalplan-content {
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    width: 67%;
    color: #000;
  }
  .subtotal-section {
    padding-top: 16px;
    padding-bottom: 25px;
  }
  .totalteam-section {
    padding-bottom: 30px;
  }
  .tax-section {
    padding-bottom: 20px;
  }
  .today-section {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .duemonth-content {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    width: 67%;
    color: #707070;
  }
  .monthdue-content {
    padding-bottom: 50px;
  }
  .visano-content {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.6px;
    text-align: center;
    text-decoration: underline;
    color: #808080;
  }
  .termsagree-content {
    font-family: "HelveticaNeue";
    font-size: 17px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 20px;
    color: #707070;
    width: 77%;
  }
  .condition-content {
    text-decoration: underline;
  }
  .saveupdatebutton-context {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: normal;
    text-align: center;
    padding: 6px 80px;
    color: #fdfdfd;
  }
  .buttonplan-content {
    margin-bottom: 60px;
  }
  .cancelplancard-content {
    font-family: "HelveticaNeue";
    font-size: 18px;
    padding-top: 10px;
    margin-left: 82px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8276b4;
  }
}
.dark .purchasesummary-section .confirmrenew-content {
  color: #fff;
}
.dark .purchasesummary-section {
  border-left: 1px solid #202f4f;
}
.dark .purchasesummary-section .totalplan-content {
  color: #fff;
}
.dark .purchasesummary-section .renewamount-content {
  color: #fff;
}
.dark .purchasesummary-section .renewheading-content {
  color: #fff;
}
.dark .purchasesummary-section .teamplan-content {
  color: #c7c7c7;
}
.dark .purchasesummary-section .teamslot-content {
  color: #c7c7c7;
}
.dark .purchasesummary-section .additional-content {
  color: #c7c7c7;
}
.dark .purchasesummary-section .billing-content {
  color: #c7c7c7;
}
.dark .purchasesummary-section .duemonth-content {
  color: #c7c7c7;
}
.dark .purchasesummary-section .visano-content {
  color: #bbb;
}
.dark .purchasesummary-section .termsagree-content {
  color: #c7c7c7;
}
.saveupdatebutton-context.apply {
  padding: 8px 35px;
}
.promotion-code {
  color: #8276b4;
  font-family: "Objectivity-Medium";
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}
