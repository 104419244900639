.myprofile-section{
    overflow: hidden;
    .myprofile-content{
        font-family: "HelveticaNeue";
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 26px;
        color: #262626;
    }
    .profile-tab{
        margin-bottom: 30px;
    }
}
.dark .myprofile-content{
    color: #fff;
}
.react-tabs__tab--selected{
    font-weight: bold !important;
    background: unset !important;
    border-bottom: solid 4px #ff6600 !important;
    color: #000000 !important;
}
.myprofile-section .react-tabs__tab {
    color: #000000;
}
.dark .profile-tab .react-tabs__tab-list .react-tabs__tab--selected{
    color: #fff;
}
.dark .profile-tab .react-tabs__tab {
    color: #fff;
}
@media (min-width: 320px) and (max-width: 771px){
    .myprofile-section{
        padding-left: 14px;
        padding-right: 14px;
    } 
}