$base-color: #262626;
$color-white: #ffffff;
$bf-color: #f8f8f8;
$color-grey: #707070;
$border-orange: #ff6600;
$color-scroll: #c6c6c6;

.bookmarksGrid-wholesection {
  .bucketHeader {
    margin-bottom: 20px;
  }
  .subbucketsbg-content {
    padding: 1px 12px;
  }
  .filescard-section {
    // padding-top: 26px;
    // padding-bottom: 26px;
    // grid-gap: 10px;
    // grid-row-gap: 10px;
  }
  .cardHolder-cards {
    width: 313px;
    height: 388px;
    border-radius: 14px;
    padding: 10px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e5e5e5;
    background-color: #fff;
  }
  .cardssubBuckets-content {
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
  }
  .cardContent {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    text-align: left;
    width: 100%;
    color: #262626;
    width: 100%;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-inline-box;
  }
  .brandspace-content {
    padding-bottom: 5px;
    display: flex;
  }
  .brandtext-content {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 26px !important;
    letter-spacing: normal;
    text-align: left;
    padding-top: 4px;
    padding-bottom: 6px;
    overflow: hidden;
    // white-space: unset !important;
    text-overflow: ellipsis;
    padding-right: 4px;
    padding-left: 4px;
    max-width: 262px;
    color: #262626 !important;
  }
  .fashioncards-content {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    display: flex;
    padding-bottom: 2px;
    color: #707070;
  }
  .uploadedcards-content {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    text-align: left;
    display: flex;
    color: #707070;
  }
  .profile-displaycontent {
    margin-top: unset;
    margin-left: auto;
  }
  .profile-imgcontent {
    display: flex;
    margin-bottom: 2px;
  }
  .profile-imgcontent img {
    width: 25px;
    height: 25px;
  }
  .profile-imgcontent img:nth-child(2) {
    margin-left: -9px;
  }
  .profile-imgcontent img:nth-child(3) {
    margin-left: -12px;
  }
  .sharesright-content {
    font-family: "HelveticaNeue";
    font-size: 10px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: right;
    margin-top: 4px;
    color: #000;
  }
  .cardsicon-content {
    padding-top: 5px;
    // padding-bottom: 10px;
  }
  .bucketHeader {
    margin-bottom: 20px;
  }
  .bookmarksfilesuploadpopup {
    position: fixed;
    overflow: scroll;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    display: flex;
    transition: left 0.3s ease-in-out;
  }
  .bookmarkssubfilesupload-section {
    position: fixed;
    overflow: scroll;
    top: 15%;
    left: 36%;
    border-radius: 10px;
    z-index: 1;
    padding: 22px 15px 0px 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    background: #fff;
    width: 30%;
    // height: 70%;
    margin: 0 auto;
    transition: left 0.3s ease-in-out;
  }
  .bookmarksfilesuploadpopup::-webkit-scrollbar {
    width: 0;
  }
  .bookmarksfilesuploadpopup ::-webkit-scrollbar-track {
    background: transparent;
  }
  .bookmarksfilesuploadpopup::-webkit-scrollbar-thumb {
    background: transparent;
  }
  .bookmarkssubfilesupload-section::-webkit-scrollbar {
    width: 0;
  }
  .bookmarkssubfilesupload-section ::-webkit-scrollbar-track {
    background: transparent;
  }
  .bookmarkssubfilesupload-section::-webkit-scrollbar-thumb {
    background: transparent;
  }
  .fileupload-section {
    padding-bottom: 10px;
  }
  .fileupload-content {
    font-family: "Objectivity-Medium";
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }
  .dismissSubfiles-content {
    margin-left: auto;
    cursor: pointer;
  }
  .uploadtext-content {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding-top: 14px;
    color: #262626;
  }
  .menusubitems-content {
    // height: 35px;
    justify-content: space-between;
    padding: 4px 14px;
    margin-top: 14px;
    margin-bottom: 24px;
    border: solid 1px #dedede;
    background-color: #f5f5f5;
  }
  .createdsubtext-content {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 26px;
    letter-spacing: normal;
    text-align: left;
    // padding-right: 20px;
    color: #707070;
  }
  .addnotes-content {
    height: 130px;
    padding: 4px 14px;
    margin-bottom: 24px;
    border-radius: 16px;
    border: solid 1px #dedede;
    background-color: #f5f5f5;
  }
  .browser-content {
    height: 130px;
    border-radius: 16px;
    border: solid 1px rgba(112, 112, 112, 0.4);
    border-style: dashed;
    background-color: #f5f5f5;
  }
  .uploadimg-content {
    margin: 0 auto;
    display: flex;
    padding-top: 30px;
    padding-bottom: 10px;
  }
  .drag-content {
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
  }
  .browse-content {
    color: #8276b4;
  }
  .savebutton-context {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: normal;
    text-align: center;
    padding: 6px 30px;
    color: #fdfdfd;
  }
  .lastfile-section {
    padding-top: 32px;
  }
  .cancel-content {
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 36px;
    letter-spacing: normal;
    text-align: center;
    padding-right: 30px;
    color: #8276b4;
  }
  .bordered-content {
    padding: 1px 10px;
    border-radius: 18px;
    border: solid 1px #d8dee5;
    background-color: #fbfbfb;
  }
  .createdtextborder-content {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: Bold;
    font-style: normal;
    line-height: 26px;
    letter-spacing: normal;
    text-align: left;
    padding-right: 10px;
    color: #262626;
  }
  .search-tab {
    input {
      background-color: #fff;
      padding-left: 12px;
      margin: 23px 12px 19px;
      width: 94%;
    }
    .search-magnifier {
      right: 18px;
      width: 30px;
    }
    input::placeholder {
      color: $color-grey;
    }
  }
  .search-tab {
    input::placeholder {
      color: #acacac;
    }
  }
  .search-tab input {
    background-color: #f5f5f5;
    padding-left: 12px;
    margin: 23px 12px 19px;
    width: 94%;
  }
  .wholesection-content {
    width: 300px;
    left: 4%;
  }
  .react-tabs {
    display: flex;
    color: $base-color;
    // background: $co;
  }
  .tab-menu {
    margin-left: auto;
    opacity: 0;
  }
  .react-tabs__tab--selected .tab-menu {
    opacity: 1;
  }
  .react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    width: 325px;
    margin: 0;
    padding: 0;
    color: white;
    background: $bf-color;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
  }
  .cardList .tab-scroll {
    height: 98%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .tab-scroll::-webkit-scrollbar {
    width: 10px;
    height: 100px;
  }
  .tab-scroll::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .tab-scroll::-webkit-scrollbar-thumb {
    background-color: $color-scroll;
    border-radius: 8px;
  }
  .react-tabs__tab {
    list-style: none;
    padding: 12px 6px;
    cursor: pointer;
    color: $base-color;
  }
  .react-tabs__tab {
    p {
      font-family: "HelveticaNeue";
      font-size: 16px;
      font-weight: normal;
      line-height: 20px;
      color: $base-color;
    }
  }
  .react-tabs__tab:focus {
    border: none;
  }
  .react-tabs__tab-panel--selected {
    display: block;
  }
  .react-tabs__tab {
    // border: unset !important;
    padding-left: 20px;
    display: flex;
    // border-left: 6px solid $bf-color;
    // border-bottom: solid 1px #dedede;
  }
  .react-tabs__tab--selected {
    padding-left: 21px;
  }
  .checkbox-wrapper-24 *,
  .checkbox-wrapper-24 *:after,
  .checkbox-wrapper-24 *:before {
    box-sizing: border-box;
  }
  .checkbox-wrapper-24 {
    display: flex;
    margin-right: 15px;
    margin-top: 4px;
  }
  .checkbox-wrapper-24 span {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 23px;
    text-align: left;
    margin-left: 11px;
    color: #000;
  }
  .checkbox-wrapper-24 input {
    position: absolute;
    opacity: 0;
  }
  .checkbox-wrapper-24 input:checked + label svg path {
    stroke-dashoffset: 0;
  }
  .checkbox-wrapper-24 input:focus + label {
    transform: scale(1.03);
  }
  .checkbox-wrapper-24 input + label {
    display: block;
    border: 2px solid $color-grey;
    width: 18px;
    height: 18px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 2px;
  }
  .react-tagsinput {
    background-color: unset !important;
    border: unset !important;
    overflow: hidden;
    padding-left: 5px;
    padding-top: unset !important;
  }
  .checkbox-wrapper-24 input + label:active {
    transform: scale(1.05);
    border-radius: 12px;
  }
  .checkbox-wrapper-24 input + label svg {
    pointer-events: none;
    padding: 5%;
  }
  #search:placeholder {
    font-size: 16px;
  }
  .checkbox-wrapper-24 input + label svg path {
    fill: none;
    stroke: #333;
    stroke-width: 4px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 100;
    stroke-dashoffset: 101;
    transition: all 250ms cubic-bezier(1, 0, 0.37, 0.91);
  }
  .react-tabs__tab-list {
    .react-tabs__tab--selected {
      background-color: #ffffff;
      color: $base-color;
    }
  }
  .spacetag-content {
    display: flex;
    padding-left: 8%;
  }
  .spacetag-content .tab-menu {
    margin-left: auto;
  }
  .spacetag-content .tab-menu {
    margin-left: 165% !important;
  }
  .spacesubtag-content {
    display: flex;
    padding-left: 16%;
  }
  .spacesubtag-content .tab-menu {
    margin-left: 156% !important;
  }
  .cardHolders {
    border: 1px solid #655e5e;
  }
  .dark .cardHolders {
    border: solid 1px #4c618f;
  }
  .react-tabs__tab--selected .dropdown-icon {
    transform: rotate(0);
  }
  .dropdown-icon {
    transform: rotate(270deg);
  }
  .createdbuckettext-content {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 26px;
    letter-spacing: normal;
    text-align: left;
    padding-right: 12px;
    color: #262626;
  }
  .bucketswhole-spacecontent {
    margin-right: 10px;
    padding: 1px 10px;
    border-radius: 18px;
    border: solid 1px #d8dee5;
    background-color: #fbfbfb;
  }
}
.dark .bookmarksGrid-wholesection .cardssubBuckets-content {
  color: #ececec;
}
.dark .bookmarksGrid-wholesection .brandspace-content {
  color: #fff;
}
.dark .bookmarksGrid-wholesection .brandtext-content {
  color: #fff!important;
}
.dark .bookmarksGrid-wholesection .createdtextborder-content {
  color: #fff;
}
.dark .bookmarksGrid-wholesection .bordered-content {
  border: solid 1px #4c618f;
  background-color: unset;
}
.dark .bookmarksGrid-wholesection .react-tabs {
  background-color: #0c2556;
}
.dark .search-tab input {
  background-color: #2f436a;
}
.dark .bookmarksGrid-wholesection .react-tabs__tab-list {
  background-color: #112750;
}
.dark .react-tabs__tab p {
  color: #ffffff;
}
.dark .react-tabs__tab-list .react-tabs__tab--selected {
  background: #2f436a;
}
.dark .bookmarksGrid-wholesection .bucketswhole-spacecontent {
  border: solid 1px #b5bac1;
  background-color: rgba(251, 251, 251, 0.23);
}
.dropdownicon-bucketcontent {
  color: #707070;
}
.dark .dropdownicon-bucketcontent {
  color: #fff;
}
.dark .bookmarksGrid-wholesection .createdbuckettext-content {
  color: #fff;
}
.dark .bookmarksGrid-wholesection .menusubbucketitems-content {
  // color: #fff;
  // border: solid 1px #d8dee5;
  // background-color: rgba(251, 251, 251, 0.23);
  background-color: #202F4F;
  color: #fff !important;
  border: unset;
}
.dark .bookmarksGrid-wholesection .cardHolder-cards {
  border: solid 1px #4c618f;
  background-color: unset;
}
.dark .bookmarksGrid-wholesection .fashioncards-content {
  color: #c7c7c7;
}
.dark .bookmarksGrid-wholesection .uploadedcards-content {
  color: #c7c7c7;
}
.Toastify__toast{
  box-shadow: none!important;
}
@media (min-width: 320px) and (max-width: 771px) {
  .bookmarksGrid-wholesection .cardHolder-cards {
    margin-left: 10px;
    width: 353px;
    border-radius: 14px;
    padding: 10px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e5e5e5;
    background-color: #fff;
  }
  .bookmarksGrid-wholesection .bookmarkssubfilesupload-section {
    position: fixed;
    overflow: scroll;
    top: 16%;
    left: 2%;
    border-radius: 10px;
    z-index: 1;
    padding: 22px 15px 0px 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    background: #fff;
    width: 95%;
    margin: 0 auto;
    transition: left 0.3s ease-in-out;
  }
  .tags-pagesection .bookmarksGrid-wholesection .bookmarkssubfilesupload-section{
    position: absolute;
    overflow: scroll;
    top: 15px;
    left: 0px;
    right: 0px;
    border-radius: 10px;
    z-index: 1;
    padding: 22px 15px 0px 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    background: #fff;
    width: 90%;
    margin: 0 auto;
    transition: left 0.3s ease-in-out;
  }
}
.dark
  .bookmarksGrid-wholesection
  .bookmarkssubfilesupload-section
  .menusubbucketitems-content {
  background: #f5f5f5;
}
