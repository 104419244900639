.personaldetails-section{
    overflow: hidden;
    .profiledetails-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #262626;
    }
    .uploadwhole-section{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .profilewhole-section{
        display: flex; 
        margin-left: 30px;
        align-items: center;  
    }
    .profiledetailimg-content{
        width: 130px;
        height: 130px;
        object-fit: cover;
        border-radius: 100px;
    }
    .buttonpadding-content {
        padding-top: 6px;
        padding-bottom: 6px;
        display: flex;
    }
    .emailbuckets-context{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.65;
        text-align: center;
        padding: 1px 30px;
        color: #fdfdfd;
    }
    .userdetailswhole-section{
        display: flex;
        flex-wrap: wrap;
        grid-gap: 20px;
        margin-bottom: 40px;
    }
    .userdetails-section{
        width: 335px;
        padding: 12px 227px 12px 20px;
        border-radius: 22.5px;
        background-color: #f5f5f5;  
    }
    .userdetails-content{ 
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        outline: unset;
        border: unset;
        padding-left: unset;
        background-color: unset;
        color: #262626;
    }
    .userdetails-content::placeholder{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        outline: unset;
        border: unset;
        background-color: unset;
        color: #707070;
    }
    .account-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-top: 40px;
        padding-bottom: 30px;
        color: #262626;
    }
    .accountdetails-section{
        width: 700px;
        padding: 12px 227px 12px 20px;
        border-radius: 22.5px;
        background-color: #f5f5f5;  
    }
    .accountdetails-content{ 
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        outline: unset;
        border: unset;
        background-color: unset;
        color: #000;
        width: 100%;
    }
    .accountdetails-content::placeholder{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        outline: unset;
        border: unset;
        background-color: unset;
        color: #000;
    }
    .changedetailsdesktop-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        display: flex;
        align-items: center;
        margin-left: 30px;
        cursor: pointer;
        color: #8276b4;
    }
    .changedetailsmbl-content{
        display: none;
    }
    .password-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-top: 40px;
        padding-bottom: 12px;
        color: #262626;
    }
    .passwordlogin-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 20px;
        color: #707070;
    }
    .lastsection-content{
        margin-bottom: 30px;
    }
    .remainderlast-section{
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .cancel-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        display: flex;
        padding-left: 40px;
        align-items: center;
        text-align: left;
        color: #8276b4;
    }
    .dismissSubfiles-content{
        cursor: pointer;
    }
    .letter-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-top: 20px;
        padding-bottom: 30px;
        color: #262626;
    }
    .password-section{
        margin: 0 auto;
        display: flex;
        padding: 12px 227px 12px 20px;
        border-radius: 22.5px;
        background-color: #f5f5f5;  
    }
    .second-section{
        margin-top: 20px;
    }
    .bookmarkssubfilesupload-section {
        position: fixed;
        overflow: scroll;
        top: 15%;
        left: 36%;
        border-radius: 10px;
        z-index: 1;
        padding: 22px 15px 0px 15px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
        background: #fff;
        width: 30%;
        height: unset;
        margin: 0 auto;
        transition: left 0.3s ease-in-out;
    }
    .react-tabs__tab-panel input{
        padding: unset;
    }
    .secondsectionpersonal-content{
        margin-bottom: 40px;
    }
    .close-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.8px; 
        text-align: left;
        padding-top: 20px;
        padding-bottom: 20px;
        text-decoration: underline;
        cursor: pointer;
        color: #808080;
    }
    .actiontext-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: left;
        padding-top: 20px;
        color: #262626;
    }
    .saveteambutton-context{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.65;
        letter-spacing: normal;
        text-align: center;
        height: 30px;
        padding: 3px 50px;
        color: #fdfdfd;
    }
    .cancelteam-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.8px;
        text-align: left;
        padding-top: 13px;
        padding-right: 40px;
        color: #8276b4;
        cursor: pointer;
    }
}
.dark .personaldetails-section .profiledetails-content{
    color: #fff;
}
.dark .personaldetails-section .account-content{
    color: #fff;
}
.dark .personaldetails-section .password-content{
    color: #fff;
}
.dark .personaldetails-section .userdetails-section{
    border: solid 1px #4C618F;
    background-color: #051534;
}
.dark .personaldetails-section .accountdetails-section{
    border: solid 1px #4C618F;
    background-color: #051534;
}
.dark .accountdetails-content::placeholder{
    color: #fff;
}
.dark .hr.paswordline-content{
    color: #dedede !important;
}
.dark .uploadwhole-section img{
    // filter: invert(77%) sepia(2%) saturate(815%) hue-rotate(176deg) brightness(95%) contrast(98%);
}
.dark .personaldetails-section .userdetails-content{
    color: #ffffff;
}
.dark .personaldetails-section .passwordlogin-content{
    color: #bbbbbb;
}
.dark .personaldetails-section .accountdetails-content{
    color: #ffffff;
}
.dark .personaldetails-section .close-content{
    color: #8D8D8D;
}
@media (min-width: 320px) and (max-width: 771px){
    .personaldetails-section{
        .userdetails-section {
            width: 346px; 
        }
        .accountdetails-section {
            width: 247px;
        }
        .changedetailsdesktop-content{
            display: none;
        }
        .changedetailsmbl-content{
            font-family: "HelveticaNeue";
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            display: flex;
            align-items: center;
            margin-left: 30px;
            cursor: pointer;
            color: #8276b4;
        }
        .emailbuckets-context {
            padding: 1px 28px;
        }
        .bookmarkssubfilesupload-section {
            position: fixed;
            overflow: scroll;
            top: 16%;
            left: 5%;
            border-radius: 10px;
            z-index: 1;
            padding: 22px 15px 0px 15px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
            background: #fff;
            width: 90%;
            height: unset;
            margin: 0 auto;
            transition: left 0.3s ease-in-out;
        }
    } 
}