.invoicesummary-section{
    width: 100%;
    margin-left: 20px;
    padding: 20px;
    border-radius: 12px;
    border: solid 1px #c1c1c1; 
    .plandetail-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        line-height: normal; 
        text-align: left;
        padding-bottom: 30px;
        color: #8276b4;
    }
    .plan-content{
        color: #707070;
    }
    .confirmrenew-content{
        font-family: "HelveticaNeue";
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 21px;
        color: #262626;
    }
    .confirmitem-content{
        font-family: "HelveticaNeue";
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-top: 10px;
        padding-bottom: 21px;
        color: #262626;
    }
    .summarydate-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.33;
        text-align: left;
        padding-bottom: 12px;
        color: #707070;
    }
    .summaryplan-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        line-height: 18px;
        text-align: left;
        padding-bottom: 16px;
        width: 10%;
        color: #707070;
    }
    .summarydetail-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        line-height: 18px;
        text-align: left;
        padding-bottom: 16px;
        align-items: center;
        display: flex;
        color: #262626;
    }
    .renewamount-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 62%;
        color: #262626;
    }
    hr{
        width: 40%;
    }
    .totalplan-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 38%;
        color: #000;
    }
    .due-section{
        padding-top: 20px;
    }
    .subtotal-section{
        padding-bottom: 25px;
    }
    .totalteam-section{
        padding-bottom: 30px;
    }
    .tax-section{
        padding-bottom: 20px;
    }
    .today-section{
        padding-top: 20px;
        padding-bottom: 10px;
    }
    .duemonth-content{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 32%;
        color: #707070;
    }
    .monthdue-content{
        padding-bottom: 50px;
    }
    .visano-content{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.6px;
        text-align: center;
        text-decoration: underline;
        color: #808080;
    }
    .termsagree-content{
        font-family: "HelveticaNeue";
        font-size: 17px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 40px;
        color: #707070;
    }
    .condition-content{
        text-decoration: underline;
    }
    .saveupdatebutton-context{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.65;
        text-align: center;
        padding: 6px 40px;
        color: #fdfdfd;
    }
    .buttonplan-content{
        margin-top: 58px;
        margin-bottom: 60px;
    }
    .cancelplancard-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        padding-top: 10px;
        margin-left: 82px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #8276b4;
    }
}
.dark .invoicesummary-section .confirmrenew-content{
    color: #fff;
}
.dark .invoicesummary-section .confirmitem-content{
    color: #fff;
}
.dark .invoicesummary-section .totalplan-content{
    color: #fff;
}
.dark .invoicesummary-section .renewamount-content{
    color: #fff;
}
.dark .invoicesummary-section .duemonth-content{
    color: #c7c7c7;
}
.dark .invoicesummary-section .visano-content{
    color: #bbb;
}
.dark .invoicesummary-section .termsagree-content{
    color: #c7c7c7;
}
.dark .invoicesummary-section .plan-content{
    color: #c7c7c7;
}
.dark .invoicesummary-section .summaryplan-content{
    color: #C7C7C7;
}
.dark .invoicesummary-section .summarydetail-content{
    color: #fff;
}
.dark .invoicesummary-section .summarydate-content{
    color: #C7C7C7;
}