.billing-section{
    .subhead-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 13%;
        padding-bottom: 16px;
        color: #262626;
    }
    .subdetail-content{
        font-family: "HelveticaNeue";
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 13%;
        color: #262626;
    }
    .subadd-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #262626;
        cursor: pointer;
    }
    .subinviteupload-section hr{
        width: 100%;
    }
    .addseat-content{
        padding-left: 10px;
        color: #8276b4;
    }
    .subdetail-section{
        margin-bottom: 40px;
    }
    .autorenew-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 30px;
        color: #707070;
    }
    .saveupdatebutton-context{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.65;
        text-align: center;
        padding: 6px 40px;
        color: #fdfdfd;
        cursor: pointer;
    }
    .payupdatebutton-context{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.65;
        text-align: center;
        padding: 6px 60px;
        color: #fdfdfd;
        cursor: pointer;
    }
    .cancelsub-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: center;
        align-items: center;
        display: flex;
        padding-left: 35px;
        color: #8276b4; 
        cursor: pointer;
    }
    .submitbill-content{
        margin-bottom: 30px;
    }
    .paybill-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-top: 30px;
        padding-bottom: 28px;
        color: #262626;
    }
    .visadetail-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.5;
        text-align: center;
        display: flex;
        align-items: center;
        width: 30%;
        color: #262626;
    }
    .expire-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.5;
        text-align: center; 
        margin-left: 60px;
        display: flex;
        align-items: center;
        color: #262626;
    }
    .plandismiss-content{
        margin-left: 30px;
    }
    .defaultplan-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding: 6px 12px;
        margin-left: 20px;
        margin-top: -4px;
        border-radius: 14px;
        background-color: rgba(193, 193, 193, 0.18);
        color: #262626;
    }
    .billinfo-section{
        width: 55%;
    }
    hr{
        width: 55%;
    }
    .paymethod-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-top: 40px; 
        padding-bottom: 30px;
        color: #8276b4;
    }
    .billinfo-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-top: 30px;
        padding-bottom: 25px;
        color: #262626;
    }
    .paymentcard-inputfield{
        width: 100%;
        height: 45px;
        outline: unset !important;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 12px 20px 12px 20px;
        border-radius: 6px;
        border: solid 2px #e6e6e6;
        background-color: #fff;
    }
    .paymentsec-inputfield{
        width: 100%;
        height: 45px;
        outline: unset !important;
        margin-right: 10px;
        padding: 12px 20px 12px 20px;
        border-radius: 6px;
        border: solid 2px #e6e6e6;
        background-color: #fff;
    }
    .paymentcvc-inputfield{
        width: 100%;
        height: 45px;
        outline: unset !important;
        padding: 12px 20px 12px 20px;
        border-radius: 6px;
        border: solid 2px #e6e6e6;
        background-color: #fff;
    }
    .plancancel-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.56;
        text-align: left;
        padding-top: 26px;
        padding-bottom: 26px;
        color: #262626;
    }
    .assigncancel-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.56;
        text-align: left;
        padding-top: 26px;
        padding-bottom: 26px;
        color: #262626;
    }
    .cancelbillinfo-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        align-items: center;
        display: flex;
        padding-right: 44px;
        color: #8276b4;
        cursor: pointer;
    }
    .subinviteupload-section {
        position: fixed;
        overflow: scroll;
        top: 14%;
        left: 36%;
        border-radius: 10px;
        z-index: 1;
        padding: 22px 15px 0px 15px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
        background: #fff;
        width: 30%;
        margin: 0 auto;
        transition: left 0.3s ease-in-out;
    }
    .subcancelupload-section {
        position: fixed;
        // overflow: scroll;
        top: 28%; 
        left: 36%;
        border-radius: 10px;
        z-index: 1;
        padding: 22px 15px 0px 15px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
        background: #fff;
        width: 30%;
        margin: 0 auto;
        transition: left 0.3s ease-in-out;
    }
    .cancellastfile-section{
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .currentstatus-section{
        padding: 20px 20px;
        margin-top: 20px;
        border-radius: 12px;
        background-color: #f5f5f5;
    }
    .profilebill-content{ 
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        align-items: center;
        display: flex;
        padding-bottom: 30px;
        color: #262626;
    }
    .seatsbill-content{
        color: #024e84;
    }
    .addbillseat-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 13px;
        color: #707070;
    }
    .decrementbutton-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: center;
        width: 90px;
        height: 40px;
        margin-right: 14px;
        border-radius: 6px;
        border: unset;
        background-color: #fff;
        color: #262626;
    }
    .focusteamcount-content{
        width: 206px;
        height: 40px;
        padding: 4px 10px;
        text-align: center;
        margin-right: 14px;
        border-radius: 6px;
        border: unset; 
    }
    .incrementbutton-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: center;
        width: 90px;
        height: 40px;
        border-radius: 6px;
        border: unset;
        background-color: #fff;
        color: #262626;
    }
    .teammember-section ::placeholder{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        color: #262626;
    }
    .seatno-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        padding-top: 14px;
        color: #707070;
    }
    .totalcharges-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        padding-top: 30px;
        padding-bottom: 18px;
        color: #000;
    }
    .seatprice-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        width: 50%;
        color: #707070;
    }
    .seatsmon-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal; 
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #000;
    }
    .seatsec-content{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .pricebill-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 50%;
        color: #000;
    }
    .pricesection-content{
        padding-bottom: 50px;
    }
    .cancelbill-content{
        font-family: HelveticaNeue;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: center;
        align-items: center;
        display: flex;
        margin-left: 62px;
        color: #8276b4;
    }
    .planupdate-section{
        margin-top: 40px;
    }
    .saveplanbutton-context{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.65;
        text-align: center;
        padding: 6px 60px;
        color: #fdfdfd;
    }
    .cancelupdatecard-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        padding-top: 10px;
        margin-right: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        margin-left: 84px;
        color: #8276b4;
    }
    .visacarddot-content{
        width: 4px;
        height: 4px;
        margin-left: 52px;
        color: #5d5d5d;;
    }
}
.dark .billing-section .subhead-content{
    color: #ffffff;
}
.dark .billing-section .subdetail-content{
    color: #ffffff;
}
.dark .billing-section .autorenew-content{
    color: #ffffff;
}
.dark .billing-section .paybill-content{
    color: #ffffff;
}
.dark .billing-section .billinfo-content{
    color: #ffffff;
}
.dark .billing-section .paymentsec-inputfield{
    border: solid 1px #4c618f;
    background-color: unset;
    color: #fff;
}
.dark .billing-section .paymentcvc-inputfield{
    border: solid 1px #4c618f;
    background-color: unset;
    color: #fff;
}
.dark .billing-section .paymentcard-inputfield{
    border: solid 1px #4c618f;
    background-color: unset;
}
.dark .billing-section .visadetail-content{
    color: #fff;
}
.dark .billing-section .expire-content{
    color: #fff;
}
.dark .billing-section .defaultplan-content{
    color: #fff;
}
.dark .billing-section .subadd-content{
    color: #ffffff;
} 
.dark .billing-section .visacarddot-content{
    filter: invert(77%) sepia(2%) saturate(815%) hue-rotate(176deg) brightness(95%) contrast(98%);
}
.billingdesk-sec{
    display: block;
}
.billingmbl-sec{
    display: none;
}
.subdetail-section{
    display: block;
}
@media (min-width: 320px) and (max-width: 771px){
    .billingdesk-sec{
        display: none;
    }
    .billingmbl-sec{
        display: block;
    }
    .subhead-content{
        width: 45% !important;
    }
    .subdetail-content{
        width: 45% !important;
        padding-bottom: 30px !important;
    }
    .subdetail-section{
        display: none;
    }
    .addseat-content {
        font-size: 17px !important;
    }
    .paymentsec-inputfield {
        width: 170px !important;
        margin-right: 10px !important;
    }
    .paymentcvc-inputfield {
        width: 170px !important;
    }
    .plancard-content{
        align-items: center !important;
    }
    .expire-content{
        margin-left: unset !important;
    }
    .visadetail-content{
        width: 100% !important;
    }
    .plandismiss-content{
        margin-left: 5px !important;
    }
    .subinviteupload-section{
        width: 100% !important;
        left: unset !important;
    }
    .pricing-checkout{
        padding-left: 14px !important;
        padding-right: 14px !important;
    }
    .subcancelupload-section{
        width: 100% !important;
        left: unset !important;
    }
}
@media (min-width: 430px) and (max-width: 932px){
    .paymentsec-inputfield {
        width: 195px !important;
        margin-right: 10px !important;
    }
    .paymentcvc-inputfield {
        width: 195px !important;
    }
}