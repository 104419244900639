.projectpreview-wholesection{
    .myproject-content{
        font-family: 'HelveticaNeue';
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal; 
        text-align: left;
        color: #8276b4;
    }
    .projecttitle-textcontent{
        color: #262626;
    }
    .actionsHolder{
        padding-top: 18px;
    }
    .bucketHeader {
        margin-bottom: 16px;
    }
    .trash-icon{
        height: 18px;
    }
    .createddate-content{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #484848;
    }
    .projectsicon-imgcontent{
        margin-top: -6px;
    }
    .actionicon-content{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: left;
        color: #262626;
    }
    .project-textcontent{
        font-family: 'HelveticaNeue';
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        padding-top: 18px;
        padding-bottom: 16px;
        color: #262626;
    }
    .projectdesc-content{
        font-family: 'HelveticaNeue';
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: normal;
        text-align: left;
        padding-bottom: 18px;
        color: #141414;
    }
    .summerwhole-section{
        width: 80%;
    }
}
.dark .projectpreview-wholesection .projecttitle-textcontent{
    color: #c1c1c1;
}
.dark .projectpreview-wholesection .createddate-content{
    color: #ffffff;
}
.dark .projectpreview-wholesection .project-textcontent{
    color: #ffffff;
}
.dark .projectpreview-wholesection .projectdesc-content{
    color: #c7c7c7;
}
.dark .projectpreview-wholesection .actionicon-content{
    color: #bbb;
} 