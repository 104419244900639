.renewplan-section{
    width: 100%; 
    margin-left: 20px;
    padding: 20px; 
    border-radius: 12px;
    border: solid 1px #c1c1c1;
    .plandetail-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        line-height: normal; 
        text-align: left;
        padding-bottom: 30px;
        color: #8276b4;
    }
    .plan-content{
        color: #707070;
    }
    .confirmrenew-content{
        font-family: "HelveticaNeue";
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 30px;
        color: #262626;
    }
    .renewheading-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 38%;
        color: #262626;
    }
    .renewamount-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 62%;
        color: #262626;
    }
    .renewmonth-content{
        padding-bottom: 6px;
    }
    .billing-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 40%;
        padding-bottom: 25px;
        color: #707070;
    }
    .totalmembers-section{
        width: 32%;
    }
    .teamplan-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 6px;
        color: #262626;
    }
    .teamslot-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 6px;
        color: #707070;
    }
    .additional-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 20px;
        color: #707070;
    }
    hr{
        width: 40%;
    }
    .totalplan-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 38%;
        color: #000;
    }
    .subtotal-section{
        padding-top: 16px;
        padding-bottom: 25px;
    }
    .totalteam-section{
        padding-bottom: 30px;
    }
    .tax-section{
        padding-bottom: 20px;
    }
    .today-section{
        padding-top: 20px;
        padding-bottom: 10px;
    }
    .duemonth-content{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        text-align: left;
        width: 32%;
        color: #707070;
    }
    .monthdue-content{
        padding-bottom: 50px;
    }
    .visano-content{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.6px;
        text-align: center;
        text-decoration: underline;
        color: #808080;
    }
    .termsagree-content{
        font-family: "HelveticaNeue";
        font-size: 17px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 40px;
        color: #707070;
    }
    .condition-content{
        text-decoration: underline;
    }
    .saveupdatebutton-context{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.65;
        text-align: center;
        padding: 6px 80px;
        color: #fdfdfd;
    }
    .buttonplan-content{
        margin-bottom: 60px;
    }
    .cancelplancard-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        padding-top: 10px;
        margin-left: 82px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #8276b4;
    }
    .decrementbutton-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: center;
        width: 30px;
        height: 31px;
        border-radius: 6px 0px 0px 6px;
        border: solid 1px #c1c1c1;
        background-color: #fff;
        color: #262626;
    }
    .focusteamcount-content{
        width: 50px;
        height: 31px;
        padding: 4px 10px;
        text-align: center;
        border: solid 1px #c1c1c1; 
    }
    .incrementbutton-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: center;
        width: 30px;
        height: 31px;
        border-radius: 0px 6px 6px 0px;
        border: solid 1px #c1c1c1;
        background-color: #fff;
        color: #262626;
    }
    .teammember-section ::placeholder{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        color: #262626;
    }
}
.dark .renewplan-section .confirmrenew-content{
    color: #fff;
}
.dark .renewplan-section .totalplan-content{
    color: #fff;
}
.dark .renewplan-section .renewamount-content{
    color: #fff;
}
.dark .renewplan-section .renewheading-content{
    color: #fff;
}
.dark .renewplan-section .teamplan-content{
    color: #fff;
}
.dark .renewplan-section .teamslot-content{
    color: #c7c7c7;
}
.dark .renewplan-section .additional-content{
    color: #c7c7c7;
}
.dark .renewplan-section .billing-content{
    color: #c7c7c7;
}
.dark .renewplan-section .duemonth-content{
    color: #c7c7c7;
}
.dark .renewplan-section .visano-content{
    color: #bbb;
}
.dark .renewplan-section .termsagree-content{
    color: #c7c7c7;
}
.dark .renewplan-section .plan-content{
    color: #c7c7c7;
}
.dark .renewplan-section .decrementbutton-content{
    color: #fff;
    background-color: unset;
}
.dark .renewplan-section .focusteamcount-content{
    color: #ffffff;
    background-color: unset;
}
.dark .teammember-section ::placeholder{
    color: #fff;
}
.dark .renewplan-section .incrementbutton-content{
    color: #fff;
    background-color: unset;
}