.emailcampaign-section{
    .emailtop-section{
        height: 480px;
        padding-top: 40px;
        background-color: #000000;
    }
    .emaillogo-content{
        display: flex;
        margin: 0 auto;
        padding-bottom: 15px;
    }
    .buttonpadding-content {
        padding-top: 6px;
        padding-bottom: 6px;
        margin: 0 auto;
        display: flex;
    }
    .emailbuckets-context{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.65;
        letter-spacing: normal;
        text-align: center;
        padding: 6px 30px;
        color: #fdfdfd;
    }
    .emaildate-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: 22px;
        text-align: center;
        margin: 0 auto;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
    }
    .emailfocus-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        line-height: 22px;
        text-align: center;
        margin: 0 auto;
        padding-bottom: 40px;
        text-decoration: underline;
        color: #fff;
    }
    .emailtagswhole-section{
        display: flex;
        margin: 0 auto;
        padding-bottom: 40px;
    }
    .emailtags-section{
        display: flex;
        margin: 0 auto;
    }
    .cardHolders{
        width: 305px;
        border: 1px solid #655E5E;
        background-color: #fff;
    }  
    .tagsname-textcontent {
        font-family: "HelveticaNeue";
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: #262626;
        background-color: #eadbff;
    }   
    .emailtag-menu {
        font-family: HelveticaNeue;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: #262626;
        border-radius: 17px;
        padding: 6px 14px;
        height: 30px;
        margin-right: 6px;
    }
    .emailsocial-textcontent {
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: normal;
        text-align: left;
        color: #262626;
        vertical-align: middle;
    }
    .emailread-content{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        line-height: 22px;
        padding: 6px 12px 12px 12px
    }
    .emailweek-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        line-height: 22px;
        text-align: center;
        margin: 0 auto;
        padding-bottom: 30px;
        text-decoration: underline;
        color: #000000; 
    }
    .emailsubcribe-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 22px;
        text-align: center;
        margin: 0 auto;
        padding-bottom: 30px;
        color: #fff;
    }
    .emailpreview-content{
        font-family: "HelveticaNeue";
        font-size: 16px; 
        font-weight: normal;
        font-style: normal;
        line-height: 22px;
        text-align: center;
        margin: 0 auto;
        padding-bottom: 30px;
        color: #000000;
    }
    .graph-imgcontent{
        width: 660px;
        display: flex;
        margin: 0 auto;
        padding-bottom: 30px;
    }
    .emailfashion-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 22px;
        letter-spacing: normal;
        text-align: left;
        padding-left: 14px;
        padding-right: 14px;
        color: #141414;
    }
    .emailkindle-description{
        background-color: #A3F1FE;
    }
    .emailkindle-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        line-height: 22px; 
        text-align: center;
        margin: 0 auto;
        padding-top: 40px;
        padding-bottom: 30px;
        text-decoration: underline;
        color: #000000; 
    }
    .kindleemailwhole-section{
        display: flex;
        margin: 0 auto;
        padding-bottom: 40px;
    }
    .kindle-detail{
        display: flex;
        margin: 0 auto;
    }
    .emailfollow-section{
        background-color: #1A1A1A;
    }
    .emailfollow-content{
        font-family: "HelveticaNeue";
        font-size: 18px; 
        font-weight: normal;
        font-style: normal;
        line-height: 22px;
        text-align: center;
        margin: 0 auto;
        padding-top: 40px;
        padding-bottom: 20px;
        color: #fff;
    }
    .emailiconswhole-section{
        display: flex;
        margin: 0 auto;
        padding-bottom: 40px;
    }
    .firstkindleemail-card{
        margin-right: 20px;
    }
    .emailicons-section{
        display: flex;
        margin: 0 auto;
    }
    .footer-section{
        background-color: #000000;
    }
    .emailweb-content{
        font-family: "HelveticaNeue";
        font-size: 16px; 
        font-weight: normal;
        font-style: normal;
        line-height: 22px;
        text-align: center;
        margin: 0 auto;
        padding-top: 40px;
        padding-bottom: 5px;
        color: #fff;
    }
    .emaillocation-content{
        font-family: "HelveticaNeue";
        font-size: 16px; 
        font-weight: normal;
        font-style: normal;
        line-height: 22px;
        text-align: center;
        margin: 0 auto;
        padding-bottom: 20px;
        color: #fff;
    }
    .emailchange-content{
        font-family: "HelveticaNeue";
        font-size: 16px; 
        font-weight: normal;
        font-style: normal;
        line-height: 22px;
        text-align: center;
        margin: 0 auto;
        padding-bottom: 40px;
        color: #727272;
    }
    .kindle-detail{
        h5{
            font-family: 'HelveticaNeue';
               font-size: 22px;
               font-weight: bold;
               font-style: normal;
               line-height: 30px;
               text-align: left;
               color: #262626;
        
             }
             .shared-public{
                background-color: #ffe6d6;
             }
             .project-nav{
                color: #8276b4;
             }
          
             .last-updated{
                font-size: 14px;
                line-height: 1.22;
                text-align: left;
                color: #707070;
              }
             .kindle-card{
                padding: 20px 15px ;
                border-radius: 14px;
                border: solid 1px #655e5e;
                background-color: #fff; 
                width: 305px;
    
              h6{
                font-family: 'HelveticaNeue';
                font-size: 16px;
                font-weight: normal;
                line-height: 21px;
                text-align: left;
                color: #262626;
                // margin-bottom: 17px;
                margin-right: 40px;
              }
              .kindle-tick{
                border: 1px solid #707070;
                border-radius: 50px;
                padding: 5px;
                background: #fff;
            
              }
             
              .kindle-image{
                width: 350px;
                height: 450px;
              }
              .kindle-box {
                padding: 7px 10px;
                border-radius: 5px;
    
              }
              .bg-green{
                background-color: #0fd161;
              }
              .bg-afc604{
                background-color: #afc604;
              }
              span{
                background: #fcffa8;
                border-radius: 14px;
                padding: 5px 12px;
                display: inline-block;
                font-size: 14px;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: left;
      color: #262626;
    
              }
            }
            .kindlecard{
                border-radius: 14px;
                border: solid 1px #d6d6d6;
                background-color: #fff;
                padding: 15px;
                border-top: 14px solid #0fd161;
                margin-bottom: 30px;
           
              .menu-kindle{
                display: flex;
                justify-content: space-between;
                margin: auto 0;
                .actionicon-content {
                    color: #8276b4;
                 }
    
            }
            .publicytext-content{
             font-size: 14px;
           }
            .kindle-content{
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 21px;
                letter-spacing: normal;
                text-align: left;
                color: #141414;
                margin-top: 20px;
                padding-right: 50px;
            }
           
             }
    }
}
.dark .cardHolders{
    border: solid 1px #4C618F;
} 
.dark{
    .kindle-detail{
        .kindle-card{
            background: #051534;
            border: solid 1px #202F4F;
        }
        .menu-icon
        {
         filter:invert(77%) sepia(2%) saturate(815%) hue-rotate(176deg) brightness(95%) contrast(98%);
        }
        h5{
               color: #fff;
        
             }
             h6{
                color: #c7c7c7;
            }
             .project-nav{
                color: #8276b4;
             }
             hr{
                border: 1px solid #202F4F;
             }
             .kindle-content
             {
                color: #c7c7c7;
             }
             .last-updated{
                color: #c7c7c7;
              }
             .kindlecard{
                background: #051534;
                border: solid 1px #202F4F;
                border-top: 14px solid #0fd161;
               
             .menu-kindle{
                .actionicon-content {
                    color: #8276b4;
                 }
             }
             }       
        }
        .kindlecard:nth-child(2){
            border-top: 14px solid #afc604;
        }
    }