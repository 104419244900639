.dashboard-wholesection {
  // height: 100;
  margin-left: unset;
  margin-right: unset;
  .bucketHeader {
    margin-bottom: 10px;
  }
  .teambuckets-content {
    margin-top: 0;
  }
  .dashboard-section {
    padding-top: 25px;
  }
  .imgteam-content {
    width: 600px;
    margin-top: 15px;
  }
  .recenttext-content {
    font-family: "Objectivity-Medium";
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -1.2px;
    text-align: left;
    padding-bottom: 15px;
    color: #262626;
    // margin-top: 61px;
  }
  .recentactivetext-content{
    font-family: "Objectivity-Medium";
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -1.2px;
    text-align: left;
    padding-bottom: 15px;
    color: #262626;
    margin-top: 61px;
  }
  .firstwhole-section {
    margin-right: 35px;
  }
  .profilecard-imgcontent {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 100px;    
  }
  .dashboard-chart {
    margin-top: 40px;
    justify-content: center;
  }
  .displaywhole-content {
    padding-top: 15px;
    display: flex;
  }
  .display-content {
    display: flex;
  }
  //   .legend-section {
  //     padding-top: 6%;
  //   }
  .sharedtext-content {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    text-align: left;
    padding-left: 10px;
    color: #3e4a64;
  }
  .datetext-content {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: normal;
    font-style: normal; 
    line-height: 28px;
    letter-spacing: normal;
    text-align: center;
    margin-left: auto;
    padding-bottom: 10px;
    // padding-top: 12px;
    color: #878f9f;
  }
  .dashbaordicon-imgcontent{
    width: 28px;
    height: 28px;
    padding-bottom: unset;
    // margin-left: 50px;
    margin: 0 auto;
    margin-bottom: 12px;
    object-fit: cover;
    border-radius: 100px;
  }
  .datetextfirst-content{
    font-family: "HelveticaNeue";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
    text-align: center;
    margin-left: auto;
    padding-bottom: 10px;
    padding-top: 12px;
    color: #878f9f;
  }
  .teamspace-content {
    padding-right: 5%;
  }
  .bucketbold-content {
    font-weight: bold;
  }
  .recentactivity-section {
    // padding-bottom: 15px;
  }
  .dashboardprofilerecent-imgcontent{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100px;
    // border: 1px solid red;
  }
  .displaytext-content {
    display: flex;
  }
  .activetext-content {
    display: flex;
    padding-left: 10px;
  }
  .activename-content {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: center;
    padding-bottom: 3px;
    color: #3e4a64;
  }
  .secondteam-content {
    padding-top: 30px;
    padding-bottom: unset;
  }
  .activeteam-content {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    text-align: left;
    color: #3e4a64;
  }
  .addedtext-content {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 14px;
    color: #3e4a64;
  }
  .spacing-content {
    display: flex;
    padding-bottom: 50px;
  }
  .secondteam-content {
    margin-left: auto;
  }
  .cardWhole-background {
    // width: 198px;
    width: 240px;
    height: unset;
    padding: 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 14px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #e5e5e5;
    background-color: #fff;
  }
  .content-section {
    padding-left: 10px;
  }
  .dashbucket-content {
    display: flex;
  }
  .bucketsnamedesktop-content {
    font-family: "Objectivity-Medium";
    // font-size: 12px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: -0.8px;
    text-align: left;
    display: block;
    color: rgba(38, 38, 38, 0.5);
  }
  .bucketsnamembl-content {
    display: none;
  }
  .bucketnumber-content {
    font-family: "Objectivity-Medium";
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: -1.5px;
    text-align: left;
    padding-top: 8px;
    color: #14172e;
  }
  .rowdashboard-content {
    flex-wrap: wrap;
    padding-bottom: 30px;
  }
  .cardReview-content {
    font-family: "Objectivity-Medium";
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -1.2px;
    text-align: left;
    width: 60%;
    color: #262626;
  }
  .cardReviews-content {
    font-family: "Objectivity-Medium";
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -1.2px;
    text-align: left;
    color: #262626;
  }
  .reviewed-content {
    font-family: "Objectivity";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: -1.1px;
    text-align: left;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #262626;
  }
  .tabssection-ontent {
    padding-top: 30px;
    padding-bottom: 45px;
  }
  // .w-[40%] {
  //     width: 40%;
  // }
  hr {
    border: 1px solid #c1c1c1;
  }
  .cloudsection-content {
    width: 40%;
    margin-right: 40px;
  }
  .lastdashboard-content {
    padding-top: 35px;
  }
  .fashion-content {
    font-family: "HelveticaNeue";
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    padding-left: 20px;
    padding-right: 40px;
    color: #59a2b2;
  }
  .account-content {
    font-family: "HelveticaNeue";
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    line-height: 21px;
    letter-spacing: normal;
    text-align: left;
    color: #6b66ce;
  }
  .secondcloud-content {
    padding-top: 6px;
    padding-bottom: 4px;
  }
  .creativity-content {
    font-family: "HelveticaNeue";
    font-size: 50px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    padding-left: 60px;
    padding-right: 30px;
    color: #ff844b;
  }
  .media-content {
    font-family: "HelveticaNeue";
    font-size: 26px;
    font-weight: normal;
    font-style: normal;
    line-height: 36px;
    letter-spacing: normal;
    text-align: left;
    align-items: center;
    display: flex;
    color: #6b66ce;
  }
  .interior-content {
    font-family: "HelveticaNeue";
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    padding-left: 24px;
    padding-right: 18px;
    color: #ff606b;
  }
  .sciencedashboard-content {
    font-family: "HelveticaNeue";
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    padding-right: 20px;
    color: #59a2b2;
  }
  .finance-content {
    font-family: "HelveticaNeue";
    font-size: 32px;
    font-weight: normal;
    font-style: normal;
    line-height: 26px;
    letter-spacing: normal;
    text-align: left;
    color: #ff606b;
  }
  .banking-content {
    font-family: "HelveticaNeue";
    font-size: 38px;
    font-weight: normal;
    font-style: normal;
    line-height: 26px;
    letter-spacing: normal;
    text-align: left;
    padding-right: 24px;
    color: #6b66ce;
  }
  .movies-content {
    font-family: "HelveticaNeue";
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: normal;
    text-align: left;
    color: #59a2b2;
  }
  .retail-content {
    font-family: "HelveticaNeue";
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    padding-left: 30px;
    padding-right: 26px;
    color: #ff844b;
  }
  .cloudtagspace-content{
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .recenttabs-content{
    width: 75%;
  }
  .donughnut-section {
    width: 34%;
    padding-right: 20px;
  }
  .bggradient-section {
    margin-right: 10px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ff844b;
  }
  .teamgradient-section {
    margin-right: 10px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ff606b;
  }
  .privategradient-section {
    margin-right: 10px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #6b66ce;
  }
  .teambuckgradient-section {
    margin-right: 10px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #3cc2e0;
  }
  .legend-content {
    width: 16px;
    height: 16px;
  }
  .firstdonut-section {
    width: 50%;
  }
  .seconddonut-section {
    width: 50%;
  }
  .breaktext-content {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #3e4a64;
  }
  .publicbuckets-content {
    width: 50%;
  }
  .percentage-content {
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    padding-top: 10px;
    color: #000;
  }
  .teambuckets-content {
    padding-left: 20px;
  }
  .seconddashboard-section {
    padding-top: 30px;
  }
  .cloudspace-content {
    margin-bottom: 26px;
  }
  .teammembers-content {
    padding-top: 20px;
  }
  .dashboardprofile-imgcontent {
    // width: 64px;
    // height: 64px;
    // padding-bottom: 8px; 
    // margin: auto;
  }
}
.react-tabs__tab {
  color: #fff;
}
// .react-tabs__tab--selected {
//     background: unset;
//     border-bottom: solid 4px #ff6600;
// }
// .react-tabs__tab--selected {
//     background: unset;
//     border-bottom: solid 4px #ff6600;
// }
.react-tabs__tab-list {
  border-bottom: 1px solid #c1c1c1;
}
// .dark .react-tabs__tab-list {
//   border-bottom: 1px solid #c1c1c1;
// }
.react-tabs__tab-list {
  margin-bottom: 35px !important;
}
.react-tabs__tab {
  border: none;
  bottom: unset;
  font-family: "Objectivity-Medium";
  font-size: 16px; 
  font-weight: 500;
  text-align: left;
  color: #262626;
  // margin-right: 10px;
  padding: 6px 9px;
}
.tabssectiondesktop-ontent .react-tabs__tab{
  margin-right: 36px;
}
.react-tabs__tab--selected {
  background-color: transparent;
  border-bottom: 4px solid #ff6600;
  border-color: none !important;
}
.dark .dashboard-wholesection .cardWhole-background {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #09255d;
  background-color: #0b2c6f;
}
.dark .dashboard-wholesection .bucketsnamedesktop-content {
  color: rgba(255, 255, 255, 0.7);
}
.dark .dashboard-wholesection .bucketnumber-content {
  color: #fff;
}
.dark .dashboard-wholesection .cardReviews-content {
  color: #fff;
}
.dark .dashboard-wholesection .reviewed-content {
  color: #fff;
}

.dark .dashboard-wholesection .cardReview-content {
  color: #fff; 
}
.dark .dashboard-wholesection .breaktext-content {
  color: #c7c7c7;
}
.dark .dashboard-wholesection .percentage-content {
  color: #fff;
}
.dark .dashboard-wholesection .recenttext-content {
  color: #fff;
}
.dark .dashboard-wholesection .recentactivetext-content{
  color: #fff;
}
.dark .dashboard-wholesection .sharedtext-content {
  color: #c7c7c7;
}
.dark .dashboard-wholesection .datetext-content {
  color: #c7c7c7;
}
.dark .dashboard-wholesection .activename-content {
  color: #d3d3d3;
}
.dark .dashboard-wholesection .activeteam-content {
  color: #c7c7c7;
}
.dark .dashboard-wholesection .addedtext-content {
  color: #c7c7c7;
}
.dark .dashboard-wholesection .teammembers-content{
  color: #fff;
}
.dark .graph-imgcontent {
  filter: invert(77%) sepia(2%) saturate(815%) hue-rotate(176deg)
    brightness(95%) contrast(98%);
}
.dark .dashboard-wholesection .react-tabs__tab {
  color: #c7c7c7;
}
// .dark .dashboard-wholesection .react-tabs__tab-list{
//     border-bottom: 1px solid #202F4F;
// }
// .dark .dashboard-wholesection .react-tabs__tab .react-tabs__tab--selected{
//     border-bottom: 4px solid #ff6600;
// }
.dashboard-section {
  display: flex;
}
.tabssectiondesktop-ontent {
  display: block;
}
.tabssectionmbl-ontent {
  display: none;
}
.displaylegend-content {
  display: flex;
}
.dark .dashboard-wholesection .react-tabs__tab-list .react-tabs__tab--selected {
  background: unset !important;
  border-bottom: solid 4px #ff6600 !important;
  color: #fff !important;
  border-left: 0;
}
.dark .dashboard-wholesection .react-tabs__tab-list {
  border-bottom: 2px solid #c1c1c1;
}
@media (min-width: 320px) and (max-width: 771px) {
  .react-tabs__tab {
    color: #fff;
  }
  .react-tabs__tab-list {
    border-bottom: 1px solid #c1c1c1;
  }
  .react-tabs__tab-list {
    margin-bottom: 35px !important;
  }
  .legend-section{
    margin-left: 5%;
  }
  .react-tabs__tab {
    border: none;
    bottom: unset;
    font-family: "Objectivity-Medium";
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    color: #262626;
    margin-right: unset;
    padding: 6px 6px;
  }
  .react-tabs__tab--selected {
    background-color: transparent;
    border-bottom: 4px solid #ff6600;
    border-color: none !important;
  }
  .dashboard-wholesection {
    margin-left: 15px;
    margin-right: 15px;
    .dashboard-section {
      display: inherit;
    }
    .cardWhole-background {
      width: 162px;
      height: 70px;
      padding: 10px;
    }
    .cardWhole-background-kindle{
      width: 175px;
    }
    .bucketsnamedesktop-content {
      display: none;
    }
    .bucketsnamembl-content {
      font-family: "Objectivity-Medium";
      // font-size: 12px;
      font-size: 11px;
      font-weight: normal;
      font-style: normal;
      line-height: 22px;
      letter-spacing: -0.8px;
      text-align: left;
      display: block;
      color: rgba(38, 38, 38, 0.5);
    }
    .bucketnumber-content {
      font-size: 16px;
    }
    .firstwhole-section {
      margin-right: unset;
    }
    .w-2\/3 {
      width: unset;
    }
    .tabssectiondesktop-ontent {
      display: none;
    }
    .tabssectionmbl-ontent {
      display: block;
      padding-top: 20px;
    }
    .offers-blur #next-arrow {
      display: none !important;
    }
    .offers-blur #previous-arrow {
      display: none !important;
    }
    .slick-slider {
      width: 344px;
    }
    .BucketListHolder-content {
      padding-left: unset;
      padding-right: unset;
    }
    .bucketSingle {
      width: 167px;
    }
    .bucketDashboard {
      padding-bottom: 40px;
    }
    .spacing-content {
      display: inherit;
      padding-bottom: 20px;
    }
    .cloudsection-content {
      width: unset;
      margin-right: unset;
      padding-bottom: 20px;
    }
    .cloudspace-content {
      margin-bottom: 10px;
    }
    .lastdashboard-content {
      padding-top: 15px;
    }
    .cardReview-content {
      width: unset;
    }
    .displaylegend-content {
      display: inherit;
    }
    .donughnut-section {
      display: flex;
      align-items: center;
      width: 60%;
      padding-right: 10px;
    }
    .teambuckets-content {
      padding-left: unset;
      padding-top: 10px;
    }
    .seconddashboard-section {
      padding-top: 10px;
    }
    .breaktext-content {
      font-size: 14px;
    }
    .w-1\/3 {
      width: unset;
    }
    .ml-\[10px\] {
      margin-left: unset;
    }
    .sharedtext-content {
      font-size: 14px;
    }
    .secondteam-content {
      padding-bottom: 20px;
    }
  }
}
@media (min-width: 430px) and (max-width: 932px){
  .dashboard-wholesection .cardWhole-background {
    width: 190px !important;
  }
}
