$base-color: #262626;
$color-white: #ffffff;
$bf-color: #f8f8f8;
$color-grey: #707070;
$border-orange: #FF6600;
$color-scroll: #c6c6c6;

.ProjectList{
  .card-detailimg{
    width: -webkit-fill-available;
    // max-height: 500px ;
  }
  .react-tabs__tab:focus{
    outline: none;
  }
  .profile-imgcontent img{
    width: 30px;
    height: 30px;
  }
  .share-content{
    font-size: 12px;
  }
  .edit-menu{
    flex-wrap: nowrap;
    margin: auto;
  }
 .inline-align{
  display: inline-flex;
 }
.search-tab{
input{
    background-color: #fff;
    padding-left: 12px;
    margin: 23px 12px 19px;
    width: 94%;
    
}

.search-magnifier{
  right: 18px;
}
input::placeholder{
    color:$color-grey;
}
}

.react-tabs {
    display: flex;
    color: $base-color;
    background: $color-white;
  }
  .tab-menu{
    margin-left: auto;
  }

  .react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    width: 450px;
    margin: 0;
    padding: 0;
    color: white;
    background: $bf-color;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #f8f8f8;
  }
  .tab-scroll{
    height: 60%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .tab-scroll::-webkit-scrollbar {
    width: 10px;
    height: 100px;
  }
  .tab-scroll::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .tab-scroll::-webkit-scrollbar-thumb {
    background-color: $color-scroll;
    border-radius: 8px;
  }
  .react-tabs__tab {
    list-style: none;
    padding: 12px 6px;
    cursor: pointer;
    color: $base-color;
  }
  .react-tabs__tab{
   p{
    font-family: 'HelveticaNeue';
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
    color: $base-color;
  }
}
  .react-tabs__tab:focus{
    border: none;
  }
.edit-cardname{
  margin-left: 30px;
}
.public-share{
  font-size: 14px;
}
.card-content{
  font-family: HelveticaNeue;
  font-size: 16px;
  line-height: 1.27;
  text-align: left;
  color: #141414;
  text-align: left;
  margin-bottom: 16px;
}
.menu-cardshare{
  align-items: center;
}
 .cardmenu-icons{
    margin-left: auto;
  }
.mb--16{
    margin-bottom: 16px;
  }
  .last-updated p{
    text-align: left;
 font-family: 'HelveticaNeue';
    font-size: 14px;
    line-height: 1.25;
    color: #484848;
    font-weight: normal;
  }
  .react-tabs__tab-panel {
    display: none;
    width: 70%;
    margin-left: 28px;
    margin-top: 20px;
  }
  .tag-menu{
 font-family: 'HelveticaNeue';
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
    border-radius: 17px;
    padding: 6px 14px;
    margin-right: 6px;
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
  }
  
  .react-tabs__tab {
    padding-left: 20px;
    display: flex;
    border-left: 6px solid $bf-color;
    border-bottom: solid 1px #dedede;

  }
  
  .react-tabs__tab--selected {
    padding-left: 21px;
  }
  .projectedit-menu{
 font-family: 'HelveticaNeue';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
  }
  .panel-content {
    text-align: center;

   h5{
 font-family: 'HelveticaNeue';
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    line-height: 30px;
    text-align: left;
    color: $base-color;
    
  }
  hr {
    border: 1px solid #c1c1c1; 
  }
  .edit-menu span{
    font-family: 'HelveticaNeue';
    font-size: 14px;
    padding-left: 5px;
    letter-spacing: normal;
    text-align: left;
    color: #191919;
  }
  
 .project-nav{
color: #8276b4;
    
  }
}
  .checkbox-wrapper-24 *,
.checkbox-wrapper-24 *:after,
.checkbox-wrapper-24 *:before {
  box-sizing: border-box;
}

.checkbox-wrapper-24{
  display: flex;
  margin-right: 15px;
  margin-top: 4px;
}
.checkbox-wrapper-24 span{
  font-family: 'HelveticaNeue';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 23px;
  text-align: left;
  margin-left: 11px;
  color: #000;
}
.checkbox-wrapper-24 input {
  position: absolute;
  opacity: 0;
}
  
.checkbox-wrapper-24 input:checked + label svg path {
  stroke-dashoffset: 0;
}
  
.checkbox-wrapper-24 input:focus + label {
  transform: scale(1.03);
}

.checkbox-wrapper-24 input + label {
  display: block;
  border: 2px solid $color-grey;
  width: 18px;
  height: 18px;
  border-radius: 6px;
  cursor: pointer;
  transition: all .2s ease;
  padding: 2px;
}
  
.checkbox-wrapper-24 input + label:active {
  transform: scale(1.05);
  border-radius: 12px;
}
  
.checkbox-wrapper-24 input + label svg {
  pointer-events: none;
  padding: 5%;
}
 #search:placeholder{
  font-size: 16px;
}

.checkbox-wrapper-24 input + label svg path {      
  fill: none;     
  stroke: #333;
  stroke-width: 4px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 100;
  stroke-dashoffset: 101;
  transition: all 250ms cubic-bezier(1,0,.37,.91);
}
  .react-tabs__tab-list {
    .react-tabs__tab--selected {
        background: $color-white;
        border-left: 6px solid $border-orange;
        color: $base-color;
      }
  }
}

.dark {
  .ProjectList{
  hr{
    border: 1px solid #202F4F;
  }
  .tab-menu{
    filter: invert(72%) sepia(5%) saturate(531%) hue-rotate(176deg) brightness(88%) contrast(93%);
  }
   .edit-menu span{
    color: #8276b4;
  }
  .search-tab{
  input::placeholder{
    color:#acacac;
}
  }
.react-tabs__tab-list
  {
    background-color: #112750;
  }
  .tab-scroll::-webkit-scrollbar-thumb {
    background-color:#c6c6c6 ;
  }

  .react-tabs{
    background-color: #051534;
  }
 .checkbox-wrapper-24 input + label {
    border: 2px solid #9aa1ab;
}
.checkbox-wrapper-24 input + label svg path {      
  fill: none;     
  stroke: #acacac;
}
.darkicon-bc
{
 filter: invert(85%) sepia(5%) saturate(328%) hue-rotate(176deg) brightness(86%) contrast(95%);

} 
  .react-tabs__tab {
    border-left: 6px solid #112750;
    border-bottom: 1px solid #3b4964;

  }
  .card-content{
    color: #c7c7c7;

  }
  .panel-content h5{
    color: $color-white;
    
  }
  .last-updated{
    color: #bbbbbb;
  }
  .search-tab{
    input{
        background-color: #2F436A;
        
    }
  }
  .react-tabs__tab-list {
    .react-tabs__tab--selected {
        background: #2F436A;
        border-left: 6px solid $border-orange;
        color: #ffffff;
      }
  }
  .react-tabs__tab{
    p{
     color: $color-white;
   }
 
 }
}
}
.dark{
.ProjectList{
  .last-updated p{
    color: #bbbbbb;
  }
  .panel-content {
  .edit-menu span{
    color: #8276b4;
  }
  hr {
    border: 1px solid #202F4F; 
  }
}
.dark-color{
  filter: invert(68%) sepia(16%) saturate(193%) hue-rotate(178deg) brightness(92%) contrast(83%);
}
}
}