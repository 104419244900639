.aiscreen {
  width: 90%;
  margin: 0 auto;
  h2 {
    font-family: "Objectivity-Medium"; 
    font-size: 120px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #262626;
  }
  .ai-icon {
    display: flex;
    justify-content: center;
    background-image: url("../../assets/images/Background.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  li {
    margin-bottom: 10px;
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: normal;
    text-align: left;
    color: #707070;
  }
  p {
    font-family: "HelveticaNeue";
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    line-height: normal;
    color: #262626;
  }
  ul li::before {
    content: "\2022";
    color: orangered;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  h5 {
    font-family: "HelveticaNeue";
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    text-align: center; 
    color: #262626;
  }
  h6 {
    font-family: "HelveticaNeue";
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    line-height: 27px;
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
    color: #262626;
  }
  .creatingproj-content{
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.33;
    text-align: left;
    color: #262626;
  }
  .aiImg-section{
    width: 50%;
  }
  .aidesc-content{
    width: 77%;
  }
  ul{
    padding-left: unset;
  }
}
.dark {
  .aiscreen {
    h2,h5,h6,p {
      color: #fff;
    }
    li {
      color: #c7c7c7;
    }
  }
}
.dark .aiscreen .creatingproj-content{
  color: #ffffff;
}
.aiscreendesktop-section{
  display: block;
}
.aiscreenmbl-section{
  display: none;
}


@media (min-width: 320px) and (max-width: 771px){
  .aiscreendesktop-section{
    display: none;
  }
  .aiscreenmbl-section{
    display: block;
  }
  .aiscreen h6 {
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    line-height: 27px; 
    text-align: center;
    padding-top: 30px;
    padding-left: unset; 
    padding-right: unset; 
    color: #262626;
  }
  .aiImg-section{
    width: unset;
    margin: 0 auto;
    display: flex;
    padding-bottom: 30px;
  }
  .creatingproj-content{
    text-align: center !important;
  }
  .aidesc-content{
    width: unset !important;
  }
  ul{
    padding-left: 10px !important;
  }
}