.sharedwithme-content{
    font-family: "HelveticaNeue";
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    text-align: left;
    padding-bottom: 34px;
    color: #262626;
}
.bucketDashboardsection{
    padding-bottom: 50px;
}
.mybucketswhole-content .CompactView-wholesection .profile-imgcontent img:nth-child(2) {
    margin-left: unset !important;
} 
.dark .sharedwithme-content{
    color: #fff;
}
@media (min-width: 320px) and (max-width: 771px) {
    .sharedwithme-content{
     margin-left: 10px;
    }
 }