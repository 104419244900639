.CompactView-wholesection {
  .BucketListHolder-content {
    // width: 300px;
    // height: auto;
    // display: flex;
    // display: grid;
    // flex-wrap: wrap;
    //  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    //  grid-gap: 34px;

    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    grid-row-gap: 35px;
  }
  .bucketSingle {
    width: 250px;
    height: 190px;
  }
  .bucketHeader {
    margin-bottom: 34px;
  }
  .filterHolder {
    margin-top: -52px;
  }
  .buttonpadding-content {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .mybuckets-content {
    font-family: "Objectivity-Medium";
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    line-height: 42px;
    letter-spacing: -1.4px;
    text-align: left;
    color: #262626;
  }
  .dark .mybuckets-content {
    font-family: "Objectivity-Medium";
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    line-height: 42px;
    letter-spacing: -1.4px;
    text-align: left;
    color: #fff;
  }
  .createbutton-context {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: normal;
    color: #fdfdfd;
  }
  .totalbucket-content {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    margin-top: 15px;
    display: flex;
    color: #707070;
  }
  .bucket-imgcontent {
    margin-top: -3px;
    margin-right: 10px;
  }
  .text-background {
    font-family: "HelveticaNeue";
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    padding: 4px 10px 4px 10px;
    text-align: left;
    display: inline;
    border-radius: 20px;
    color: #fff;
    background-color: #000;
    display: -webkit-inline-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .cardlist-content {
    padding: 10px;
  }
  .share-content {
    font-family: "HelveticaNeue";
    font-size: 10px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    // padding-top: 4px;
    color: #000;
    margin-top: 3px;
  }
  .shareright-content {
    font-family: "HelveticaNeue";
    font-size: 10px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: right;
    margin-top: -6px;
    color: #000;
  }
  .sorttext-content {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: normal;
    text-align: left;
    margin-right: 11px;
    color: #707070;
  }
  .createdtext-content {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: normal;
    text-align: left;
    padding-right: 20px;
    color: #262626;
  }
  .bucketsinside {
    margin-top: 7px;
  }
  // .gradient-wholebackground{
  //     height: 130px;
  //     background: #74CFFB;
  // }
  .profile-imgcontent {
    display: flex;
  }
  .profile-imgcontent img {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    border: 1px solid #ffffff;
  }
  // .profile-imgcontent img:nth-child(2) {
  //   margin-left: -9px;
  // }
  // .profile-imgcontent img:nth-child(3) {
  //   margin-left: -12px;
  // }
  .display-content {
    display: flex;
  }
  .profile-displaycontent {
    margin-top: 10px;
    margin-left: auto;
  }
  .tagicon-imgcontent {
    margin-top: 3px;
  }

  .subBuckets-whole {
    .subBucketsList .BucketListHolder-content .bucketSingle {
      width: 200px;
      height: 190px;
      z-index: 10;
      position: relative;
      background: #e8e8e8;
    }
    .display-content {
      display: flex;
    }
    .filterHolder {
      margin-top: unset;
    }
    .subbucketsbg-content {
      padding: 1px 12px;
    }
    .BucketListHolder-content .bucketSingle button {
      background: transparent;
    }
    .subBucketsList {
      margin-top: 26px;
    }
    .subBucketsHolder {
      padding-top: 20px;
    }
    .subBucket-content {
      font-family: "HelveticaNeue";
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #8276b4;
    }
    .uxtext-content {
      color: #707070;
    }
    .bucketName-content {
      font-family: "Objectivity";
      font-size: 22px;
      font-weight: bold;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: -1.4px;
      text-align: left;
      margin-bottom: 12px;
      margin-top: 18px;
      color: #262626;
      font-family: "Objectivity";
    }
    .bucketActions {
      padding-left: 30px;
    }
    .trashicon-content {
      padding-left: 30px;
    }
    .subbucketstext-content {
      font-family: "HelveticaNeue";
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: #262626;
    }
    .cardssubBuckets-content {
      font-family: "HelveticaNeue";
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: #262626;
    }
  }
  .social-textcontent {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
  }
  .cardContent {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
  }
  .updatedtext-content {
    font-family: "HelveticaNeue";
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #484848;
  }
}
// #grid {
//     display: grid;
//     grid-template-columns: repeat(auto-fill, minmax(365px, 1fr));
//     grid-gap: 34px;
//   }
//   #grid > .card-wholecontent {
//     width: 365px;
//     height: 300px;
// padding: 13px 17px;
// border-radius: 10px;
// margin-left: 34px;
// border: solid 1px #e5e5e5;
// background-color: #fff;
// box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.16);
// }

.dark {
  .CompactView-wholesection {
    .regular-view {
      filter: invert(100%) sepia(5%) saturate(72%) hue-rotate(59deg)
        brightness(116%) contrast(78%);
    }
    .compact-view {
      filter: invert(86%) sepia(0%) saturate(5833%) hue-rotate(88deg)
        brightness(103%) contrast(99%);
    }
  }
}
.regular .emoji-icon {
  width: 30px;
}
.emoji-icon {
  width: 50px;
  // margin-top: -3px;
}
.regular {
  .dark-icon-clr {
    width: 16px;
    height: 16px;
  }
  .emojis-icon {
    width: 30px;
  }
  .gradient-wholebackground {
    height: 75px;
  }
  .bucketsinside {
    margin-top: 0;
  }
  .bucketsinside svg {
    width: 16px;
    height: 16px;
  }
  // .card-textcontent{
  //     font-size: 10px;
  //     width: 15px;
  //     height: 15px;
  //     bottom: 9px;
  //     right: 6px;

  // }
  .cardtext-buttoncontent {
    font-size: 10px;
    line-height: 1rem;
  }
  .cardlist-content {
    padding: 8px;
  }
  .profile-imgcontent img {
    width: 20px;
    height: 20px;
  }
}

.popup-upload {
  .cardlist-content {
    // width: 164px;
    // height: 75px !important;
    // background-size: 100% !important;
    // background-position: center !important;

    padding: 10px;
    border-radius: 8px;
    margin-top: 10px;
    // margin-bottom: 10px;
    // justify-content: center;
    // background-size: 100% !important;
    background-position: center !important;
    height: 100px !important;
    background-size: cover !important;
    background-position: center !important;
    border-radius: 16px;
  }
}
