.pricing-checkout {
  .pagination-checkout {
    margin-bottom: 60px;
    span {
      color: #8276b4;
    }
  }
  .billing-fields {
    input::placeholder {
      color: #000000;
    }
  }
  .card-inputfield {
    background-color: #f5f5f5;
    width: 100%;
    border-radius: 50px;
  }
  .dark-inactive-icon {
    display: none;
  }
  .fa-chevron-down {
    color: #707070;
    height: 12px;
  }
  input[name="payment"] {
    accent-color: orangered;
    width: 18px;
    height: 18px;
    margin-top: 4px;
  }
  .default-payment {
    font-family: HelveticaNeue;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #262626;
  }
  input:focus {
    outline: none;
  }
  input::placeholder {
    font-family: HelveticaNeue;
    font-size: 18px;
    text-align: left;
    color: #707070;
  }
  .input-icon {
    border: solid 1px #ffffff;
    background-color: #f5f5f5;
  }
  .active-icon {
    display: none;
  }
  .react-tabs__tab-panel input {
    padding-left: 14px;
  }
  .react-tabs__tab-list {
    border-bottom: 0;
  }
  .react-tabs__tab-list {
    display: flex;
  }
  .react-tabs__tab {
    width: 50%;
    border-radius: 12px;
    border: solid 1px #c1c1c1;
    text-align: center;
    display: inline-flex;
    padding: 14px;
  }
  .react-tabs__tab img {
    padding-right: 10px;
    margin-top: -4px;
  }

  .react-tabs__tab:nth-child(1) {
    margin-right: 10px;
  }
  .react-tabs__tab:nth-child(2) {
    margin-left: 10px;
    margin-right: 0;
  }
  .react-tabs__tab--selected {
    border-bottom: 1px solid #ff6600 !important;
    font-weight: normal !important;
    border: 1px solid #ff6600;
    color: #707070 !important;
  }
  .react-tabs__tab--selected .active-icon {
    display: block;
  }
  .react-tabs__tab--selected .inactive-icon {
    display: none;
  }
  p {
    font-family: HelveticaNeue;
    font-size: 20px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }

  .plan-billing {
    // padding-bottom: 50px;

    .plan-btn.active {
      border: 1px solid #ff6600;
      color: #ff6600;
    }
    h4 {
      font-family: Objectivity-Medium;
      font-size: 24px;
      font-weight: 500;
      line-height: normal;
      text-align: left;
      color: #262626;
      margin-bottom: 30px;
    }
    h6 {
      font-family: Objectivity-Medium;
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      text-align: left;
      color: #262626;
    }
    .plan-btn {
      border-radius: 20px;
      border: solid 1px #c1c1c1;
      width: 100%;
      padding: 7px 0;
      font-family: Objectivity-Medium;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: -0.8px;
      color: #000;
    }
    .plan-btn:hover {
      border: 1px solid #ff6600;
      color: #ff6600;
    }
  }
}

.dark {
  input::placeholder {
    color: #acacac;
  }
  .inactive-icon {
    display: none;
  }

  .inactive-icon {
    filter: invert(98%) sepia(2%) saturate(1852%) hue-rotate(338deg)
      brightness(106%) contrast(107%);
  }
  .billing-fields {
    input::placeholder {
      color: #acacac;
    }
  }
  .pricing-checkout {
    input[name="payment"] {
      filter: invert(100%) sepia(5%) saturate(100%) hue-rotate(190deg)
        brightness(100%) contrast(100%);
    }
    .dark-inactive-icon {
      display: block;
    }
    p {
      color: #ffffff;
    }

    .input-icon {
      border: solid 1px #4c618f;
    }
    .plan-billing {
      h4,
      h6 {
        color: #fff;
      }
      .plan-btn {
        color: #fff;
        border: solid 1px #4c618f;
      }
      .default-payment {
        color: #c7c7c7;
      }

      input {
        border: solid 1px #4c618f;
        background-color: #051534;
      }
      .input-icon {
        background-color: #051534;
        input {
          border: 0;
        }
      }
    }
  }
}
.dark .pricing-checkout .react-tabs__tab--selected .dark-inactive-icon {
  display: none;
}
.dark .pricing-checkout .react-tabs__tab--selected .inactive-icon {
  display: none;
}
.dark .pricing-checkout .react-tabs__tab {
  border: solid 1px #4c618f;
}
.dark .pricing-checkout .react-tabs__tab-list .react-tabs__tab--selected {
  border: 1px solid #4c618f !important;
}
.dark
  .pricing-checkout
  .react-tabs__tab-list
  .react-tabs__tab--selected
  .dark-inactive-icon {
  display: none;
}
