.visaplan-section {
  width: 100%;
  margin-left: 20px;
  padding: 20px;
  border-radius: 12px;
  border: solid 1px #c1c1c1;
  .plandetail-content {
    font-family: "HelveticaNeue";
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    line-height: normal;
    text-align: left;
    padding-bottom: 30px;
    color: #8276b4;
  }
  .plan-content {
    color: #707070;
  }
  .visapayment-content {
    font-family: "HelveticaNeue";
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1.1px;
    text-align: left;
    padding-bottom: 15px;
    color: #262626;
  }
  .selectpayment-content {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: left;
    padding-bottom: 25px;
    color: #707070;
  }
  .radiobutton-content {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  .visaplan-content {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.5;
    text-align: left;
    color: #262626;
  }
  .visaexpire-content {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    text-align: center;
    margin-left: 120px;
    color: #262626;
  }
  .visadetail-section {
    margin-bottom: 30px;
  }
  .addpayment-section {
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: left;
    padding-left: 10px;
    color: #262626;
  }
  .termsagree-content {
    font-family: "HelveticaNeue";
    font-size: 17px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    text-align: left;
    padding-top: 90px;
    padding-bottom: 40px;
    color: #707070;
  }
  .condition-content {
    text-decoration: underline;
  }
  .saveupdatebutton-context {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.65;
    text-align: center;
    padding: 6px 80px;
    color: #fdfdfd;
  }
  .buttonplan-content {
    margin-bottom: 60px;
  }
  .cancelplancard-content {
    font-family: "HelveticaNeue";
    font-size: 18px;
    padding-top: 10px;
    margin-left: 82px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: left;
    color: #8276b4;
  }
}
.dark .renewplan-section .termsagree-content {
  color: #c7c7c7;
}
.dark .visaplan-section .visapayment-content {
  color: #fff;
}
.dark .visaplan-section .selectpayment-content {
  color: #c7c7c7;
}
.dark .visaplan-section .visaplan-content {
  color: #fff;
}
.dark .visaplan-section .visaexpire-content {
  color: #fff;
}
.dark .visaplan-section .addpayment-section {
  color: #fff;
}
.dark .visaplan-section .plan-content {
  color: #c7c7c7;
}
.dark .visaplan-section .termsagree-content {
  color: #c1c1c1;
}
