.upgradeplan-section{
    width: 100%;
    margin-left: 20px;
    padding: 20px;
    border-radius: 12px;
    border: solid 1px #c1c1c1;
    .plandetail-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        line-height: normal; 
        text-align: left;
        padding-bottom: 30px;
        color: #8276b4;
    }
    .plan-content{
        color: #707070;
    }
    .upgradeplan-content{
        font-family: "HelveticaNeue";
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #262626;
    }
    h3 {
        font-family: "HelveticaNeue";
        font-size: 30px;
        font-weight: 500;
        line-height: normal;
        text-align: center;
        color: #262626;
        margin-bottom: 10px;
      }
      .react-tabs {
        margin-top: unset;
      }
      .sec-sectio {
        text-align: center; 
        display: inline-block;
        h6 {
          margin-left: 60px;
        }
      }
      .save-plan {
        width: 20%;
        text-align: center;
        margin-left: -35px;
        margin-top: 38px;
      }
    
      .saveplan-text {
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #ff6500;
      }
      .pt-12 {
        padding-top: 12px;
      }
      .react-tabs__tab {
        padding: 8px 60px;
      }
      .react-tabs__tab--selected {
        background-color: #ff6600 !important;
        border-bottom: 0 !important;
        border-radius: 50px;
        color: #ffffff !important;
        font-size: 16px;
        font-family: Objectivity;
        text-align: center;
        color: #fff;
      }
      .page-heading {
        margin-bottom: 25px;
      }
      .react-tabs__tab-list {
        border-bottom: 0;
        margin-bottom: 0px !important;
        width: fit-content;
        margin-top: 30px;
        box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.16);
        background-color: #fff;
        border-bottom: 0;
        padding: 4px;
        border-radius: 50px;
      }
      p {
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        text-align: center;
        color: #707070;
      }
}
.dark {
    .pricing-plans {
        h3 {
          color: #fff;
        }
        .sec-sectio {
          h6 {
            color: #ffffff;
          }
        }
        p {
          color: #c7c7c7;
        }
        .react-tabs__tab-list {
          background-color: #2f436a;
          .react-tabs__tab--selected {
            border-bottom: 0 !important;
            background-color: #ff6600 !important;
          }
        }
      }
      .plans {
        background-color: #051534;
        h4 {
          color: #ffffff;
        }
        h6 {
          color: #ffffff;
        }
        .plan-details {
          h5 {
            color: #ffffff;
          }
    
          .sub-heading {
            color: #ffffff;
          }
        }
      }
}
.dark .upgradeplan-section .upgradeplan-content{
    color: #fff;
}
.dark .upgradeplan-section .sec-sectio h6{
    color: #fff;
}
.dark  .upgradeplan-section .react-tabs__tab {
    color: #fff;
    padding: 8px 60px;
}
.dark .upgradeplan-section .react-tabs__tab-list{
    background-color: #2f436a;
}
.dark .upgradeplan-section .react-tabs__tab-list .react-tabs__tab--selected{
    border-bottom: 0 !important;
    background-color: #ff6600 !important;
}
