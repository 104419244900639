.monthlyplan-section{
    .plandetails-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 33px;
        color: #262626;
    }
    .monthlyplan-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 14px;
        color: #262626;
    }
    .amountmonth-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        align-items: center;
        display: flex;
        color: #262626;
    }
    .currentplan-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding: 6px 12px;
        margin-left: 20px;
        border-radius: 14px;
        background-color: rgba(193, 193, 193, 0.18);
        color: #262626;
    }
    .planrenew-content{
        font-family: "HelveticaNeue"; 
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-top: 17px;
        padding-bottom: 28px;
        color: #707070;
    }
    .profileplan-section{
        margin-bottom: 50px;
    }
    .purchasedplan-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-left: 8px;
        display: flex;
        align-items: center;
        color: #707070;
    }
    .countplan-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold !important;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-left: 8px;
        display: flex;
        align-items: center;
        color: #000000;
    }
    .planteam-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: 25px;
        text-align: center;
        display: flex;
        padding-left: 13px;
        align-items: center;
        text-decoration: underline;
        color: #808080;
    }
    .saveplanbutton-context{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.65;
        text-align: center;
        padding: 6px 60px;
        color: #fdfdfd;
    }
    .planupdate-section{
        padding-bottom: 30px;
    }
    .paymentdetails-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-top: 30px;
        padding-bottom: 30px;
        color: #262626;
    }
    .visadetail-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.5;
        text-align: center;
        display: flex;
        align-items: center;
        color: #262626;
    }
    .defaultplan-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding: 6px 12px;
        margin-left: 20px;
        border-radius: 14px;
        background-color: rgba(193, 193, 193, 0.18); 
        color: #262626;
    }
    .expire-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.5;
        text-align: center;
        margin-left: 63px;
        display: flex;
        align-items: center;
        color: #262626;
    }
    .plancard-content{
        padding-bottom: 40px;
    }
    .addpayment-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 40px;
        color: #8276b4;
    }
    .billingdetails-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 30px;
        color: #262626;
    }
    .updateplan-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 100px;
        color: #8276b4;
    }
}
.dark .plandetails-section .plandetailsfirst-section{
    background-color: unset;
}
.dark .monthlyplan-section .react-tabs__tab-list .react-tabs__tab--selected {
    font-weight: bold !important;
    // background: #f3f4f6 !important;
    // border-bottom: unset !important;
    color: #fff !important;
    border-left: 0;
}
.dark .monthlyplan-section .plandetailsfirst-section .react-tabs__tab-list .react-tabs__tab--selected {
    font-weight: bold !important;
    background: #f3f4f6 !important;
    border-bottom: unset !important;
    color: #000000 !important;
    border-left: 0;
}
.dark .plandetails-section .react-tabs__tab{
    color: #ffffff;
}
.dark .monthlyplan-section .plandetails-content{
    color: #fff;
}
.dark .monthlyplan-section .monthlyplan-content{
    color: #fff;
}
.dark .monthlyplan-section .amountmonth-content{
    color: #fff;
}
.dark .monthlyplan-section .currentplan-content{
    color: #fff;
}
.dark .monthlyplan-section .planrenew-content{
    color: #c7c7c7;
}
.dark .monthlyplan-section .purchasedplan-content{
    color: #bbb;
}
.dark .monthlyplan-section .countplan-content{
    color: #fff;
}
.dark .monthlyplan-section .planteam-content{
    color: #bbb;
}
.dark .monthlyplan-section .paymentdetails-content{
    color: #fff;
}
.dark .monthlyplan-section .visadetail-content{
    color: #fff;
}
.dark .monthlyplan-section .defaultplan-content{
    color: #fff;
}
.dark .monthlyplan-section .expire-content{
    color: #fff;
}
.dark .monthlyplan-section .billingdetails-content{
    color: #fff;
}