.plandetails-section{
    overflow: hidden;
    .plandetailsfirst-section{
        // margin: 29.8px 12px 693px 34px;
        // padding: 10px 0;
        height: 116px;
        border-radius: 12px;
        border: solid 1px #c1c1c1;
        background-color: #fff;
    }
    .react-tabs__tab--selected {
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold !important;
        font-style: normal;
        line-height: normal;
        text-align: left;
        background: unset !important;
        width: 214px;
        height: 48px;
        padding-top: 14px;
        border-radius: 12px;
        margin: 7px;
        // background-color: #fff6f0 !important;
        background-color: #f3f4f6 !important;
        border-bottom: unset !important;
        color: #262626 !important;
    }
    .react-tabs__tab-list{
        border-bottom: unset !important;
        margin-bottom: unset !important;
    }
    .plandetailssecond-section{
        width: 100%;
        margin-left: 20px;
        padding: 20px;
        border-radius: 12px;
        border: solid 1px #c1c1c1;
    }
    .react-tabs__tab {
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        color: #262626;
        margin-right: 10px;
        // padding: 6px 9px;
        margin: 7px;
        // height: 48px;
        width: 214px;
        border: none;
        bottom: unset;
    }
}