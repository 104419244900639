.publicshare-section {
  padding: 15px;
  .publicspace-content {
    // padding-top: 20px;
  }
  .ql-editor {
    height: auto !important;
    padding: 0;
  }

  .xu06os2.x1ok221b:nth-child(1) {
    padding-bottom: 5px;
  }
  .x1lliihq.x6ikm8r.x10wlt62.x1n2onr6 > span {
    font-size: 15px !important;
  }
  .x1n2onr6 > .x1n2onr6.x1ja2u2z {
    padding-top: 5px !important;
  }
  .carddetail .x1lliihq.x6ikm8r.x10wlt62.x1n2onr6.xlyipyv.xuxw1ft {
    padding-top: 0 !important;
  }

  .headingtext-content,
  .landingpage-content {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
    padding-bottom: 0;
    margin-bottom: 20px;
  }
  .topinner-logo {
    width: 34px;
    height: 34px;
  }
  .landingpage-content {
    margin-top: 40px;
  }
  .purple-imgcontent {
    font-family: HelveticaNeue;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.25;
    text-align: left;
    color: #5773d1;
  }
  .buttonpublic-content {
    margin-left: auto;
  }
  .publicdesc-content {
    font-family: "HelveticaNeue";
    font-size: 20px;
    font-weight: normal;
    line-height: normal;
    padding-top: 8px;
    text-align: left;
    color: #000;
    span {
      font-family: "Objectivity-Medium";
      font-weight: 500;
    }
  }
  .next-icons {
    width: 24px;
    height: 24px;
  }
  .twitter-imgcontent {
    width: 20px;
  }
  .secondsections-content {
    padding-top: 20px;
  }
  .publiclink-imgcontent {
    height: 20px;
    // margin-top: 3px;
    // margin-left: 40px;
    // margin: auto;
    padding-left: 20px;
  }
  .internetdesc-content {
    font-family: "HelveticaNeue";
    text-align: left;
    font-size: 18px;
    font-weight: normal;
    padding-left: 10px;
    display: flex;
    color: #262626;
    line-height: 28px;
  }
  .detail-content {
    font-family: "HelveticaNeue";
    padding-top: 16px;
    padding-bottom: 40px;
    font-size: 22px;
    font-weight: normal;
    line-height: 1.27;
    text-align: left;
    color: #141414;
  }
  .cardDetailpublic-imgcontent {
    padding-bottom: 16px;
  }
  .notedescr-content {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #141414;
    // border: 1px solid #000000;
  }
  .publicpage-content {
    padding-top: 26px;
  }
  .addtagpublic-content {
    font-family: "HelveticaNeue";
    font-size: 13px;
    font-weight: 400;
    padding: 4px 14px;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-top: 4px;
    border: unset;
    display: inline-block;
    background-color: #eaeaea;
    border-radius: 17px;
    color: #262626;
  }
}
.shared-tags .addtagpublic-content {
  background-color: #eadbff;
  background-color: #eadbff;
  padding: 8px 8px;
  font-family: "HelveticaNeue";
  font-size: 16px;
  line-height: 11px;
  height: 30px;
  letter-spacing: normal;
  text-align: left;
  color: #262626;
  background-color: #eadbff;
  border: none;
}
.shared-tags .addtagpublic-content:nth-child(even) {
  background-color: #f7f8df;
  border-color: #f7f8df;
  padding: 8px 8px;
  font-family: "HelveticaNeue";
  font-size: 16px;
  line-height: 11px;
  height: 30px;
  letter-spacing: normal;
  text-align: left;
  color: #262626;
  border: none;
}
.dark .publicshare-section .detail-content {
  color: #bbbbbb;
}
.dark .publicshare-section .publicdesc-content {
  color: #ffffff;
}
.dark .publicshare-section .internetdesc-content {
  color: #ffffff;
}
.dark .publicshare-section .notedescr-content {
  color: #bbbbbb;
}
.publicshare-section {
  .xdj266r.x11i5rnm {
    font-family: HelveticaNeue;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    text-align: left;
    color: #141414;
  }
  .cardDetailContent .cardImage .fb-image {
    margin-top: 10px;
  }
}

.dark .publicshare-section .xu06os2 > .x1yc453h > .x126k92a div {
  color: #ffffff;
}
.dark .publicshare-section .xdj266r.x11i5rnm {
  color: #ffffff;
}
.dark .publicshare-section .publiclink-imgcontent {
  filter: invert(77%) sepia(2%) saturate(815%) hue-rotate(176deg)
    brightness(95%) contrast(98%);
}
@media only screen and (max-width: 600px) and (min-width: 360px) {
  .publicshare-section {
    .publicdesc-content {
      width: 140px;
      font-size: 16px;
    }
    .savebutton-context {
      font-size: 14px;
    }
    .width-50 {
      width: 100% !important;
    }
    .cardHeaderDetails.flex {
      display: inline-block;
    }
    .secondsections-content.flex {
      display: inline-block;
    }
    .detail-content {
      padding-bottom: 0;
    }
    .pl-\[30px\] {
      padding-left: 0 !important;
    }
    .flex.publicspace-content {
      vertical-align: middle;
      align-items: center;
    }
  }
}
