.updatebilling-section{
    width: 100%;
    margin-left: 20px;
    padding: 20px;
    border-radius: 12px;
    border: solid 1px #c1c1c1;
    .plandetail-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        line-height: normal; 
        text-align: left;
        padding-bottom: 30px;
        color: #8276b4;
    }
    .plan-content{
        color: #707070;
    }
    .confirmrenew-content{
        font-family: "HelveticaNeue";
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 30px;
        color: #262626;
    }
    .addid-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-top: 30px;
        padding-bottom: 50px;
        color: #8276b4;
    }
    .planupdate-section{
        padding-bottom: 30px;
    }
    .saveplanbutton-context{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.65;
        text-align: center;
        padding: 6px 80px;
        color: #fdfdfd;
    }
    .cancelupdatecard-content{
        font-family: HelveticaNeue;
        font-size: 18px;
        padding-top: 10px;
        margin-right: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        margin-left: 84px;
        color: #8276b4;
    }
}
.dark .updatebilling-section .plan-content{
    color: #c7c7c7;
}
.dark .updatebilling-section .confirmrenew-content{
    color: #fff;
}
