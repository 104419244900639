.tagsHolder .react-tagsinput-tag {
  background-color: #eadbff;
  background-color: #eadbff;
  padding: 8px 8px;
  font-family: "HelveticaNeue";
  font-size: 16px;
  line-height: 7px;
  height: 30px;
  letter-spacing: normal;
  text-align: left;
  color: #262626;
  background-color: #eadbff;
  border: none;
}
.tagsHolder .react-tagsinput-tag:nth-child(even) {
  background-color: #f7f8df;
  border-color: #f7f8df;
  padding: 8px 8px;
  font-family: "HelveticaNeue";
  font-size: 16px;
  line-height: 7px;
  height: 30px;
  letter-spacing: normal;
  text-align: left;
  color: #262626;
  border: none;
}
.tagsHolder.tag input {
  padding: 6px !important;
  margin: unset !important;
}



// CHNAGE THE SECOND HTML CONTENT BUTTON NAME

// .second-html .x1o1ewxj.x3x9cwd.x1e5q0jg.x13rtm0m.x1ey2m1c.xds687c.xg01cxk.x47corl.x10l6tqk.x17qophe.x13vifvy.x1ebt8du.x19991ni.x1dhq9h::after {
//   text-align: center;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   line-height: 27px;
//   font-weight: 600;
// }

// .second-html #\:r1ad\: .x1o1ewxj.x3x9cwd.x1e5q0jg.x13rtm0m.x1ey2m1c.xds687c.xg01cxk.x47corl.x10l6tqk.x17qophe.x13vifvy.x1ebt8du.x19991ni.x1dhq9h::after {
//   content: "Learn more";
// }

// .second-html #\:rei\: .x1o1ewxj.x3x9cwd.x1e5q0jg.x13rtm0m.x1ey2m1c.xds687c.xg01cxk.x47corl.x10l6tqk.x17qophe.x13vifvy.x1ebt8du.x19991ni.x1dhq9h::after {
//   content: "View";
// }

// .second-html #\:r8t\: .x1o1ewxj.x3x9cwd.x1e5q0jg.x13rtm0m.x1ey2m1c.xds687c.xg01cxk.x47corl.x10l6tqk.x17qophe.x13vifvy.x1ebt8du.x19991ni.x1dhq9h::after {
//   content: "View more";
// }

// .second-html #\:rdn\: .x1o1ewxj.x3x9cwd.x1e5q0jg.x13rtm0m.x1ey2m1c.xds687c.xg01cxk.x47corl.x10l6tqk.x17qophe.x13vifvy.x1ebt8du.x19991ni.x1dhq9h::after {
//   content: "Download";
// }


.second-html span.x1rg5ohu.x6ikm8r.x10wlt62.x16dsc37::after{
  content: "View";
  display: block;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  color: #333;
  font-weight: 600;
}
.dark .second-html span.x1rg5ohu.x6ikm8r.x10wlt62.x16dsc37::after{
  color: #ffffff;
}
