.kindleList-wholesection{
    .bucketHeader{
        margin-bottom: 20px;
    }
    .featureprogress-content{
        font-family: "HelveticaNeue";
        font-size: 24px;
        font-weight: normal;
        text-align: center;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-bottom: 40px;
        border-radius: 6px;
        color: #000;
        background-color: #fafafa;
    }
    .actionicon-content{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: left;
        color: #BCBCBC;
    }
    .kindlehighlight-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: left;
        color: #262626;
    }
    .kindlebg-content{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        text-align: center;
        padding: 6px 12px;
        color: #fff;
    }
    .rowkindle-content{
        flex-wrap: wrap;
    }
    .cardHolderskindle .social-textcontent{
        font-family: "HelveticaNeue";
        font-size: 11px; 
        font-weight: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        // width: 129px;
        margin-bottom: 20px;
        color: #262626;
        display: -webkit-inline-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        width: 103px;
    }
    .iconsalign-content{
        margin-left: auto;
    }
    .vertical-iconcontent{
        height: 20px;
    }
    .headingproject-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: left;
        padding-top: 15px;
        padding-bottom: 15px; 
        color: #000000;
    }
    .cardtext-content{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.18;
        letter-spacing: normal;
        text-align: left;
        padding-top: 6px;
        padding-bottom: 14px;
        color: #141414;
    }
    .createddate-content{
        font-family: "HelveticaNeue";
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #484848;
    }
    .highlight-content{
        font-family: "HelveticaNeue";
        background: #fcffa8;
        border-radius: 14px;
        padding: 3px 8px;
        font-size: 11px;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: center;
        margin: 0 auto;
        display: flex;
        width: 80px;
        margin-bottom: 20px;
        color: #262626;
    }
    .kindlenumber-content{
        font-family: "HelveticaNeue";
        position: absolute;
        background: #fcffa8;
        border-radius: 14px;
        padding: 2px 7px;
        font-size: 13px;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: center;
        width: 34px;
        margin-left: -7px;
        margin-top: -9px;
        border: solid 1px #7d7d7d;
        color: #262626;
    }
    .kindleemail-content{
        font-family: "HelveticaNeue";
        position: absolute;
        background: #fcffa8;
        border-radius: 14px;
        padding: 2px 7px;
        font-size: 13px;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: center;
        width: 30px;
        margin-left: 28px;
        margin-top: -8px;
        border: solid 1px #7d7d7d;
        color: #262626;
    }
    .kindle-card{
        padding-right: 15px ;
      h6{
        font-family: 'HelveticaNeue';
        font-size: 16px;
        font-weight: normal;
        line-height: 21px;
        text-align: left;
        color: #262626;
        margin-bottom: 17px;
      }
      .kindlelist-box {
        // width: 63px;
        width: 50px;
        // height: 10px;
        height: 3px;
      }
      .kindlelist-twobox {
        width: 31px;
        height: 10px;
      }
      .kindlelist-onebox{
        width: 128px;
        height: 10px;
      }
      .bg-green{ 
        background-color: #0fd161;
      }
      .bg-afc604{
        background-color: #afc604;
      }
      .cardcolorbg-content{
        background-color: #d92694;
      }
      .bg-0fd1bd{
        background-color: #0fd1bd;
      }
    }
    .kindleimg-content{
        margin: 0 auto;
        margin-bottom: 6px;
        width: 102px;
        height: 160px;
        // width: 100%;
    }
    .kindle-box {
        padding: 20px;
        border-radius: 5px;
    }
    .bg-green{
        background-color: #0fd161;
    }
    .bg-afc604{
        background-color: #afc604;
    }
}
.filesuploadpopup .saveteambutton-context {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: normal;
    text-align: center;
    height: 30px;
    padding: 3px 50px;
    color: #fdfdfd;
}
.filesuploadpopup .buttonpadding-content {
    padding-top: 6px;
    padding-bottom: 6px;
    display: flex;
}
.filesuploadpopup .cancelteam-content{
    margin-right: 10px;
    align-items: center;
    display: flex;
}
.filesuploadpopup .lastfile-section {
    padding-top: 20px;
}
.dark .kindleList-wholesection .cardHolderskindle .social-textcontent{
    color: #fff;
}
.dark .kindleList-wholesection .headingproject-content{
    color: #fff;
}
.dark .kindleList-wholesection .cardtext-content{
    color: #c7c7c7;
}
.dark .kindleList-wholesection .createddate-content{
    color: #bbb;
}
.dark .kindleList-wholesection .actionicon-content{
    color: #39445B;
}
.dark .kindle-detail .kindle-card{
    background: #051534;
    border: solid 1px #202F4F;
}
.dark .kindle-detail hr{
    border: 1px solid #202F4F;
}
.dark .kindle-detail .kindle-content{
    color: #c7c7c7;
}
.dark .kindle-detail .last-updated{
    color: #c7c7c7;
}
.dark .kindle-detail .kindlecard{
    background: #051534;
    border: solid 1px #202F4F;
    border-top: 14px solid #0fd161;
}
.kindlecard:nth-child(2){
   border-top: 14px solid #afc604;
}
.dark .kindleList-wholesection .kindlehighlight-content{
   color: #fff;
}
.kindleList-wholesection{ 
    display: block;
} 
.kindlelist-mblsection{
    display: none;
}
.kindledetaildesktop-section{
    display: block;
}
.dark .featureprogress-content{
    color: #ffffff;
    background-color: #0a1d42;
}
@media (min-width: 320px) and (max-width: 771px) {
    .kindleList-wholesection{
      display: none;
    }
    .featureprogress-content{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: normal;
        text-align: center;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-bottom: 20px;
        margin-right: 36px;
        border-radius: 6px;
        color: #000;
        background-color: #fafafa;
    }
    .kindlelist-mblsection{
      display: block;
    }
    .kindlelist-mblsection{
        padding-left: 24px;
        // padding-right: 7px;
    }
    .kindlelist-mblsection hr{
       margin-right: 24px;
    }
    .kindledetaildesktop-section{
        display: none;
    }
    .pr-\[17\.73px\] {
        padding-right: 11px;
    }
    .pl-\[23px\] {
        padding-left: 16px;
    }
    .pr-\[32px\] {
        padding-right: 16px;
    }
    .spacekindle-seccontent{
        padding-right: 24px;
    }
    .bucketHeader{
        margin-bottom: 20px;
    }
    .actionicon-content{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: left;
        color: #BCBCBC;
    }
    .kindlehighlight-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: left;
        color: #262626;
    }
    .kindlebg-content{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        text-align: center;
        padding: 6px 12px;
        color: #fff;
    }
    .rowkindle-content{
        flex-wrap: wrap;
    }
    .cardHolderskindle .social-textcontent{
        font-family: "HelveticaNeue";
        font-size: 10px; 
        font-weight: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        width: 129px;
        margin-bottom: 20px;
        color: #262626;
        display: -webkit-inline-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .iconsalign-content{
        margin-left: auto;
    }
    .vertical-iconcontent{
        height: 20px;
    }
    .headingproject-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: left;
        padding-top: 15px;
        padding-bottom: 15px; 
        color: #000000;
    }
    .cardtext-content{
        font-family: "HelveticaNeue";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.18;
        letter-spacing: normal;
        text-align: left;
        padding-top: 6px;
        padding-bottom: 14px;
        color: #141414;
    }
    .createddate-content{
        font-family: "HelveticaNeue";
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #484848;
    }
    .highlight-content{
        font-family: "HelveticaNeue";
        background: #fcffa8;
        border-radius: 14px;
        padding: 3px 8px;
        font-size: 11px;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: center;
        margin: 0 auto;
        display: flex;
        width: 80px;
        margin-bottom: 20px;
        color: #262626;
    }
    .kindlenumber-content{
        font-family: "HelveticaNeue";
        position: absolute;
        background: #fcffa8;
        border-radius: 14px;
        padding: 3px 6px;
        font-size: 10px;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: center;
        width: 25px;
        border: solid 1px #7d7d7d;
        color: #262626;
        top: -8px;
    }
    .kindleemail-content{
        position: absolute;
        left: 28px;
        top: -9px;
    }
    .kindle-card{
        padding-right: 15px ;
        position: relative;
      h6{
        font-family: 'HelveticaNeue';
        font-size: 16px;
        font-weight: normal;
        line-height: 21px;
        text-align: left;
        color: #262626;
        margin-bottom: 17px;
      }
      .kindlelist-box {
        width: 63px;
        height: 3px;
      }
      .kindlelist-twobox {
        width: 31px;
        height: 10px;
      }
      .kindlelist-onebox{
        width: 128px;
        height: 10px;
      }
      .bg-green{ 
        background-color: #0fd161;
      }
      .bg-afc604{
        background-color: #afc604;
      }
      .cardcolorbg-content{
        background-color: #d92694;
      }
      .bg-0fd1bd{
        background-color: #0fd1bd;
      }
    }
    .kindleimg-content{
        width: 154px;
        margin-bottom: 6px;
        height: 195px;
    }
    .kindle-box {
        padding: 20px;
        border-radius: 5px;
    }
    .bg-green{
        background-color: #0fd161;
    }
    .bg-afc604{
        background-color: #afc604;
    }
  }

  @media (min-width: 414px) and (max-width: 736px){
    .kindlelist-mblsection{
        padding-left: 44px;
    }
}
@media (min-width: 430px) and (max-width: 932px){
    .kindlelist-mblsection{
        padding-left: 51px;
    }
    .featureprogress-content{
        margin-right: 48px;
    }
}
