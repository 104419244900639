.invoices-section{
    .invoicenameamnt-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: 1.83;
        text-align: left;
        width: 13%;
        padding-bottom: 3px;
        color: #262626;
    }
    .invoiceamount-content{
        font-family: "HelveticaNeue"; 
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: 1.83;
        text-align: left;
        width: 19%;
        padding-bottom: 3px;
        color: #262626;
    }
    .invoiceamntcustomer-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: 1.83;
        text-align: left;
        width: 34%;
        padding-bottom: 3px;
        color: #262626;
    }
    .invoiceamntplan-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: 1.83;
        text-align: left;
        width: 33%;
        padding-bottom: 3px;
        color: #262626;
    }
    .invoicepay-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: 1.83;
        text-align: left;
        width: 25%;
        padding-bottom: 3px; 
        color: #262626;
    }
    .invoicename-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.2;
        text-align: left;
        width: 13%;
        padding-top: 8px;
        padding-bottom: 8px;
        color: #262626;
    }
    .invoicedetail-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.2;
        text-align: left;
        width: 20%;
        padding-top: 8px;
        padding-bottom: 8px;
        color: #262626;
    }
    .invoicedetailemail-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.2;
        text-align: left;
        width: 36%;
        padding-top: 8px;
        padding-bottom: 8px;
        color: #262626;
    }
    .invoicedetailmon-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.2;
        text-align: left;
        width: 35%;
        padding-top: 8px;
        padding-bottom: 8px;
        color: #262626;
    }
    .invoicedetailpay-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.2;
        text-align: left;
        width: 26%;
        padding-top: 8px;
        padding-bottom: 8px;
        color: #262626;
    }
    .invoicepdf-detail{
        width: 5%;
        align-items: center;
        display: flex;
    }
}
.dark .invoices-section .invoicename-content{
    color: #ffffff;
}
.dark .invoices-section .invoiceamount-content{
    color: #ffffff;
}
.dark .invoices-section .invoiceamount-content{
    color: #ffffff;
}
.dark .invoices-section .invoicepay-content{
    color: #ffffff;
}
.dark .invoices-section .invoicedetail-content{
    color: #ffffff;
}
.dark .invoices-section .invoicedetailpay-content{
    color: #ffffff;
}
.dark .invoicepdf-detail{
    filter: invert(77%) sepia(2%) saturate(815%) hue-rotate(176deg) brightness(95%) contrast(98%);
}
.dark .invoices-section .invoicenameamnt-content{
    color: #ffffff;
}
.dark .invoices-section .invoiceamntcustomer-content{
    color: #ffffff;
}
.dark .invoices-section .invoiceamntplan-content{
    color: #ffffff;
}
.dark .invoices-section .invoicedetailemail-content{
    color: #ffffff;
}
.dark .invoices-section .invoicedetailmon-content{
    color: #ffffff;
}
.invoicesdesktop-section{
    display: block;
}
.invoices-section hr{
    display: block;
}
.invoicesmbl-section{
    display: none;
}
@media (min-width: 320px) and (max-width: 771px){
    .invoicesdesktop-section{
        display: none;
    }
    .invoices-section hr{
        display: none;
    }
    .invoicesmbl-section{
        display: block;
    }
    .invoicembl-bgcontent{
        padding: 10px;
        margin-bottom: 15px;
        border-radius: 16px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #fff;
    }
    .invoicedetail-content{
        width: 100% !important;
    }
    .invoicedetailmon-content{
        width: unset !important;
    }
    .invoicedetailpay-content{
        width: unset !important;
    }
    .invoicename-content{
        width: unset !important;
        margin-right: 10px !important;
    }
}