.topBar {
  height: 60px;
  display: grid;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 99;
  background-color: #fff;
}
.topBar {
  input::placeholder {
    color: #707070;
    font-size: 16px;
  }
  .pl-78 {
    padding-left: 78px;
  }
  .magnifier {
    width: 18px;
    height: 18px;
  }
  .search-input {
    padding-left: 18px;
  }
  #search {
    height: 36px;
  }
  .searchwhole-content {
    padding-left: 50px;
    width: 50%;
    padding-right: 30px;
  }
  .menu-imgcontent {
    cursor: pointer;
  }
  .searchBar {
    input[type="checkbox"] {
      width: 23px;
      height: 23px;
      margin-right: 16px;
      border-radius: 10px;
      accent-color: #fff;
    }
    ::placeholder {
      font-size: 12px;
      color: red;
    }
    label {
      font-size: 20px;
      line-height: 24px;
      color: #000;
      vertical-align: super;
    }
    input[type="checkbox"]:checked {
      border: 1px solid #000;
    }

    input[type="checkbox"]::before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transform: scale(0);
      transform-origin: bottom left;
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--form-control-color);
      /* Windows High Contrast Mode */
      background-color: CanvasText;
    }
  }
  .checkbox-wrapper-23 *,
  .checkbox-wrapper-23 *:after,
  .checkbox-wrapper-23 *:before {
    box-sizing: border-box;
  }

  .checkbox-wrapper-23 {
    display: flex;
  }
  .checkbox-wrapper-23 span {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 23px;
    text-align: left;
    margin-left: 11px;
  }
  .checkbox-wrapper-23 input {
    position: absolute;
    opacity: 0;
  }

  .checkbox-wrapper-23 input:checked + label svg path {
    stroke-dashoffset: 0;
  }
  .dark .checkbox-wrapper-23 input + label {
    border: 2px solid #202f4f;
  }
  .dark .checkbox-wrapper-23 input + label svg path {
    fill: none;
    stroke: #acacac;
  }
  .checkbox-wrapper-23 input:focus + label {
    transform: scale(1.03);
  }

  .checkbox-wrapper-23 input + label {
    display: block;
    border: 2px solid #333;
    width: 23px;
    height: 23px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 2px;
  }

  .checkbox-wrapper-23 input + label:active {
    transform: scale(1.05);
    border-radius: 12px;
  }

  .checkbox-wrapper-23 input + label svg {
    pointer-events: none;
    padding: 5%;
  }
  #search:placeholder {
    font-size: 16px;
    font-family: "HelveticaNeue";
  }

  .checkbox-wrapper-23 input + label svg path {
    fill: none;
    stroke: #333;
    stroke-width: 4px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 100;
    stroke-dashoffset: 101;
    transition: all 250ms cubic-bezier(1, 0, 0.37, 0.91);
  }
}
.topBars {
  height: 60px;
  display: grid;
  align-items: center;
  width: 100%;
  background-color: #fff;
}
.light {
  .light-theme-logo {
    display: block;
  }
  .dark-theme-logo {
    display: none;
  }
}
.dark {
  .light-theme-logo {
    display: none;
  }
  .dark-theme-logo {
    display: block;
  }
  .menu {
    filter: invert(80%) sepia(5%) saturate(19%) hue-rotate(314deg)
      brightness(89%) contrast(79%);
  }
}

.dark {
  .checkbox-wrapper-23 input + label {
    border: 2px solid #202f4f;
  }
  .checkbox-wrapper-23 input + label svg path {
    fill: none;
    stroke: #acacac;
  }
}
.topbardesktop-section {
  display: block;
}
.topbarmbl-section {
  display: none;
}
@media (min-width: 320px) and (max-width: 771px) {
  .topbardesktop-section {
    display: none;
  }
  .topbarmbl-section {
    display: block;
  }
  .light-theme-logo {
    width: 108px;
    height: 20px;
  }
  .topbardismiss-mblcontent {
    margin-left: auto;
    padding: 7px 6px 0px 0px;
  }
}
