.app {
    height: 100%;
  }
  
  .treeRoot {
    height: 100%;
    padding-top: 32px;
    padding-bottom: 32px;
  }
  
  .draggingSource {
    opacity: 0.3;
  }
  
  .dropTarget {
    /* background-color: #eba50f; */
    border: 3px solid #f60;
    outline: none; /* This removes the default outline style */
  }
  