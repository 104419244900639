.pricing-plans {
  padding-bottom: 40px;
  h3 {
    font-family: Objectivity-Medium;
    font-size: 30px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    color: #262626;
    margin-bottom: 10px;
  }
  .react-tabs {
    margin-top: -50px;
  }
  .sec-sectio {
    text-align: center;
    display: inline-block;
    h6 {
      margin-left: 60px;
    }
  }
  .save-plan {
    text-align: center;
    margin-left: 582px;
    margin-top: -9px;
  }

  .saveplan-text {
    font-family: Objectivity;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ff6500;
  }
  .pt-12 {
    padding-top: 12px;
  }

  .page-heading {
    margin-bottom: 25px;
  }
  .react-tabs__tab-list {
    border-bottom: 0;
    margin-bottom: 0px !important;
    width: fit-content;
    margin: auto;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    border-bottom: 0;
    padding: 4px;
    border-radius: 50px;
  }
  p {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    color: #707070;
  }
}

.dark {
  .pricing-plans {
    h3 {
      color: #fff;
    }
    .sec-sectio {
      h6 {
        color: #ffffff;
      }
    }
    p {
      color: #c7c7c7;
    }
  }
  .plans {
    background-color: #051534;
    h4 {
      color: #ffffff;
    }
    h6 {
      color: #ffffff;
    }
    .plan-details {
      h5 {
        color: #ffffff;
      }

      .sub-heading {
        color: #ffffff;
      }
    }
  }
}
.pricing-plans .react-tabs__tab {
  padding: 8px 60px;
}
.pricing-plans .react-tabs__tab--selected {
  background-color: #ff6600 !important;
  border-bottom: 0 !important;
  border-radius: 50px;
  color: #ffffff !important;
  font-size: 16px;
  font-family: Objectivity;
  text-align: center;
  color: #fff;
}
.dark .pricing-plans .react-tabs__tab-list {
  background-color: #2f436a;
}
.dark .pricing-plans .react-tabs__tab--selected {
  border-bottom: 0 !important;
  background-color: #ff6600 !important;
}
