.sucess-section{
    width: 80%;
    margin: 30px auto;
    .success-content{
        font-family: "HelveticaNeue";
        font-size: 40px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: center;
        padding-bottom: 40px;
        color: #262626; 
    }
    .successcenter-content{
        margin: 0 auto;
    }
    .successbutton-content{
        width: 190px;
        border-radius: 30px;
        padding: 6px 10px 6px 10px;
        margin: 0 auto;
        align-items: center;
        border: solid 1px rgba(112, 112, 112, 0.52);
        background-color: #fff;
    }
    .extension-content{
        font-family: "HelveticaNeue";
        font-size: 26px !important;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: center;
        padding-top: 40px;
        padding-bottom: 20px;
        color: #262626;
    }
    .extensiondetail-content{
        font-family: "HelveticaNeue";
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.39;
        text-align: center;
        padding-bottom: 20px;
        color: #262626;
    }
    .download-content{
        color: #8276b4;
    }
    .downloadext-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: center;
        margin-bottom: 50px;
        color: #262626;
    }
}
.dark .sucess-section .success-content{
    color: #ffffff;
}
.dark .sucess-section .successstarted-content{
    color: #ffffff;
}
.dark .sucess-section .successeasy-content{
    color: #ffffff;
}
.dark .sucess-section .extension-content{
    color: #ffffff;
}
.dark .sucess-section .extensiondetail-content{
    color: #ffffff;
}
.dark .sucess-section .successbutton-content{
    color: #ffffff;
    background-color: unset;
    border: 1px solid #ffffff;
}
.dark .sucess-section .downloadext-content{
    color: #ffffff;
}