.userpricing-plans{
    .upgrade-content{
        font-family: "HelveticaNeue";
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 30px;
        color: #262626;
    }
    .plans{
        // margin: unset;
    }
    .save-plan {
        text-align: center;
        margin-top: 61px;
    }
}
.dark .userpricing-plans .upgrade-content{
    color: #ffffff;
}