.invoicedetails-section{
    .invoice-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold; 
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-bottom: 33px;
        color: #262626;
    }
    .amountmonth-content{
        font-family: "HelveticaNeue";
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        align-items: center;
        display: flex;
        color: #262626;
    }
    .currentplan-content{
        font-family: "HelveticaNeue";
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding: 6px 12px;
        margin-left: 20px;
        border-radius: 14px;
        background-color: rgba(193, 193, 193, 0.18);
        color: #262626;
    }
    .planrenew-content{
        font-family: "HelveticaNeue"; 
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        text-align: left;
        padding-top: 17px;
        padding-bottom: 48px;
        color: #707070;
    }
    .invoiceheading-section{
        font-family: "HelveticaNeue"; 
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.65;
        text-align: left;
        width: 20%;
        padding-bottom: 10px;
        color: #262626;
    }
    .invoicedetail-content{
        font-family: "HelveticaNeue"; 
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.2;
        text-align: left;
        padding-top: 15px;
        padding-bottom: 15px;
        color: #262626;
    }
    .monthlyinvoice-section{
        width: 18%;
    }
    .numberinvoice-section{
        width: 20%;
    }
    .detailsinvoice-section{
        width: 25%;
    }
    .dollarsinvoice-section{
        width: 12%;
    }
    .priceinvoice-section{
        font-family: "HelveticaNeue"; 
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.2;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;
        color: #262626;
    }
} 
.dark .invoicedetails-section .amountmonth-content{
    color: #fff;
}
.dark .invoicedetails-section .currentplan-content{
    color: #fff;
}
.dark .invoicedetails-section .planrenew-content{
    color: #c7c7c7;
}
.dark .invoicedetails-section .invoice-content{
    color: #fff;
}
.dark .invoicedetails-section .invoiceheading-section{
    color: #fff;
}
.dark .invoicedetails-section .invoicedetail-content{
    color: #fff;
}
.dark .invoicedetails-section .priceinvoice-section{
    color: #fff;
}
.dark .invoicedetails-section hr {
    border: 1px solid #202f4f !important;
}
